import request from "@/utils/request";

/**
 * 通过auth_code换session
 */
export function getSession(data) {
  return request({
    url: "/ticket/ota/changeSession",
    method: "get",
    params: data,
  });
}

/**
 * 获取票关联列表
 */
export function getAssociationList(data) {
  return request({
    url: "/ticket/association/list",
    method: "get",
    params: data,
  });
}

/**
 * 获取票关联列表（抖音）
 */
export function getDouyinAssociationList(data) {
  return request({
    url: "/ticket/ota/queryDouyinTicketing",
    method: "get",
    params: data,
  });
}

/**
 * 获取抖音授权信息
 */
export function getDouyinAuthInfo(data) {
  return request({
    url: "/ticket/ota/tiktokAuthorization",
    method: "get",
    params: data,
  });
}

/**
 * 解绑抖音授权
 */
export function unbindTiktok(data) {
  return request({
    url: "/ticket/ota/unbindTiktok",
    method: "get",
    params: data,
  });
}

/**
 * 获取票关联
 */
export function getAssociation(id) {
  return request({
    url: "/ticket/association" + id,
    method: "get",
  });
}

/**
 * 删除票关联
 */
export function removeAssociation(id) {
  return request({
    url: "/ticket/association" + id,
    method: "delete",
  });
}

/**
 * 添加票关联
 */
export function addAssociation(data) {
  return request({
    url: "/ticket/association",
    method: "post",
    data,
  });
}

/**
 * 修改票关联
 */
export function updateAssociation(data) {
  return request({
    url: "/ticket/association",
    method: "put",
    data,
  });
}

/**
 * 解除票关联
 */
export function disassociate(id) {
  return request({
    url: "/ticket/association/disassociate/" + id,
    method: "get",
  });
}

/**
 * 获取ota授权列表
 * empowerType 授权类型 1美团
 * ifEmpower 授权 0未授权 1已授权
 */
export function getOtaList(data) {
  return request({
    url: "/ticket/ota/otaList",
    method: "get",
    params: data,
  });
}

/**
 * 获取绑定店铺列表
 */
export function getBindShopList(data) {
  return request({
    url: "/ticket/bind/list",
    method: "get",
    params: data,
  });
}

/**
 * 获取美团店铺列表
 */
export function getMeituanShopList(data) {
  return request({
    url: "/ticket/bind/getStoreList",
    method: "get",
    params: data,
  });
}

/**
 * 绑定景区
 */
export function bindScenic(data) {
  return request({
    url: "/ticket/bind/bind",
    method: "post",
    data: data,
  });
}

/**
 * 解绑景区
 */
export function unbindScenic(id) {
  return request({
    url: "/ticket/bind/unbind/" + id,
    method: "get",
  });
}

/**
 * 解除授权
 */
export function revocation(id) {
  return request({
    url: "/ticket/ota/unbindMtOta/" + id,
    method: "get",
  });
}

/**
 * 获取美团券码查询到的美团购买的商品
 * 抖音也是用这个接口
 */
export function getMeituanGoods(data) {
  return request({
    url: "/ticket/collectionHistory/ticketCollection",
    method: "get",
    params: data,
  });
}

/**
 * 取票
 */
export function submitTicketCollection(data) {
  return request({
    url: "/ticket/collectionHistory/submitTicketCollection",
    method: "post",
    data: data,
  });
}

/**
 * 取票记录
 */
export function getTakeTicketRecord(data) {
  return request({
    url: "/ticket/collectionHistory/list",
    method: "get",
    params: data,
  });
}

/**
 * 获取核销记录
 */
export function getVerificationRecords(data) {
  return request({
    url: "/ticket/verificationRecords/list",
    method: "get",
    params: data,
  });
}

/**
 * 美团撤销验券
 */
export function revokeVerificationRecord(data) {
  return request({
    url: "/ticket/verificationRecords/revoke",
    method: "post",
    data: data,
  });
}

/**
 * 抖音撤销验券
 */
export function revokeVerificationRecordDouyin(data) {
  return request({
    url: "/ticket/verificationRecords/dyRevoke",
    method: "post",
    data: data,
  });
}

/**
 * 获取抖音授权链接的链接
 */
export function getDyAuthLink(data) {
  return request({
    url: "/ticket/ota/dyEmpower",
    method: "get",
    params: data,
  });
}

/**
 * 获取抖音是否授权
 */
export function getIsDyAuth(data) {
  return request({
    url: "/ticket/bind/whetherBindDy",
    method: "get",
    params: data,
  });
}
