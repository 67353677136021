import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listWicket } from "@/api/ticket/ticket-office";
import { mapMutations, mapState } from "vuex";
export default {
  name: "OfficeSelectDialog",
  data() {
    return {
      visible: false,
      officeId: null,
      officeList: [],
      isExit: false
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    ...mapState(["user"]),
    showCancel() {
      return this.office != null;
    }
  },
  methods: {
    ...mapMutations("office", ["setOffice"]),
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$router.push({
          name: "Login"
        });
        this.isExit = true;
        this.$refs["dialogEl"].handleClose();
      });
    },
    beforeClose(done) {
      console.log("beforeClose");
      if (this.isExit) {
        this.isExit = false;
        done();
      } else if (this.office == null) {
        this.$message.warning("请选择窗口");
        return;
      }
      done();
    },
    async initWicket() {
      const res = await listWicket({
        userId: this.user.userId,
        ticketWindowType: "0"
      });
      this.officeList = res.data;
    },
    open() {
      console.log(this.office);
      if (this.office != null) {
        this.officeId = this.office.id;
      }
      this.visible = true;
      this.initWicket();
    },
    cancel() {
      this.$emit("cancel");
      this.close();
    },
    close() {
      this.$emit("close");
      this.$refs["dialogEl"].handleClose();
    },
    confirm() {
      if (this.officeId == null) {
        this.$message.warning("请选择窗口");
        return;
      }
      const office = this.officeList.find(v => v.id === this.officeId);
      this.setOffice(office);
      this.$emit("confirm");
      this.close();
    }
  }
};