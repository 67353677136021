import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import dict from "./modules/dict";
import user from "./modules/user";
import office from "./modules/office";
import prodMenu from './modules/prod-menu';
import tagsView from "./modules/tagsView";
import permission from "./modules/permission";
import settings from "./modules/settings";
import getters from "./getters";
// import createPersistedState from "vuex-persistedstate";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

// const localPersistedState = createPersistedState({
//   paths: ["office"],
// });

const vuexLocal = new VuexPersistence({
  modules: ["office"],
});

const store = new Vuex.Store({
  modules: {
    app,
    dict,
    user,
    tagsView,
    permission,
    settings,
    office,
    prodMenu
  },
  getters,
  plugins: [vuexLocal.plugin],
});

export default store;
