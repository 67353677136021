//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AssociateTicketDialog from "../components/AssociateTicketDialog.vue";
import { getAssociationList, getDouyinAssociationList, getDyAuthLink, disassociate, getIsDyAuth, getDouyinAuthInfo, unbindTiktok } from "@/api/ticket/ota";
import { scenicUsableList } from "@/api/ticket/scenic";

// 票类关联设置（抖音）
export default {
  name: "TicketAssociationSettings",
  components: {
    AssociateTicketDialog
  },
  data() {
    return {
      associationList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ticketingTimeName: null
      },
      scenicId: null,
      loading: false,
      total: 0,
      scenicList: [],
      isDyAuth: false,
      initIsDyAuthId: -1,
      auntList: []
    };
  },
  watch: {
    isDyAuth(v) {
      console.log(v);
      this.initDouyinAuthInfo();
      this.getList();
    }
  },
  async mounted() {
    this.initIsDyAuthId = setInterval(() => {
      this.initIsDyAuth();
    }, 10 * 1000);
  },
  async created() {
    await this.initScenicList();
    if (this.scenicList?.length > 0) {
      this.scenicId = this.scenicList[0].id;
    }
    await this.initIsDyAuth();
    this.initDouyinAuthInfo();
    this.getList();
  },
  async onShowLazyHook() {
    await this.initScenicList();
    await this.initIsDyAuth();
    this.initDouyinAuthInfo();
    this.getList();
  },
  destroyed() {
    clearInterval(this.initIsDyAuthId);
  },
  methods: {
    // 解除授权
    async deauthorize() {
      await this.$confirm("确定解除授权？", "解除授权", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await unbindTiktok({
        scenicId: this.scenicId
      });
      await this.initIsDyAuth();
      this.initDouyinAuthInfo();
      this.getList();
    },
    async initDouyinAuthInfo() {
      if (!this.isDyAuth) {
        return;
      }
      const res = await getDouyinAuthInfo({
        scenicId: this.scenicId
      });
      this.auntList = [res.data];
    },
    async initIsDyAuth() {
      const res = await getIsDyAuth({
        scenicId: this.scenicId
      });
      this.isDyAuth = res.data == 1;
    },
    async goDouyinOtaAuth() {
      const res = await getDyAuthLink({
        scenicId: this.scenicId
      });
      window.open(res.msg, "_blank");
    },
    async initScenicList() {
      const res = await scenicUsableList();
      this.scenicList = res.data;
    },
    async getList() {
      this.associationList = [];
      this.loading = true;
      try {
        if (!this.isDyAuth) {
          return;
        }
        await getDouyinAssociationList({
          ...this.queryParams,
          otaType: 2,
          scenicId: this.scenicId
        });
        const response = await getAssociationList({
          ...this.queryParams,
          otaType: 2,
          scenicId: this.scenicId
        });
        this.associationList = response.rows;
        this.total = response.total;
      } finally {
        this.loading = false;
      }
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 店铺切换
    async scenicChange(e) {
      console.log(e);
      await this.initIsDyAuth();
      await this.initDouyinAuthInfo();
      this.getList();
    },
    // 关联
    associate(item) {
      this.$refs["associateTicketDialog"].open(item, this.scenicId);
    },
    // 解除关联
    disassociate(item) {
      this.$confirm("确定解除关联？", "解除关联", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await disassociate(item.id);
        this.$modal.msgSuccess(res.msg);
        this.getList();
      });
    }
  }
};