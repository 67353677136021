export function getDefaultSettings() {
  return [
    {
      printType: "门票小票",
      ifOpen: "2",
      printer: null,
      printWidth: "48mm",
      printTemplate: null,
      printNum: "1",
    },
    {
      printType: "收银小票",
      ifOpen: "2",
      printer: null,
      printWidth: "48mm",
      printTemplate: null,
      printNum: "1",
    },
    {
      printType: "会员小票",
      ifOpen: "2",
      printer: null,
      printWidth: "48mm",
      printTemplate: null,
      printNum: "1",
    },
    {
      printType: "普通门票",
      ifOpen: "2",
      printer: null,
      printWidth: "48mm",
      printTemplate: null,
      printNum: "1",
    },
    {
      printType: "检票小票",
      ifOpen: "2",
      printer: null,
      printWidth: "48mm",
      printTemplate: null,
      printNum: "1",
    },
  ];
}
