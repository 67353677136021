//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TextTabs",
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      }
    },
    active: {
      type: Number,
      default: -1
    }
  },
  methods: {
    handleTabClick(i) {
      this.$emit("tab-click", i);
      this.$emit("update:active", i);
    }
  }
};