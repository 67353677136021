import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import { dateRangePickerShortcuts } from "@/utils/el-utils";
import { getTakeTicketRecord } from "@/api/ticket/ota";
import { getAllTicketList } from "@/api/ticket/ticketing";
function createDateRange() {
  return [dayjs().startOf("d").format("YYYY-MM-DD"), dayjs().endOf("d").format("YYYY-MM-DD")];
}

// 取票记录
export default {
  name: "TakeTicketRecord",
  components: {},
  data() {
    return {
      takeTicketRecord: [],
      ticketList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ticketId: null,
        thirdOrderNo: null,
        thirdTicketNo: null,
        otaType: 2
      },
      loading: false,
      total: 0,
      dateRange: createDateRange(),
      pickerOptions: {
        shortcuts: dateRangePickerShortcuts
      }
    };
  },
  mounted() {
    this.initTicketList();
    this.handleQuery();
  },
  methods: {
    initTicketList() {
      // FEAT 只查散客票与团队票
      getAllTicketList().then(response => {
        this.ticketList = response.data.filter(v => {
          return v.operationType == "0" || v.operationType == "1";
        });
      });
    },
    getList() {
      this.loading = true;
      getTakeTicketRecord(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
        this.takeTicketRecord = response.rows;
        this.total = response.total;
      }).finally(() => {
        this.loading = false;
      });
    },
    resetQuery() {
      this.dateRange = createDateRange();
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.total = 0;
      this.getList();
    },
    // 导出
    handleExport() {
      this.download("/ticket/collectionHistory/export", this.addDateRange(this.queryParams, this.dateRange), `collectionHistory_${new Date().getTime()}.xlsx`);
    }
  }
};