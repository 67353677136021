//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    Treeselect
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    tables: {
      type: Array,
      default: null
    },
    menus: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      subColumns: [],
      rules: {
        tplCategory: [{
          required: true,
          message: "请选择生成模板",
          trigger: "blur"
        }],
        packageName: [{
          required: true,
          message: "请输入生成包路径",
          trigger: "blur"
        }],
        moduleName: [{
          required: true,
          message: "请输入生成模块名",
          trigger: "blur"
        }],
        businessName: [{
          required: true,
          message: "请输入生成业务名",
          trigger: "blur"
        }],
        functionName: [{
          required: true,
          message: "请输入生成功能名",
          trigger: "blur"
        }]
      }
    };
  },
  watch: {
    "info.subTableName": function (val) {
      this.setSubTableColumns(val);
    }
  },
  created() {},
  methods: {
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.menuId,
        label: node.menuName,
        children: node.children
      };
    },
    /** 选择子表名触发 */
    subSelectChange(value) {
      this.info.subTableFkName = "";
    },
    /** 选择生成模板触发 */
    tplSelectChange(value) {
      if (value !== "sub") {
        this.info.subTableName = "";
        this.info.subTableFkName = "";
      }
    },
    /** 设置关联外键 */
    setSubTableColumns(value) {
      for (const item in this.tables) {
        const name = this.tables[item].tableName;
        if (value === name) {
          this.subColumns = this.tables[item].columns;
          break;
        }
      }
    }
  }
};