// 商品类型
export function prodType() {
  const arr = [
    {
      label: "普通",
      value: 0,
    },
    {
      label: "服务",
      value: 1,
    },
  ];
  return arr;
}
// 商品状态 0 上架（开启） 1 下架（禁用）-1 回收
export function prodStutus(){
  const arr =[
    {label: "上架", value: 0},
    {label: "下架", value: 1},
    // {label: "回收", value: -1},
  ];
  return arr;
}

// 支付状态
export function payStatus(){
  const arr = [
    {label:'支付中',value:20},
    {label:'订单关闭',value:30},
    {label:'订单完成',value:40},
    {label:'部分退货',value:50},
    {label:'全部退货',value:60},
  ]
  return arr;
}
