//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Table",
  props: {
    tableColumns: {
      type: Object,
      required: true,
      default: () => {
        return [];
      }
    },
    tableData: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    stripe: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: null
    },
    maxHeight: {
      type: Number,
      default: document.documentElement.clientHeight - 300
    }
  },
  data() {
    return {
      h: ''
    };
  },
  methods: {
    handleClick(row, type) {
      this.$emit('handleClick', row, type);
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val);
    }
  }
};