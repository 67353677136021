import ScanCodeDialog from "./index.vue";
import Vue from "vue";

const ScanCodeDialogConstructor = Vue.extend(ScanCodeDialog);

/**
 * 打开扫码对话框
 * @param {Vue} ctx 父组件
 * @returns {Promise}
 */
export function openScanCodeDialog(ctx) {
  const instance = new ScanCodeDialogConstructor({
    el: document.createElement("div"),
    parent: ctx,
  });
  instance.$mount(document.body);
  // document.body.appendChild(instance.$el);

  instance.open();

  return new Promise((resolve, reject) => {
    instance.$on("confirm", (e) => resolve(e));
    instance.$on("cancel", () => reject());
  });
}
