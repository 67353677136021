/**
 * 验证身份证号是否存在
 * @param {string} val 身份证号
 */
export function checkIdCardNo(val) {
  if (!val) {
    return false;
  }

  const p =
    /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
  const code = val.substring(17);
  if (p.test(val)) {
    let sum = 0;
    for (let i = 0; i < 17; i++) {
      sum += Number(val[i]) * factor[i];
    }
    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }
  return false;
}

/**
 * 等待
 * @param {number} timeout 等待时间，ms
 * @return {Promise<void>}
 */
export function wait(timeout) {
  return new Promise((resolve, reject) => {
    try {
      const id = setTimeout(() => {
        resolve();
        clearTimeout(id);
      }, timeout);
    } catch (error) {
      reject(error);
    }
  });
}
