import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken,setUser } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    // userName
    name: "",
    userName: "",
    userId: "",
    nickName: "",
    avatar: "",
    shopId: "",
    shopName: "",
    role: {
      roleName: "",
    },
    roleName: "",
    phoneNumber: "",
    loginTime: "",
    roles: [],
    permissions: [],
    shop: {
      shopId: "",
      shopName: "",
      merchantType: null,
    },
  },

  mutations: {
    SET_SHOP: (state, value) => {
      Object.assign(state.shop, value);
    },
    SET_ROLE: (state, role) => {
      Object.assign(state.role, role);
    },
    SET_ROLE_NAME: (state, roleName) => {
      state.roleName = roleName;
    },
    SET_PHONE_NUMBER: (state, phoneNumber) => {
      state.phoneNumber = phoneNumber;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
      state.userName = name;
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId;
    },
    SET_NICK_NAME: (state, nickName) => {
      state.nickName = nickName;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_LOGIN_TIME: (state, loginTime) => {
      state.loginTime = loginTime;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_SHOP_ID: (state, shopId) => {
      state.shopId = shopId;
    },
    SET_SHOP_NAME: (state, shopName) => {
      state.shopName = shopName;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid)
          .then((res) => {
            setToken(res.token);
            commit("SET_TOKEN", res.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const user = res.user;
            const avatar =
              user.avatar == "" || user.avatar == null
                ? require("@/assets/images/profile.png")
                : user.avatar;
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit("SET_ROLES", res.roles);
              commit("SET_PERMISSIONS", res.permissions);
              commit("SET_ROLE", res.role);
            } else {
              commit("SET_ROLES", ["ROLE_DEFAULT"]);
            }
            commit("SET_USER_ID", user.userId);
            commit("SET_NAME", user.userName);
            commit("SET_NICK_NAME", user.nickName);
            commit("SET_AVATAR", avatar);
            commit("SET_ROLE_NAME", user.roleName);
            commit("SET_LOGIN_TIME", user.loginTime);
            commit("SET_PHONE_NUMBER", user.phoneNumber);
            commit("SET_SHOP_ID", user.shopId);
            commit("SET_SHOP_NAME", user.shopName);
            commit("SET_SHOP", res.shop);
            let obj = {
              userName:user.userName,
              userId:user.userId,
            }
            setUser(JSON.stringify(obj));
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            commit("SET_PERMISSIONS", []);
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
