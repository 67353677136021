import request from "@/utils/request";

/**
 * 获取打印模板列表
 */
export function getTemplateList(query) {
  return request({
    url: "/ticket/templateConfig/list",
    method: "get",
    params: query,
  });
}

/**
 * 获取打印模板详情
 */
export function getTemplateConfig(id) {
  return request({
    url: "/ticket/templateConfig/" + id,
    method: "get",
  });
}

/**
 * 修改打印模板
 */
export function updateTemplateConfig(data) {
  return request({
    url: "/ticket/templateConfig",
    method: "put",
    data: data,
  });
}

/**
 * 新增打印模板
 */
export function addTemplateConfig(data) {
  return request({
    url: "/ticket/templateConfig",
    method: "post",
    data: data,
  });
}

/**
 * 获取打印设置
 */
export function getPrintSettingList(query) {
  return request({
    url: "/ticket/printSetting/list",
    method: "get",
    params: query,
  });
}

/**
 * 修改打印设置
 */
export function updatePrintSettingList(data) {
  return request({
    url: "/ticket/printSetting",
    method: "put",
    data: data,
  });
}
