//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import { mapMutations } from "vuex";
import TextTabs from "@/components/TextTabs";
import { LockOnIcon, User1Icon } from "tdesign-icons-vue";
import config from "@/config";
export default {
  name: "Login",
  components: {
    TextTabs,
    LockOnIcon,
    User1Icon
  },
  data() {
    return {
      loginBg: require('@/assets/images/logo-bg.png'),
      logo: require('@/assets/images/logo.png'),
      activeTab: 0,
      title: process.env.VUE_APP_TITLE,
      codeUrl: "",
      loginForm: {
        // username: "admin",
        // username: "15888888888",
        username: "",
        // password: "admin123",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "blur",
          message: "请输入您的账号"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "请输入您的密码"
        }],
        code: [{
          required: true,
          trigger: "change",
          message: "请输入验证码"
        }]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined
    };
  },
  created() {
    switch (config.platform) {
      case "office":
        this.activeTab = 0;
        break;
      case "management":
        this.activeTab = 1;
        break;
      default:
        this.activeTab = 2;
        break;
    }
    this.getCode();
    this.getCookie();
  },
  methods: {
    ...mapMutations("office", ["setOffice"]),
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin({
      validateResult,
      firstError
    }) {
      console.log({
        validateResult,
        firstError
      });
      if (validateResult !== true) {
        this.$modal.msgError(firstError);
        return;
      }
      this.loading = true;
      if (this.loginForm.rememberMe) {
        Cookies.set("username", this.loginForm.username, {
          expires: 30
        });
        Cookies.set("password", encrypt(this.loginForm.password), {
          expires: 30
        });
        Cookies.set("rememberMe", this.loginForm.rememberMe, {
          expires: 30
        });
      } else {
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      this.$store.dispatch("Login", this.loginForm).then(() => {
        console.log(this.activeTab, 'activeTab--210');
        // this.$router.push({ name: "Index" });
        if (this.activeTab === 0) {
          this.setOffice(null);
          location.replace("/ticket-office.html");
        } else if (this.activeTab === 1) {
          location.replace("/");
        } else {
          location.replace("/prod-menu.html");
        }
      }).catch(() => {
        this.loading = false;
        if (this.captchaEnabled) {
          this.getCode();
        }
      });
    }
  }
};