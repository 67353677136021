/**
 * 会员接口
 */
import request from "@/utils/request";

// 查询会员信息列表
export function listMember(query) {
  return request({
    url: "/ticket/member/list",
    method: "get",
    params: query,
  });
}

// 查询会员信息详细
export function getMember(id) {
  return request({
    url: "/ticket/member/" + id,
    method: "get",
  });
}

// 新增会员信息
export function addMember(data) {
  return request({
    url: "/ticket/member/hairpin",
    method: "post",
    data: data,
  });
}

// 修改会员信息
export function updateMember(data) {
  return request({
    url: "/ticket/member",
    method: "put",
    data: data,
  });
}

// 删除会员信息
export function delMember(id) {
  return request({
    url: "/ticket/member/" + id,
    method: "delete",
  });
}
// 会员延期
export function memberExtension(data) {
  return request({
    url: "/ticket/member/memberExtension",
    method: "post",
    data: data,
  });
}

// 批量会员延期
export function memberExtensionList(data) {
  return request({
    url: "/ticket/member/memberExtensions",
    method: "post",
    data: data,
  });
}

// 会员注销
export function logOffMember(id) {
  return request({
    url: "/ticket/member/logOffMember/" + id,
    method: "get",
  });
}

// 批量会员注销
export function logOffMemberList(data) {
  return request({
    url: "/ticket/member/logOffMembers/",
    method: "get",
    params: data,
  });
}

// 续卡
export function continuationCard(data) {
  return request({
    url: "/ticket/member/continuationCard",
    method: "post",
    data: data,
  });
}

// 充值
export function memberRecharge(data) {
  return request({
    url: "/ticket/member/memberRecharge",
    method: "post",
    data: data,
  });
}

//补卡
export function cardReplacement(data) {
  return request({
    url: "/ticket/member/cardReplacement",
    method: "post",
    data: data,
  });
}

//退卡
export function refundCard(data) {
  return request({
    url: "/ticket/member/refundCard",
    method: "post",
    data: data,
  });
}

// 查询会员会员操作记录
export function memberOrderList(query) {
  return request({
    url: "/ticket/memberOrder/list",
    method: "get",
    params: query,
  });
}

// 查询会员订单列表
export function memberOrderAllList(query) {
  return request({
    url: "/ticket/memberOrder/webList",
    method: "get",
    params: query,
  });
}

// 查询会员订单详细
export function getMemberOrder(id) {
  return request({
    url: "/ticket/memberOrder/" + id,
    method: "get",
  });
}

/**
 * 会员头像上传
 * @param {*} data
 * @deprecated 改为统一上传文件接口
 * @typedef { import("../common.js") } common
 * @see {common.uploadFile}
 */
export function memberProfileUpload(data) {
  return request({
    url: "/ticket/common/upload",
    method: "post",
    data: data,
  });
}

// 获取会员详情（售票时）
export function getByDetails(value) {
  return request({
    url: "/ticket/member/getByDetails/" + value,
    method: "get",
  });
}

// 获取会员详情（查询会员时）
export function getByDetails2(value) {
  return request({
    url: "/ticket/member/getByDetail/" + value,
    method: "get",
  });
}
