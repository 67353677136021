import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CashierKeyBoard from './cashier-keyBoard.vue';
import { openScanCodeDialog } from "@/components/ScanCodeDialog/main";
import { getWindowPayList } from '@/common/logic';
import { mapState } from "vuex";
import { rentalOrderAdd, getPayStatus, settlementPay, getCheckoutStatus } from "@/api/ticket/rental";
import { cashierOrder, checkOrder } from "@/api/product/product";
import { listPayment } from "@/api/ticket/payment";
export default {
  name: "cashRegister",
  components: {
    CashierKeyBoard
  },
  props: {
    operaType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      checkePrint: false,
      //是否打印
      form: {},
      tabsName: '扫码支付',
      paymentList: [
        // { icon: 'icon-saomazhifu', name: '扫码支付', color: '#7057FF' },
        // { icon: 'icon-xianjinzhifu', name: '现金支付', color: '#FFD234' },
        // { icon: 'icon-yuezhifu', name: '余额支付', color: '#62C2FF' },
        // { icon: 'icon-zhifubao', name: '支付宝', color: '#009BFF' },
        // { icon: 'icon-weixinzhifu', name: '微信支付', color: '#00B42A' }
      ],
      showScanCodeDialog: false,
      timer: '',
      channelErrorMsg: '',
      payMentInfo: {},
      scanLoading: null,
      isEnterFlag: false
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    countAdvance() {
      if (this.operaType === 'cashier') {
        this.$set(this.form, 'payAmount', this.form.actualAmount / 100);
        this.$set(this.form, 'give', 0);
        return (this.form.actualAmount / 100).toFixed(2);
      } else if (this.operaType === 'return') {
        return this.changeToFixed(this.form.payAmount);
      } else {
        let sum = 0;
        if (this.form.rentalGoodsOrderItemsList && this.form.rentalGoodsOrderItemsList.length > 0) {
          sum = this.form.rentalGoodsOrderItemsList.reduce((quantity, cur) => {
            return quantity + +cur.advanceCharge;
          }, 0);
        } else {
          sum = 0;
        }
        this.$set(this.form, 'payAmount', sum);
        this.$set(this.form, 'give', 0);
        return this.changeToFixed(sum);
      }
    }
  },
  methods: {
    //金额格式化
    changeToFixed(val) {
      return Number(val).toFixed(2);
    },
    handleClosed() {
      if (this.form.payType == '2') {
        clearInterval(this.timer);
      }
      if (this.scanLoading) {
        this.scanLoading.close();
      }
      this.form = {};
      this.payMentInfo = {};
      this.$set(this.form, 'payAmount', 0);
      this.visible = false;
      clearInterval(this.timer);
      this.timer = null;
      this.isEnterFlag = false;
    },
    open(data) {
      this.initPaymentList();
      this.form = JSON.parse(JSON.stringify(data));
      if (this.operaType === 'cashier') {
        this.payMentInfo = JSON.parse(JSON.stringify(data));
      }
      this.visible = true;
    },
    async initPaymentList() {
      let payTypeList = [];
      if (this.operaType === 'cashier') {
        const res = await listPayment();
        payTypeList = res.rows;
      } else {
        payTypeList = await getWindowPayList(this.office);
      }
      this.paymentList = payTypeList.map(item => {
        if (item.payType == '2') {
          //扫码
          return {
            ...item,
            icon: 'icon-saomazhifu',
            color: '#7057FF'
          };
        } else if (item.payType == '1') {
          //现金
          return {
            ...item,
            icon: 'icon-xianjinzhifu',
            color: '#FFD234'
          };
        } else if (item.payType == '3') {
          //余额
          return {
            ...item,
            icon: 'icon-yuezhifu',
            color: '#62C2FF'
          };
        } else if (item.payType === '4') {
          //记账
          return {
            ...item,
            icon: 'icon-jizhang',
            color: '#FE8001'
          };
        } else {
          return {
            ...item,
            icon: 'icon-zidingyizhifu',
            color: '#1ED7A2'
          };
        }
      });
      this.tabsName = this.paymentList[0].paymentName;
      this.$set(this.form, 'payType', this.paymentList[0].payType);
      this.$set(this.form, 'paymentName', this.paymentList[0].paymentName);
      this.$set(this.form, 'paymentType', this.paymentList[0].paymentType);
    },
    changePayment(row) {
      if (row.payType === '3' && Number(this.form.balance) < Number((this.form.actualAmount / 100).toFixed(2)) || !this.form.memberId && row.payType === '3' || row.payType === '3' && this.form.memberId && Number(this.form.balance) < this.countAdvance) return;
      this.tabsName = row.paymentName;
      this.$set(this.form, 'payType', row.payType);
      this.$set(this.form, 'paymentName', row.paymentName);
      this.$set(this.form, 'paymentType', row.paymentType);
    },
    getQuickKey(data) {
      if (this.form.payType != '1') {
        return;
      }
      this.changeMoney('' + data);
    },
    getCleanKey() {
      if (this.form.payType != '1') {
        return;
      }
      if (this.form.payAmount) {
        let payAmount = String(this.form.payAmount);
        const a = payAmount.slice(0, payAmount.length - 1);
        this.changeMoney(a);
      }
    },
    getKey(data) {
      if (this.form.payType != '1') {
        return;
      }
      const a = this.form.payAmount + '' + data;
      this.changeMoney(a);
    },
    /** 提交 */
    async submit() {
      if (this.operaType === 'cashier') {
        this.createCashier();
      } else {
        this.form.scenicId = this.office.scenicId;
        this.form.payAmount = this.countAdvance;
        if (this.form.payType == '2') {
          if (this.isEnterFlag) return;
          this.isEnterFlag = true;
          try {
            const code = await openScanCodeDialog(this);
            this.form.qrCode = code;
          } catch (e) {
            this.isEnterFlag = false;
            return;
          }
        }
        this.scanLoading = this.$loading({
          lock: true,
          text: '支付中请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(128,128,128, 0.7)',
          customClass: 'scanLoading'
        });
        try {
          const pay = this.operaType === 'return' ? await settlementPay(this.form) : await rentalOrderAdd(this.form);
          if (this.form.payType == '2') {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              this.getPayStatus(pay.msg);
            }, 2000);
          } else {
            if (pay.code == 200) {
              this.handleClosed();
              this.$modal.msgSuccess('支付成功');
              if (this.checkePrint) {
                this.$emit("success", {
                  val: 1,
                  data: pay.data
                });
              } else {
                this.$emit("success", {
                  val: 2
                });
              }
            } else {
              this.scanLoading.close();
              this.$modal.msgError(pay.msg);
              this.scanLoading.clear();
              this.isEnterFlag = false;
              return;
            }
          }
        } catch (e) {
          this.scanLoading.close();
          this.isEnterFlag = false;
          return;
        }
      }
    },
    async getPayStatus(val) {
      try {
        const isSuccess = this.operaType === 'return' ? await getCheckoutStatus(val) : await getPayStatus(val);
        if (isSuccess.data.payStatus == '2002') {} else if (isSuccess.data.payStatus == '1') {
          clearInterval(this.timer);
          this.handleClosed();
          this.$modal.msgSuccess('支付成功');
        } else {
          this.scanLoading.visible = false;
          clearInterval(this.timer);
          this.isEnterFlag = false;
          this.$modal.msgError(isSuccess.data.payMsg);
          return;
        }
      } catch (e) {
        this.isEnterFlag = false;
        this.scanLoading.clear();
        this.$modal.msgError(e.message);
        return;
      }
    },
    // 轮询商品收款订单查询
    async getPayStatusCashier(val) {
      try {
        const isSuccess = await checkOrder({
          orderId: val
        });
        if (isSuccess.data) {
          clearInterval(this.timer);
          this.handleClosed();
          this.$modal.msgSuccess('支付成功');
          if (this.checkePrint) {
            this.$emit("success", {
              val: 1,
              data: pay.data
            });
          } else {
            this.$emit("success", {
              val: 2
            });
          }
        }
      } catch (e) {
        this.scanLoading.close();
        this.isEnterFlag = false;
        this.$modal.msgError(e.message);
        return;
      }
    },
    // 改变支付方式的金额
    changeMoney(val) {
      let value = val.match(/\d+(\.\d{0,2})?/) ? val.match(/\d+(\.\d{0,2})?/)[0] : '';
      let payAmount = Number(value);
      this.$set(this.form, 'payAmount', value);
      this.$set(this.form, 'give', payAmount > this.countAdvance ? this.changeToFixed(payAmount - this.countAdvance) : 0);
    },
    // 商品收款
    async createCashier() {
      const a = {
        actualAmount: (this.form.payAmount * 100).toFixed(),
        receivableAmount: (this.form.payAmount * 100).toFixed(),
        paymentPayType: this.form.payType
      };
      this.$set(this.payMentInfo, 'paymentList', [a]);
      if (this.form.payType == '2') {
        if (this.isEnterFlag) return;
        this.isEnterFlag = true;
        try {
          const code = await openScanCodeDialog(this);
          this.payMentInfo.paymentList[0].qrCode = code;
        } catch (e) {
          this.isEnterFlag = false;
          return;
        }
      }
      this.scanLoading = this.$loading({
        lock: true,
        text: '支付中请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(128,128,128, 0.7)',
        customClass: 'scanLoading'
      });
      console.log(this.payMentInfo, '支付信息');
      cashierOrder(this.payMentInfo).then(pay => {
        if (pay.code === 200) {
          if (this.form.payType == '2') {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              this.getPayStatusCashier(pay.msg);
            }, 2000);
          } else {
            this.handleClosed();
            this.$modal.msgSuccess('支付成功');
            if (this.checkePrint) {
              this.$emit("success", {
                val: 1,
                data: pay.data
              });
            } else {
              this.$emit("success", {
                val: 2
              });
            }
          }
        } else {
          this.scanLoading.close();
          this.isEnterFlag = false;
          this.$message.error(pay.msg);
        }
      }).finally(() => {
        this.scanLoading.close();
        this.isEnterFlag = false;
      });
    }
  }
};