//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import printerStyle from "!raw-loader!@/assets/styles/printer.css";
import QRCode from "qrcode";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { getCurrentValidityArr } from "@/common/logic";
function createSettings() {
  return {
    ifOpen: "2",
    printer: null,
    printWidth: null,
    printTemplate: null,
    printNum: null
  };
}
function createConfig() {
  return {
    ifCheckTicketNum: 0,
    ifRemainingTimes: 0,
    ifRemainingAmount: 0,
    ifCheckTicketAmount: 0,
    ifTicketCheckpoint: 0,
    ifCheckTicketTime: 0,
    ifScenic: 0,
    ifTicketCode: 0,
    ticketNo: 0,
    ifTicketName: 0,
    ifPersonNum: 0,
    ifUnitPrice: 0,
    ifAmount: 0,
    ifValidity: 0,
    ifSeller: 0,
    ifTicketWindow: 0,
    ifPrintTime: 0,
    ifEndNote1: 0,
    endNote1: "",
    printer: ""
  };
}
function createData() {
  return {
    scenicName: "",
    orderNo: "",
    ticketNo: "",
    ticketName: "",
    personNum: 0,
    ticketDiscountPrice: 0,
    subtotalAmount: 0,
    activationStaDate: "",
    createBy: "",
    ticketOfficeName: "",
    ticketWindowName: "",
    activationTicketDate: "",
    activationStartTime: "",
    activationEntDate: "",
    sTicketValidity: "",
    eTicketValidity: "",
    ifMemberTicketNo: 0,
    checkedNum: 1,
    totalNum: 1,
    remainingTicketCheckTimes: 0,
    remainingTicketCheckMoney: 0,
    memberCardNo: "",
    ticketCounterName: "",
    deviceName: ""
  };
}

/**
 * 检票小票打印模板
 */
export default {
  name: "CheckTicketPrintTemplate",
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: createData(),
      config: createConfig(),
      settings: createSettings(),
      ticketCodeImg: null,
      printTime: dayjs().format("YYYY-MM-DD HH:mm:ss")
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    // 景区的名称
    currentValidity() {
      return getCurrentValidityArr(this.data);
    }
  },
  methods: {
    setConfig(config) {
      this.config = createConfig();
      Object.assign(this.config, config);
    },
    setSettings(settings) {
      this.settings = createSettings();
      Object.assign(this.settings, settings);
    },
    setData(data) {
      this.data = createData();
      Object.assign(this.data, data);
      return QRCode.toDataURL([{
        data: this.data.ticketNo,
        mode: "alphanumeric"
      }], {
        errorCorrectionLevel: "M",
        version: 2
      }).then(url => {
        console.log(url);
        this.ticketCodeImg = url;
      }).catch(err => {
        console.error(err);
      });
    },
    async print(data, settings, config) {
      if (data) {
        await this.setData(data);
      }
      if (settings) {
        this.setSettings(settings);
      }
      if (config) {
        this.setConfig(config);
      }
      const printer = this.settings.printer;
      // if (!printer) {
      //   this.$modal.msgError("未设置打印机");
      //   return;
      // }

      this.printTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          // 打印机名称
          const style = `<style>${printerStyle}</style>`;
          const strHTML = this.$refs["template"].innerHTML;
          const LODOP = getLodop();
          LODOP.PRINT_INIT();
          LODOP.SET_PRINTER_INDEXA(printer);
          LODOP.SET_PRINT_PAGESIZE(3, this.settings.width, 10, "CreateCustomPage");
          // LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Width");
          // LODOP.SET_PRINT_MODE("RESELECT_PAGESIZE", 0);
          // LODOP.SET_PRINT_MODE("PROGRAM_CONTENT_BYVAR", true);
          // LODOP.SET_PRINT_STYLE("Bold", 1); // 设置加粗
          // LODOP.SET_SHOW_MODE("PREVIEW_IN_BROWSE", 1);
          // LODOP.SET_SHOW_MODE("SHOW_SCALEBAR", 1);
          LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", style + strHTML);
          // LODOP.SET_PRINT_STYLEA(0,"0",1)
          // LODOP.ADD_PRINT_TEXT(10,10,100,20,"aaa");
          // LODOP.SET_PRINT_STYLEA(0,"ContentVName","v1");
          LODOP.SET_LICENSES("深圳市智络科技有限公司", "BBFF47D5AB0D522C0007D05CDE387E65", "", "");
          LODOP.On_Return = (taskID, value) => {
            console.log(taskID, value);
            this.$emit("success");
            resolve();
            //   const fun = new Function(value.replace("单号", "双号"));
            //   fun();
            //   // LODOP.PREVIEW()
          };
          // LODOP.PREVIEW();
          LODOP.PRINT();
          // LODOP.SAVE_TO_FILE("aaa.png")
          // LODOP.PRINT_DESIGN();
        });
      });
    }
  }
};