/* unplugin-vue-components disabled */import __unplugin_components_16 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_15 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_14 from 'element-ui/lib/col';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/col.css';
import __unplugin_components_13 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_12 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_11 from 'element-ui/lib/col';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/col.css';
import __unplugin_components_10 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_9 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_8 from 'element-ui/lib/col';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/col.css';
import __unplugin_components_7 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_6 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_5 from 'element-ui/lib/col';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/col.css';
import __unplugin_components_4 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_3 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_2 from 'element-ui/lib/col';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/col.css';
import __unplugin_components_1 from 'element-ui/lib/row';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/row.css';
import __unplugin_components_0 from 'element-ui/lib/form';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form.css';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{ref:"basicInfoForm",attrs:{"model":_vm.info,"rules":_vm.rules,"label-width":"150px"}},[_c(__unplugin_components_1,[_c(__unplugin_components_2,{attrs:{"span":12}},[_c(__unplugin_components_3,{attrs:{"label":"表名称","prop":"tableName"}},[_c(__unplugin_components_4,{attrs:{"placeholder":"请输入仓库名称"},model:{value:(_vm.info.tableName),callback:function ($$v) {_vm.$set(_vm.info, "tableName", $$v)},expression:"info.tableName"}})],1)],1),_c(__unplugin_components_5,{attrs:{"span":12}},[_c(__unplugin_components_6,{attrs:{"label":"表描述","prop":"tableComment"}},[_c(__unplugin_components_7,{attrs:{"placeholder":"请输入"},model:{value:(_vm.info.tableComment),callback:function ($$v) {_vm.$set(_vm.info, "tableComment", $$v)},expression:"info.tableComment"}})],1)],1),_c(__unplugin_components_8,{attrs:{"span":12}},[_c(__unplugin_components_9,{attrs:{"label":"实体类名称","prop":"className"}},[_c(__unplugin_components_10,{attrs:{"placeholder":"请输入"},model:{value:(_vm.info.className),callback:function ($$v) {_vm.$set(_vm.info, "className", $$v)},expression:"info.className"}})],1)],1),_c(__unplugin_components_11,{attrs:{"span":12}},[_c(__unplugin_components_12,{attrs:{"label":"作者","prop":"functionAuthor"}},[_c(__unplugin_components_13,{attrs:{"placeholder":"请输入"},model:{value:(_vm.info.functionAuthor),callback:function ($$v) {_vm.$set(_vm.info, "functionAuthor", $$v)},expression:"info.functionAuthor"}})],1)],1),_c(__unplugin_components_14,{attrs:{"span":24}},[_c(__unplugin_components_15,{attrs:{"label":"备注","prop":"remark"}},[_c(__unplugin_components_16,{attrs:{"type":"textarea","rows":3},model:{value:(_vm.info.remark),callback:function ($$v) {_vm.$set(_vm.info, "remark", $$v)},expression:"info.remark"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }