import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: '',
  props: {
    prodList: {
      type: Array,
      default: () => []
    },
    operaType: {
      type: String,
      default: ''
    }
  },
  computed: {
    compTypeStyle() {
      return item => {
        if (item.chargeType === 0) {
          return 'color00B';
        } else {
          return 'color165';
        }
      };
    },
    chargeTypeName() {
      return row => {
        if (row.chargeType == 1) {
          let arr = row.rentalTimeChargeStageRulesList.map(item => `${item.startVal}到${item.endVal}分钟${item.stageRent}元`).join(",");
          return `阶梯内:
                    ${arr},
                    阶梯外:
                    每${row.outUnitValue}分钟${row.outRuleRent}元,
                    封顶价：${row.topRent ? row.topRent : 0}元`;
        } else {
          return row.name;
        }
      };
    }
  },
  data() {
    return {
      showNoBarProdDialog: false,
      skuProdInfo: '',
      showSkuDialog: false
    };
  },
  methods: {
    addProd(item, index) {
      this.$emit('e-prod', {
        row: {
          ...item,
          quantity: 1,
          myPrice: item.goodsSku && item.goodsSku.length > 0 ? (item.goodsSku[0].price / 100).toFixed(2) : 0
        },
        index
      });
    },
    //金额格式化
    changeToFixed(val) {
      return Number(val).toFixed(2);
    }
  },
  created() {},
  mounted() {},
  destroyed() {}
};