//
//
//
//
//
//
//
//
//

import { elementTypeProvider } from "./custom-element-type-provider.js";
import { printTemplate } from "./custom-print-template.js";
import { hiprint } from "@/libs/hiprint/hiprint.bundle.js";
import stringRandom from "string-random";
import $ from "jquery";
export default {
  name: "GeneralTicketPrintTemplate",
  props: {
    // settingContainer: {
    //   type: String,
    //   default: null,
    // },
    // paginationContainer: {
    //   type: String,
    //   default: null,
    // },
    // printTemplate: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const id = stringRandom(10);
    return {
      id,
      hiprintTemplate: null
    };
  },
  methods: {
    init(options) {
      // const el = document.getElementById(this.id);
      // el.innerHTML = "";
      $(`#${this.id}`).empty();
      hiprint.init({
        host: "http://localhost:17522",
        token: "123456",
        providers: [new elementTypeProvider()]
      });
      let template = printTemplate;
      if (options.template) {
        template = options.template;
      }
      this.hiprintTemplate = new hiprint.PrintTemplate({
        template,
        settingContainer: options.settingContainer,
        paginationContainer: options.paginationContainer
      });
      this.hiprintTemplate.design(`#${this.id}`, {
        axisEnabled: true
      });
    },
    clear() {
      this.hiprintTemplate.clear();
    },
    rotatePaper() {
      this.hiprintTemplate.rotatePaper();
    },
    setPaper(paperTypeOrWidth, height) {
      this.hiprintTemplate.setPaper(paperTypeOrWidth, height);
    },
    print(printData) {
      this.hiprintTemplate.print(printData);
    },
    print2(printData, options) {
      this.hiprintTemplate.print2(printData, options);
    },
    getHtml(printData) {
      return this.hiprintTemplate.getHtml(printData);
    },
    getJson() {
      return this.hiprintTemplate.getJson();
    },
    getJsonTid() {
      return this.hiprintTemplate.getJsonTid();
    }
  }
};