/**
 * 将毫秒，转换成时间字符串。例如说，xx 分钟
 *
 * @param ms 毫秒
 * @returns {string} 字符串
 */
export function getDate(ms) {
  const day = Math.floor(ms / (24 * 60 * 60 * 1000));
  const hour =  Math.floor((ms / (60 * 60 * 1000) - day * 24));
  const minute =  Math.floor(((ms / (60 * 1000)) - day * 24 * 60 - hour * 60));
  const second =  Math.floor((ms / 1000 - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60));
  if (day > 0) {
    return day + "天" + hour + "小时" + minute + "分钟";
  }
  if (hour > 0) {
    return hour + "小时" + minute + "分钟";
  }
  if (minute > 0) {
    return minute + "分钟";
  }
  if (second > 0) {
    return second + "秒";
  } else {
    return 0 + "秒";
  }
}

export function beginOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function endOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

export function betweenDay(date1, date2) {
  date1 = convertDate(date1);
  date2 = convertDate(date2);
  // 计算差值
  return Math.floor((date2.getTime() - date1.getTime()) / (24 * 3600 * 1000));
}

export function formatDate(date, fmt) {
  date = convertDate(date);
  const o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) { // 年份
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

export function addTime(date, time) {
  date = convertDate(date);
  return new Date(date.getTime() + time);
}

export function convertDate(date) {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
}

// 获取几天前或几天后的日期
export function getDay(day,nowDay) {
  let today;
  if(nowDay){
    today = new Date(nowDay);
  }else{
    today = new Date();
  }

  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds); //注意，这行是关键代码
  var tYear = today.getFullYear();
  var tMonth = today.getMonth();
  var tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
}

export function doHandleMonth(month) {
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}

// 获取本月第一天
export function getStartTime() {
  let date = new Date()
  date.setDate(1) // 将当前时间的日期设置成第一天
  let year= date.getFullYear()  // 得到当前年份
  let month = date.getMonth()  + 1 // 得到当前月份（0-11月份，+1是当前月份）
  month  = month > 10 ? month :'0' + month // 补零
  let day  = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
  let strTime = year +'-'+ month +'-'+ day + ' 00:00:00'
  return new Date(strTime) // 这里传入的是字符串
}

//判断两个时间段是否重合
export function isOverlap(startTime1, endTime1, startTime2, endTime2) {
  if (startTime1 > endTime2 || startTime2 > endTime1) {
    return false;
  } else {
    return true;
  }
}



//时间字符串转为时间格式
export function convertToTimeFormat(timeString) {
  const parts = timeString.split(':');
  // 假设时间为24小时制
  return new Date(1970, 0, 1, ...parts);
}

export function convertToHourMinute(isoString) {
  const date = new Date(isoString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
// 在日期格式中提取时分
export function dateGetHourMinute(dateParam) {
  const date = dateParam;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
// 分钟转为几小时几分钟
export function minuteToTime(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;
  return [hours,remainingMinutes]
}
// 分钟转为几天几小时几分钟
export function minuteToDay(value){
  var time=[]
  var day =parseInt(value/60/24)
  var hour=parseInt(value/60%24)
  var min=parseInt(value%60)
  time[0]=day>0?day:0
  time[1]=hour>0?hour:0
  time[2]=min>0?parseFloat(min):0
  return  time;
}

//获取本周一
export function  BMondayTime(){
  const now = new Date();
  const nowTime = now.getTime();
  const day = now.getDay();
  const oneDayTime = 24 * 60 * 60 * 1000;
//本周的周一
  let BMondayTimeStr = nowTime - (day - 1) * oneDayTime;
  return BMondayTimeStr;
}
//获取某天的本周天
export function  BSundayTime(time){
  const now = new Date(time);
  const nowTime = now.getTime();
  const day = now.getDay();
  const oneDayTime = 24 * 60 * 60 * 1000;
//本周的周日
  let BSundayTimeStr = nowTime + (7 - day) * oneDayTime;
  return BSundayTimeStr;
}



