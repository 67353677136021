import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DragDiv from "@/components/DragDiv";
import { dateRangePickerShortcuts } from "@/utils/el-utils";
import { getTicketing, addTicketing, updateTicketing } from "@/api/ticket/ticketing";
import { scenicUsableList } from "@/api/ticket/scenic";
import { operationUsableList } from "@/api/ticket/operation";
import { ruleUsableList } from "@/api/ticket/rule";
import { timesUsableList } from "@/api/ticket/times";
import { typeUsableList } from "@/api/ticket/type";
import { modificationTimeList, ticketDisableList, idCardPhotoList, modifyThePriceList, issueWarrantTypeList, activationPeriodTypeList, validityPeriodTypeList, refundTicketTypeList, activationTicketTypeList, ifTicketRefundList } from "@/common/form";
import dayjs from "dayjs";
import { mapState } from "vuex";
function createForm() {
  return {
    id: null,
    ticketName: null,
    ticketNum: null,
    ticketTag: null,
    scenicId: null,
    operationId: null,
    touristTypeId: null,
    makeType: "0",
    ticketStyleId: null,
    realNameRule: null,
    idCardPhoto: "0",
    freeParkingTime: null,
    numberRuleId: null,
    isTicketPackage: "0",
    state: "0",
    issueWarrantType: "0",
    idCardNo: null,
    idCardAddress: null,
    minAge: null,
    maxAge: null,
    dayNumber: null,
    monthNumber: null,
    yearMunber: null,
    marketPrice: "0",
    sellingPrice: "0",
    isVip: "0",
    vipPrice: "0",
    costPrice: null,
    costOfProduction: null,
    serviceCharge: null,
    modifyThePrice: "0",
    activationTicketType: "0",
    activationTicketDate: "0",
    activationTicketEndDate: null,
    activationStartTime: null,
    activationEndTime: null,
    refundTicketType: "1",
    refundTicketRatio: "1",
    refundTicketPrice: "0",
    ticketTimesId: null,
    ifTicketRefund: 1,
    minTicketNumber: "1",
    maxTicketNumber: "0",
    modificationTime: 0,
    // v1.7 新增时默认排序传0
    sort: 0,
    periodOfValidityType: "0",
    periodOfValidityEndDate: "1",
    periodOfValidityStartTime: null,
    periodOfValidityEndTime: null,
    periodOfValidityNumberType: "0",
    fixedNumber: "0",
    periodOfValidityPrice: "1",
    amountInForce: null,
    //会员线上
    settingSwitch: false,
    ifName: "2",
    ifPhone: "3",
    ifFacePhoto: "0",
    ifAddress: "0",
    ifIdCard: "0",
    ifIdCardPhoto: "0",
    ifEffectiveInformation: "0",
    backgroundImg: null,
    useNotice: null,
    // 散客团队线上
    onlineSales: null,
    shareTickets: null,
    salesDisplay: null,
    priceDisplay: null,
    scienceLabel: null,
    useDate: null,
    presaleTime: null,
    costIncludes: null,
    costNotIncludes: null,
    entWay: null,
    customWay: null,
    entTime: null,
    setMaxTime: null,
    ifSelTime: false,
    maxTime: null,
    detailed: null,
    ifPrompt: null,
    prompt: null,
    note: null,
    facePhotoNotice: null,
    operationType: null,
    fileNotice: null,
    AdmissionTime: null,
    //入园时间 给entTime 和 maxTime 赋值
    scienceLabelArr: [{
      value: ""
    }],
    //景区标签数组
    entWayList: [],
    //激活日期
    activationRange: [dayjs().startOf("d").format("YYYY-MM-DD HH:mm:ss"), dayjs().endOf("d").format("YYYY-MM-DD HH:mm:ss")],
    //有效日期
    periodOfValidityRange: [dayjs().startOf("d").format("YYYY-MM-DD HH:mm:ss"), dayjs().endOf("d").format("YYYY-MM-DD HH:mm:ss")]
  };
}

/**
 * 添加门票页
 * FEAT 当业务类型为散客时，展示线上售卖
 * FEAT 当业务类型为会员时，展示会员线上售卖
 */
export default {
  name: "AddTicket",
  components: {
    DragDiv
  },
  props: {
    // "add" "edit"
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      ifTicketRefundList,
      pickerOptions: {
        shortcuts: dateRangePickerShortcuts
      },
      activationTicketTypeList,
      refundTicketTypeList,
      activationPeriodTypeList,
      validityPeriodTypeList,
      issueWarrantTypeList,
      modificationTimeList,
      idCardPhotoList,
      ticketDisableList,
      modifyThePriceList,
      scenicUsableList: [],
      operationUsableList: [],
      ruleUsableList: [],
      timesUsableList: [],
      typeUsableList: [],
      //门票表单参数
      ticketForm: createForm(),
      // 表单校验
      ticketRules: {
        ticketName: [{
          required: true,
          message: "请输入门票名称",
          trigger: "blur"
        }],
        ticketTag: [{
          required: true,
          message: "请输入门票标签",
          trigger: "blur"
        }],
        scenicId: [{
          required: true,
          message: "请选择所属景区",
          trigger: "blur"
        }],
        operationId: [{
          required: true,
          message: "请选择所业务类型",
          trigger: "blur"
        }],
        numberRuleId: [{
          required: true,
          message: "请选择票号规则",
          trigger: "blur"
        }],
        marketPrice: [{
          required: true,
          message: "请输入市场价",
          trigger: "blur"
        }],
        sellingPrice: [{
          required: true,
          message: "请输入售价",
          trigger: "blur"
        }],
        refundTicketRatio: [{
          required: true,
          message: "请输入退还比例",
          trigger: "blur"
        }],
        refundTicketPrice: [{
          required: true,
          message: "请输入固定退还金额",
          trigger: "blur"
        }],
        ticketTimesId: [{
          required: true,
          message: "请选择售票时间方案",
          trigger: "blur"
        }],
        minTicketNumber: [{
          required: true,
          message: "请输入最小售票数量",
          trigger: "blur"
        }],
        maxTicketNumber: [{
          required: true,
          message: "请输入最大售票数量",
          trigger: "blur"
        }],
        periodOfValidityEndDate: [{
          required: true,
          message: "请输入有效结束日期",
          trigger: "blur"
        }],
        ifIdCardPhoto: [{
          required: true,
          message: "请选择上传身份证照片",
          trigger: "blur"
        }],
        ifEffectiveInformation: [{
          required: true,
          message: "请选择上传有效证件资料",
          trigger: "blur"
        }],
        backgroundImg: [{
          required: true,
          message: "请选择门会员卡背景图片",
          trigger: "blur"
        }],
        useNotice: [{
          required: true,
          message: "请输入使用说明",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            console.log(value);
            const domParser = new DOMParser();
            const doc = domParser.parseFromString(value, "text/html");
            console.dir(doc);
            const imgs = doc.body.querySelectorAll("img");
            console.log(imgs.length);
            const content = doc.body.innerText;
            console.log(content);
            if (content == "" && imgs.length == 0) {
              callback("使用说明不能为空");
            } else {
              callback();
            }
          },
          message: "请输入使用说明",
          trigger: "blur"
        }],
        costIncludes: [{
          required: true,
          message: "请输入费用包含",
          trigger: "blur"
        }],
        costNotIncludes: [{
          required: true,
          message: "请输入费用不包含",
          trigger: "blur"
        }],
        entWayList: [{
          required: true,
          message: "请选择入园方式",
          trigger: "blur"
        }],
        AdmissionTime: [{
          required: true,
          message: "请选择入园时间",
          trigger: "blur"
        }],
        detailed: [{
          required: true,
          message: "请输入详细介绍",
          trigger: "blur"
        }, {
          validator: (rule, value, callback) => {
            console.log(value);
            const domParser = new DOMParser();
            const doc = domParser.parseFromString(value, "text/html");
            const content = doc.body.innerText;
            const imgs = doc.body.querySelectorAll("img");
            if (content == "" && imgs.length == 0) {
              callback("详细介绍不能为空");
            } else {
              callback();
            }
          },
          message: "请输入详细介绍",
          trigger: "blur"
        }],
        prompt: [{
          required: true,
          message: "请输入提示内容",
          trigger: "blur"
        }]
      },
      //获取身份照片
      idCardPhoto: false,
      ticketId: null
    };
  },
  computed: {
    ...mapState(["user"]),
    // 商户类型是否是游乐场版
    isAmusementPark() {
      return this.user.shop.merchantType == "2";
    },
    // 业务类型是否是会员
    isMemberType() {
      const op = this.operationUsableList.find(v => {
        return v.id == this.ticketForm.operationId;
      });
      return op != null && op.operationType == "2";
    }
  },
  async created() {
    if (this.type === "edit") {
      const ticketId = this.$route.params.ticketId;
      this.ticketId = ticketId;
      await this.initTicketing();
    } else {
      this.ticketForm.operationId = this.$route.params.operationId;

      // FEAT v1.8 游乐场版相关初始值
      if (this.isAmusementPark) {
        // “有效次数类型”默认固定次数
        this.ticketForm.periodOfValidityNumberType = "1";

        // FEAT v1.8 门票标签添加默认值「默认标签」
        this.ticketForm.ticketTag = "默认标签";
      }
    }
    this.getAllList();
  },
  methods: {
    activationTicketTypeChange(e) {
      if (e == "0") {
        this.ticketForm.modificationTime = 0;
      }
    },
    /**
     * 固定金额（amountInForce）不能大于售价
     * 固定退还金额（refundTicketPrice）不能大于售价
     * 会员价（vipPrice）不能大于售价
     */
    sellingPriceChange(e) {
      const sellingPrice = parseFloat(e);
      const amountInForce = parseFloat(this.ticketForm.amountInForce);
      const refundTicketPrice = parseFloat(this.ticketForm.refundTicketPrice);
      const vipPrice = parseFloat(this.ticketForm.vipPrice);
      this.ticketForm.amountInForce = Math.min(amountInForce, sellingPrice);
      this.ticketForm.refundTicketPrice = Math.min(refundTicketPrice, sellingPrice);
      this.ticketForm.vipPrice = Math.min(vipPrice, sellingPrice);
    },
    /**
     * FEAT 会员业务类型只能售票激活，只能固定次数
     */
    operationChange(e) {
      const op = this.operationUsableList.find(v => {
        return v.id == e;
      });
      const isMember = op != null && op.operationType == "2";
      if (isMember) {
        // TODO 如果会员票需要激活期则删除下一行
        this.ticketForm.activationTicketType = "0";
        this.ticketForm.periodOfValidityNumberType = "1";
      }
    },
    removeTag(index) {
      this.ticketForm.scienceLabelArr.splice(index, 1);
    },
    addTag() {
      this.ticketForm.scienceLabelArr.push({
        value: ""
      });
    },
    goBack() {
      this.$tab.closePage();
      this.$router.push({
        name: "Operation"
      });
    },
    /**线上售票确认 */
    switchChange(val, attr) {
      if (val === false) {
        this.$confirm("确定关闭线上售票吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).catch(() => {
          this.ticketForm[attr] = true;
        });
      }
    },
    /** 获取所以可用列表 */
    getAllList() {
      //获取景区列表
      scenicUsableList().then(res => {
        this.scenicUsableList = res.data;
      });
      //获取业务列表
      operationUsableList().then(res => {
        this.operationUsableList = res.data;
        if (this.type == "add" && this.isAmusementPark) {
          // FEAT v1.8 游乐场版门票类型默认「普通票」
          const op = this.operationUsableList.find(v => {
            return v.operationType == "0";
          });
          this.ticketForm.operationId = op.id;
        }
        this.operationChange(this.ticketForm.operationId);
      });
      //获取票号规则列表
      ruleUsableList().then(res => {
        this.ruleUsableList = res.data;
      });
      //获取售票方案列表
      timesUsableList().then(res => {
        this.timesUsableList = res.data;
        if (this.type == "add" && this.isAmusementPark) {
          // FEAT v1.8 游乐场版「售票时间方案」默认第一个
          this.ticketForm.ticketTimesId = this.timesUsableList[0]?.id;
        }
      });
      //获取游客类型列表
      typeUsableList().then(res => {
        this.typeUsableList = res.data;
      });
    },
    initTicketing() {
      return getTicketing(this.ticketId).then(response => {
        Object.assign(this.ticketForm, response.data);

        //处理回显入园时间
        if (this.ticketForm.entTime) {
          const arr = this.ticketForm.entTime.split("-");
          this.ticketForm.AdmissionTime = arr;
        }

        // 处理回显景区标签
        if (this.ticketForm.scienceLabel) {
          this.ticketForm.scienceLabelArr = this.ticketForm.scienceLabel?.split(",").map(v => {
            return {
              value: v
            };
          });
        }

        //回显入园方式
        if (this.ticketForm.entWay) {
          const arr = this.ticketForm.entWay.split(",");
          this.ticketForm.entWayList = arr;
        }

        //回显激活日期
        if (this.ticketForm.activationStartTime && this.ticketForm.activationEndTime) {
          this.ticketForm.activationRange = [this.ticketForm.activationStartTime, this.ticketForm.activationEndTime];
        }

        //回显有效日期
        if (this.ticketForm.periodOfValidityStartTime && this.ticketForm.periodOfValidityEndTime) {
          this.ticketForm.periodOfValidityRange = [this.ticketForm.periodOfValidityStartTime, this.ticketForm.periodOfValidityEndTime];
        }
      });
    },
    /** 提交 */
    async submitForm() {
      await this.$refs["ticketForm"].validate();
      const operation = this.operationUsableList.find(v => v.id == this.ticketForm.operationId);
      this.ticketForm.operationType = operation.operationType;

      // 处理景区标签
      if (this.ticketForm.scienceLabelArr) {
        this.ticketForm.scienceLabel = this.ticketForm.scienceLabelArr.filter(v => v.value != "").map(v => v.value).join(",");
      }

      // 处理入园时间
      if (this.ticketForm.AdmissionTime && this.ticketForm.AdmissionTime.length > 0) {
        this.ticketForm.entTime = this.ticketForm.AdmissionTime.join("-");
      }

      //处理使用日期
      // if (this.ticketForm.useDate == 2) {
      //   if (!this.ticketForm.presaleTime) {
      //     this.$modal.msgWarning("请输入预售日期");
      //   }
      // }

      // 处理入园方式
      if (this.ticketForm.entWayList && this.ticketForm.entWayList.length > 0) {
        this.ticketForm.entWay = this.ticketForm.entWayList.join(",");
        if (this.ticketForm.entWayList.includes("5") && !this.ticketForm.customWay) {
          this.$modal.msgWarning("请输入自定义入园方式");
        }
      }

      // 处理有效日期起止时间
      if (this.ticketForm.periodOfValidityRange && this.ticketForm.periodOfValidityRange.length > 0) {
        this.ticketForm.periodOfValidityStartTime = this.ticketForm.periodOfValidityRange[0];
        this.ticketForm.periodOfValidityEndTime = this.ticketForm.periodOfValidityRange[1];
      } else {
        this.ticketForm.periodOfValidityStartTime = null;
        this.ticketForm.periodOfValidityEndTime = null;
      }

      // 处理激活日期起止时间
      if (this.ticketForm.activationRange && this.ticketForm.activationRange.length > 0) {
        this.ticketForm.activationStartTime = this.ticketForm.activationRange[0];
        this.ticketForm.activationEndTime = this.ticketForm.activationRange[1];
      } else {
        this.ticketForm.activationStartTime = null;
        this.ticketForm.activationEndTime = null;
      }
      if (this.ticketForm.id != null) {
        updateTicketing(this.ticketForm).then(res => {
          this.$modal.msgSuccess(res.msg);
          this.$router.push({
            name: "Operation"
          });
        });
      } else {
        addTicketing(this.ticketForm).then(res => {
          this.$modal.msgSuccess(res.msg);
          this.$router.push({
            name: "Operation"
          });
        });
      }
    }
  }
};