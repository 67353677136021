/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./PanelGroup.vue?vue&type=template&id=51a4f042&scoped=true"
import script from "./PanelGroup.vue?vue&type=script&lang=js"
export * from "./PanelGroup.vue?vue&type=script&lang=js"
import style0 from "./PanelGroup.vue?vue&type=style&index=0&id=51a4f042&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51a4f042",
  null
  
)

export default component.exports