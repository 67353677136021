/**
 * 首页统计
 */
import request from "@/utils/request";

/**
 * 获取数据统计
 */
export function getDataStatistics(data) {
  return request({
    url: "/data/dtatistics/index/" + data,
    method: "get",
  });
}

/**
 * 获取销售图表
 */
export function getSalesEcharts(data) {
  return request({
    url: "/data/dtatistics/salesEcharts/" + data,
    method: "get",
  });
}

/**
 * 获取公告
 */
export function getNotice() {
  return request({
    url: "/room/yu/getDocs",
    method: "get",
  });
}

/**
 * 获取售票端首页统计数据
 */
export function getSaleWindowData() {
  return request({
    url: "/saleWindowsData/data",
    method: "post",
  });
}
