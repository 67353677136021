/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./addTicket.vue?vue&type=template&id=7af12976&scoped=true"
import script from "./addTicket.vue?vue&type=script&lang=js"
export * from "./addTicket.vue?vue&type=script&lang=js"
import style0 from "./addTicket.vue?vue&type=style&index=0&id=7af12976&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af12976",
  null
  
)

export default component.exports