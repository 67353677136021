//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";
import "bootstrap";
import { configElementTypeProvider } from "@/libs/hiprint/custom_test/config-etype-provider.js";
import { configPrintJson } from "@/libs/hiprint/custom_test/config-print-json.js";
import { printData } from "@/libs/hiprint/custom_test/config-print-data.js";
// import { customElementTypeProvider } from "@/libs/hiprint/custom_test/custom-etype-provider.js";
// import { customPrintJson } from "@/libs/hiprint/custom_test/custom-print-json.js";
// import { printData } from "@/libs/hiprint/custom_test/print-data.js";
import "@claviska/jquery-minicolors";
import { hiprint } from "@/libs/hiprint/hiprint.bundle.js";
import "@/libs/hiprint/plugins/jquery.hiwprint.js";
export default {
  data() {
    return {
      hiprintTemplate: null,
      customWidth: "",
      customHeight: "",
      textareaHtml: "",
      textareaJson: "",
      prevViewHtml: ""
    };
  },
  mounted() {
    this.initHiprint();
  },
  methods: {
    initHiprint() {
      console.log(hiprint);

      //初始化打印插件
      hiprint.init({
        host: "http://localhost:17522",
        token: "vue-plugin-hiprint",
        providers: [new customElementTypeProvider()]
        // providers: [new configElementTypeProvider()],
      });

      //设置左侧拖拽事件
      // hiprint.PrintElementTypeManager.build('.hiprintEpContainer', 'testModule');
      hiprint.PrintElementTypeManager.buildByHtml($(".ep-draggable-item"));
      this.hiprintTemplate = new hiprint.PrintTemplate({
        template: customPrintJson,
        // template: configPrintJson,
        settingContainer: "#PrintElementOptionSetting"
        // paginationContainer: "#hiprint-printPagination",
      });
      console.log(this.hiprintTemplate);

      //打印设计
      this.hiprintTemplate.design("#hiprint-printTemplate");
    },
    getHtmlToTextarea() {
      this.textareaHtml = this.hiprintTemplate.getHtml(printData)[0].outerHTML;
    },
    getJsonToTextarea() {
      this.textareaJson = JSON.stringify(this.hiprintTemplate.getJson());
    },
    dialogPrint() {
      this.hiprintTemplate.printByHtml(this.$refs["prevView"]);
    },
    print() {
      this.hiprintTemplate.print2(printData);
    },
    preview() {
      const prevView = this.hiprintTemplate.getHtml(printData)[0];
      this.$refs["prevView"].appendChild(prevView);
      $("#myModal").modal("show");
    },
    //调整纸张
    setPaper(paperTypeOrWidth, height) {
      this.hiprintTemplate.setPaper(paperTypeOrWidth, height);
    },
    //旋转
    rotatePaper() {
      this.hiprintTemplate.rotatePaper();
    },
    // 清空
    clearTemplate() {
      this.hiprintTemplate.clear();
    }
  }
};