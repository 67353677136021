//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTemplateConfig, updateTemplateConfig } from "@/api/ticket/template";

/**
 * 收银打印模板设计
 */
export default {
  name: "CashierTemplateDesign",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        printer: "",
        ifScenic: 0,
        ifTicketCode: 0,
        ticketNo: 0,
        ifTicketName: 0,
        ifPersonNum: 0,
        ifUnitPrice: 0,
        ifAmount: 0,
        ifValidity: 0,
        ifSeller: 0,
        ifTicketWindow: 0,
        ifPrintTime: 0,
        ifEndNote1: 0,
        ifEndNote2: 0,
        endNote1: "",
        endNote2: "",
        ifMemberName: 0,
        ifMemberCard: 0,
        ifMemberTicket: 0,
        ifTicketType: 0,
        ifOrderNo: 0,
        ifSaleWindow: 0,
        ifSaleTime: 0,
        ifOperator: 0,
        ifNum: 0,
        ifAmountReceivable: 0,
        ifActualAmount: 0,
        ifSettlementMethod: 0,
        ifChange: 0,
        ifRechargeAmount: 0,
        ifRechargeTimes: 0,
        ifGiftAmount: 0,
        ifGiveNumber: 0
      }
    };
  },
  watch: {
    form: {
      handler(value) {
        console.log(value);
        this.$refs["printTemplate"].setConfig(value);
      },
      deep: true
    }
  },
  created() {
    this.initPrintConfig();
  },
  mounted() {
    this.$refs["printTemplate"].setData({
      scenicName: "示例景区",
      orderNo: "1234567890",
      ticketOfficeName: "检票点A",
      ticketWindowName: "窗口B",
      createTime: "2023-01-01 00:00:00",
      createBy: "售票员A",
      ticketingOrderInfoList: [{
        ticketName: "门票1",
        ticketDiscountPrice: 90,
        ticketNum: 1,
        personNum: 1
      }, {
        ticketName: "门票2",
        ticketDiscountPrice: 50,
        ticketNum: 1,
        personNum: 2
      }],
      orderAmount: 190,
      actualPayment: 200,
      payWay: "现金",
      orderChangeMoney: 10
    });
  },
  methods: {
    async initPrintConfig() {
      const res = await getTemplateConfig(this.id);
      Object.assign(this.form, res.data);
    },
    goBack() {
      this.$tab.closePage();
    },
    print() {
      this.$refs["printTemplate"].print();
    },
    async save() {
      const res = await updateTemplateConfig(this.form);
      if (res.code == 200) {
        this.$modal.msgSuccess(res.msg);
        this.$tab.closePage();
      }
    }
  }
};