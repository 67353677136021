import request from "@/utils/request";

// 查询商品列表
export function getCahierOrder(query) {
  return request({
    url: "/goods/cashier/orders/list",
    method: "get",
    params: query,
  });
}

// 获取订单详情
export function getCahierDetail(id) {
  return request({
    url: "/goods/cashier/orders/"+id,
    method: "get",
  });
}

// 退货
export function returnCashier(query) {
  return request({
    url: "/goods/cashier/orders/returns",
    method: "post",
    data: query,
  });
}
