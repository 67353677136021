import request from "@/utils/request";

// 查询团队联系人列表
export function listPrincipal(query) {
  return request({
    url: "/ticket/principal/list",
    method: "get",
    params: query,
  });
}

// 查询团队联系人详细
export function getPrincipal(id) {
  return request({
    url: "/ticket/principal/" + id,
    method: "get",
  });
}

// 新增团队联系人
export function addPrincipal(data) {
  return request({
    url: "/ticket/principal",
    method: "post",
    data: data,
  });
}

// 修改团队联系人
export function updatePrincipal(data) {
  return request({
    url: "/ticket/principal",
    method: "put",
    data: data,
  });
}

// 删除团队联系人
export function delPrincipal(id) {
  return request({
    url: "/ticket/principal" + id,
    method: "delete",
  });
}
