import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scenicUsableList } from "@/api/ticket/scenic";
import { getMeituanShopList, bindScenic, getBindShopList } from "@/api/ticket/ota";
function createForm() {
  return {
    id: null,
    scenicId: null
  };
}
export default {
  name: "AddBindShopDialog",
  data() {
    return {
      visible: false,
      form: createForm(),
      rules: {
        id: [{
          required: true,
          message: "店铺不能为空",
          trigger: "blur"
        }],
        scenicId: [{
          required: true,
          message: "景区不能为空",
          trigger: "blur"
        }]
      },
      otaShopList: [],
      scenicList: []
    };
  },
  methods: {
    async initOtaShopList() {
      const res = await getMeituanShopList();
      const res2 = await getBindShopList();
      console.log(res.data);
      console.log(res2.data);
      this.otaShopList = res.data.map(v => {
        const isBind = res2.data.some(v2 => v2.openShopUuid == v.openShopUuid);
        console.log(isBind);
        return {
          ...v,
          disabled: isBind
        };
      });
    },
    async initScenicList() {
      const res = await scenicUsableList();
      this.scenicList = res.data.map(s => {
        const isBind = this.otaShopList.some(v => v.scenicId == s.id);
        return {
          ...s,
          disabled: isBind
        };
      });
    },
    handleClosed() {
      this.form = createForm();
      this.$refs["form"].clearValidate();
    },
    cancel() {
      this.close();
    },
    close() {
      this.visible = false;
    },
    async open() {
      await this.initOtaShopList();
      this.initScenicList();
      this.visible = true;
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const otaShop = this.otaShopList.find(v => v.id == this.form.id);
          const scenic = this.scenicList.find(v => v.id == this.form.scenicId);
          bindScenic({
            ...otaShop,
            ...this.form,
            scenicName: scenic.scenicName
          }).then(res => {
            this.$modal.msgSuccess(res.msg);
            this.close();
            this.$emit("success");
          });
        }
      });
    }
  }
};