/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/Users/Administrator/Desktop/yunpu/ZhiLuo.Ticketing.Vue/src/components/table/index.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"template",staticClass:"print-box",class:{
    'print-box--hidden': _vm.hidden,
  }},[_c('div',{staticClass:"print-box__inner"},[(_vm.config.ifScenic == 1)?_c('div',{staticClass:"print-box__title"},[_vm._v(" "+_vm._s(_vm.data.scenicName)+" ")]):_vm._e(),(_vm.config.ifTicketType == 1)?_c('div',{staticClass:"print-box__item print print-box__item--center"},[_vm._v(" 收银小票-门票 ")]):_vm._e(),_c('div',{staticClass:"print-box__divider"}),(_vm.config.ifOrderNo == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("订单号：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.orderNo))])]):_vm._e(),(_vm.config.ifSaleWindow == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("售票窗口：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.ticketOfficeName)+_vm._s(_vm.data.ticketWindowName)+" ")])]):_vm._e(),(_vm.config.ifSaleTime == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("售票时间：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.createTime)+" ")])]):_vm._e(),(_vm.config.ifOperator == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("操作员：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.createBy)+" ")])]):_vm._e(),(
        _vm.config.ifOrderNo == 1 ||
        _vm.config.ifSaleWindow == 1 ||
        _vm.config.ifSaleTime == 1 ||
        _vm.config.ifOperator == 1
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),_c(__unplugin_components_0,{staticClass:"print-box__table"},[_c('thead',[_c('tr',[(_vm.config.ifTicketName == 1)?_c('th',{staticStyle:{"width":"44%"},attrs:{"align":"left"}},[_vm._v(" 门票名称 ")]):_vm._e(),(_vm.config.ifUnitPrice == 1)?_c('th',{staticStyle:{"width":"34%"},attrs:{"align":"left"}},[_vm._v(" 单价 ")]):_vm._e(),(_vm.config.ifNum == 1)?_c('th',{staticStyle:{"width":"22%"},attrs:{"align":"right"}},[_vm._v(" 数量 ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.data.ticketingOrderInfoList),function(item,index){return _c('tr',{key:index},[(_vm.config.ifTicketName == 1)?_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.ticketName)+" ")]):_vm._e(),(_vm.config.ifUnitPrice == 1)?_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.ticketDiscountPrice)+" ")]):_vm._e(),(_vm.config.ifNum == 1)?_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.ticketNum * item.personNum)+" ")]):_vm._e()])}),0)]),(
        _vm.config.ifTicketName == 1 ||
        _vm.config.ifUnitPrice == 1 ||
        _vm.config.ifNum == 1
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifAmountReceivable == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("应收金额：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.orderAmount))])]):_vm._e(),(_vm.config.ifActualAmount == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("实收金额：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.actualPayment)+" ")])]):_vm._e(),(_vm.config.ifSettlementMethod == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("结算方式：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.payWay))])]):_vm._e(),(_vm.config.ifChange == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("找零：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.orderChangeMoney))])]):_vm._e(),(
        _vm.config.ifAmountReceivable == 1 ||
        _vm.config.ifActualAmount == 1 ||
        _vm.config.ifSettlementMethod == 1 ||
        _vm.config.ifChange == 1
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifEndNote1 == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" "+_vm._s(_vm.config.endNote1)+" ")]):_vm._e(),(_vm.config.ifEndNote2 == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" "+_vm._s(_vm.config.endNote2)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }