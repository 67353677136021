import request from "@/utils/request";

// 查询所有分类
export function getAllCate(query) {
  return request({
    url: "/goods/category/all",
    method: "get",
    params: query,
  });
}

// 查询类别分页
export function getCatePage(query) {
  return request({
    url: "/goods/category/list",
    method: "get",
    params: query,
  });
}
// 新增类别
export function addCate(query) {
  return request({
    url: "/goods/category",
    method: "post",
    data: query,
  });
}
// 修改类别
export function updateCate(query) {
  return request({
    url: "/goods/category",
    method: "put",
    data: query,
  });
}
// 删除类别
export function delCate(id) {
  return request({
    url: "/goods/category/"+ id,
    method: "DELETE",
  });
}


