//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAllCate } from "@/api/product/cate";
export default {
  props: {
    placeholderStr: {
      type: String,
      default: '请输入商品名称'
    },
    operaType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryParams: {},
      activedCateParentObj: {
        id: [1],
        type: 'service',
        name: '服务商品'
      },
      cateList: [],
      timer: null,
      // 定时器（方便清除）,
      activedCate: ''
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleQuery() {
      this.$emit('e-filter', this.queryParams);
    },
    // 获取类别
    getCate(passType) {
      let type;
      if (passType && this.operaType === 'cashier') {
        type = passType;
      } else {
        type = this.operaType === 'cashier' ? 0 : 2;
      }
      getAllCate({
        type: type
      }).then(res => {
        const arr = [{
          name: '全部',
          id: ''
        }];
        this.cateList = [...arr, ...res.data];
        if (this.operaType === 'cashier') {
          this.filterProd('');
        }
      });
    },
    // 定位到分类
    positionCate(id) {
      if (this.cateList.findIndex(v => v.id === id) > -1) {
        this.activedCate = id;
      } else {
        this.activedCate = '';
      }
    },
    toLeft() {
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft;
      let num = 0;
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(() => {
        //   !left:已经滚动到最左侧
        //   一次性滚动距离（可调节）
        if (!left || num >= 300) {
          // 停止滚动
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapperCon.scrollLeft = left -= 30;
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30;
      }, 20);
      // 20：速度（可调节）
    },
    toRight() {
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft;
      // 保存元素的整体宽度
      let scrollWidth = this.$refs.wrapperCon.scrollWidth;
      // 保存元素的可见宽度
      let clientWidth = this.$refs.wrapperCon.clientWidth;
      let num = 0;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        // 已经滚动距离+可见宽度
        // left+clientWidth>=scrollWidth：滚动到最右侧
        // num>=300一次性滚动距离
        if (left + clientWidth >= scrollWidth || num >= 300) {
          clearInterval(this.timer);
          return;
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapperCon.scrollLeft = left += 30;
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30;
      }, 20);
      // 20：速度（可调节）
    },
    filterProd(id) {
      this.activedCate = id;
      this.queryParams.categoryId = id;
      this.$emit('e-cate', this.queryParams);
    }
  },
  created() {
    if (this.operaType === 'cashier') {
      this.getCate(0);
    } else {
      this.getCate();
    }
  },
  mounted() {}
};