import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { openOfficeSelectDialog } from "@/components/OfficeSelectDialog/main";
import { getSaleWindowData } from "@/api/ticket/home";
import { moneyFormatter } from "@/utils/index";
import { getDefaultSettings } from "@/common/printer-settings";
import { PRINTER_SETTINGS } from "@/constant/localStorage";

/**
 * 管理端首页
 */
export default {
  name: "IndexManagement",
  data() {
    return {
      statData: {
        cashReceiptsToDay: 0,
        saleAmountToDay: 0,
        refundNumToDay: 0,
        netReceiptsToday: 0,
        collectionStatisticsToDay: 0,
        rechargeTimes: 0,
        saleNumToDay: 0,
        refundAmountToDay: 0,
        otherCollectionToDay: 0,
        scanCollectionToDay: 0,
        updateTime: ""
      }
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    ...mapState({
      roleName: s => s.user.roleName,
      userName: s => s.user.userName,
      avatar: s => s.user.avatar,
      phoneNumber: s => s.user.phoneNumber,
      loginTime: s => s.user.loginTime
    })
  },
  async mounted() {
    if (this.office == null) {
      await openOfficeSelectDialog(this);
    }
    this.initStatisticsData();
    await this.checkPrinterSettings();
  },
  methods: {
    moneyFormatter,
    async checkPrinterSettings() {
      const settingsJson = localStorage.getItem(PRINTER_SETTINGS);
      if (!settingsJson) {
        localStorage.setItem(PRINTER_SETTINGS, JSON.stringify(getDefaultSettings()));
        await this.$confirm("未设置打印设置, 是否去设置？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });
        this.$router.push({
          name: "printSettings"
        });
      }
    },
    async initStatisticsData() {
      const res = await getSaleWindowData();
      Object.assign(this.statData, res.data);
    }
  }
};