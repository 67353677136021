//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    headData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    listData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  }
};