import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddBindShopDialog from "../components/AddBindShopDialog.vue";
import { getOtaList, getBindShopList, unbindScenic, revocation } from "@/api/ticket/ota";

// 账户授权详情
export default {
  name: "AccountAuthDetails",
  components: {
    AddBindShopDialog
  },
  data() {
    return {
      accountList: [],
      bindShopList: [],
      otaInfo: {
        bid: null,
        time: null
      }
    };
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    async initBindShopList() {
      const res = await getBindShopList({
        otaType: 1
      });
      this.bindShopList = res.data;
    },
    // 刷新美团ota商户信息
    async initData() {
      const res = await getOtaList();
      const data = res.data;
      this.otaInfo = data.find(v => v.empowerType == "1");
      this.accountList = [this.otaInfo];
      this.initBindShopList();
    },
    // 添加绑定店铺
    addBindShop() {
      this.$refs["addBindShopDialog"].open();
    },
    // 解除授权
    deauthorize(item) {
      this.$confirm("确定解除授权？", "解除授权", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await revocation(item.id);
        if (res.code == 200) {
          this.$modal.msgSuccess("解除授权成功");
          this.$router.push({
            name: "Ota"
          });
          this.$tab.closePage({
            path: "/ticket/ota_meituan/AccountAuthDetails"
          });
          this.$tab.closePage({
            path: "/ticket/ota_meituan/TicketAssociationSettings"
          });
          this.$tab.closePage({
            path: "/ticket/ota_meituan/TakeTicketRecord"
          });
          this.$tab.closePage({
            path: "/ticket/ota_meituan/CheckTicketRecord"
          });
        }
      });
    },
    // 解除绑定
    unbindShop(item) {
      this.$confirm("确定解除绑定？", "解除绑定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await unbindScenic(item.id);
        if (res.code == 200) {
          this.$modal.msgSuccess("解除解除绑定");
          this.initData();
        }
      });
    }
  }
};