import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CateTab from '@/views/ticket-office/billingLease/rental/cateTab.vue';
import ProdInfo from '@/views/ticket-office/billingLease/rental/prodInfo.vue';
import CashRegister from '@/components/cashRegister/index.vue';
import MemberDig from '@/components/memberDialog/index.vue';
import { rentalList } from "@/api/ticket/rental";
import { PRINTER_SETTINGS } from "@/constant/localStorage";
import { getTemplateConfig } from "@/api/ticket/template";
import { accurateQueryMember, getProdPage } from "@/api/product/product";
import { listMember } from "@/api/ticket/member";
import { validatePhoneNumber } from "@/utils/validate";
export default {
  name: 'rental',
  components: {
    CateTab,
    ProdInfo,
    CashRegister,
    MemberDig
  },
  data() {
    return {
      loading: false,
      tableData: [],
      tableParams: {
        pageNum: 1,
        status: 0,
        pageSize: 28
      },
      prodList: [],
      total: 0,
      queryParams: {
        mobile: ''
      },
      filterProd: {
        name: '',
        no: ''
      },
      searchParams: {
        memberCardNumber: '',
        phone: ''
      },
      typeList: [{
        label: '普通',
        value: 0
      }, {
        label: '服务',
        value: 1
      }],
      activeType: 0,
      tableHeight: 0,
      handleQueryType: ''
    };
  },
  computed: {
    countRent() {
      let sum = this.tableData.reduce((quantity, cur) => {
        return quantity + +cur.quantity;
      }, 0);
      return sum;
    },
    countDeposit() {
      let sum = this.tableData.reduce((quantity, cur) => {
        return quantity + cur.quantity * cur.myPrice;
      }, 0);
      return sum.toFixed(2);
    }
  },
  created() {
    // this.getRentalList();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableArea.offsetHeight;
    });
  },
  methods: {
    // 查询商品
    handleQuery() {
      this.handleQueryType = 'search';
      this.getRentalList();
    },
    limitMobile() {
      if (this.queryParams.mobile === '') {
        return;
      }
      if (!validatePhoneNumber(this.queryParams.mobile)) {
        this.$modal.msgWarning('请输入正确的手机号格式');
        this.$set(this.queryParams, 'mobile', '');
      }
    },
    limitPrice(val, row) {
      let value = '' + val;
      value = value.match(/\d+(\.\d{0,2})?/) ? value.match(/\d+(\.\d{0,2})?/)[0] : '';
      value = value.indexOf('.') > -1 ? value.slice(0, value.indexOf('.') + 3) : value.slice(0, 5);
      row.myPrice = value;
    },
    // 改变商品类型
    changeType(row) {
      this.activeType = row.value;
      this.$refs.memberInput.getCate(this.activeType);
    },
    //散客按钮点击事件
    changeFIT() {
      this.queryParams.memberId = '';
      this.queryParams.mobile = '';
      this.searchParams.memberCardNumber = '';
      this.searchParams.phone = '';
      this.$set(this.queryParams, 'memberName', '散客');
      this.$set(this.queryParams, 'balance', 0.00);
    },
    // 精准查询会员
    selectMember() {
      if (this.searchParams.memberCardNumber === '') {
        this.changeMember();
        return;
      }
      this.searchParams.phone = this.searchParams.memberCardNumber;
      accurateQueryMember(this.searchParams).then(res => {
        if (res.code === 200) {
          if (res.rows.length === 0) {
            this.$message.warning('没有查询到该信息的会员');
          } else {
            this.changeRowMenber(res.rows[0]);
          }
        }
      });
    },
    //打开选择会员弹框
    async changeMember() {
      this.$refs['memberDig'].open();
      this.searchParams.memberCardNumber = '';
    },
    // 选择的会员数据
    changeRowMenber({
      id,
      memberName,
      memberCardNumber,
      phone,
      remainingSum,
      giveMoney
    }) {
      this.$set(this.queryParams, 'memberId', id);
      this.$set(this.queryParams, 'memberName', memberName);
      this.$set(this.queryParams, 'memberCardNumber', memberCardNumber);
      this.$set(this.queryParams, 'mobile', phone);
      this.$set(this.queryParams, 'balance', this.changeToFixed(remainingSum + giveMoney));
    },
    //金额格式化
    changeToFixed(val) {
      return Number(val).toFixed(2);
    },
    //重置
    resetQuery() {
      this.queryParams.memberId = '';
      this.queryParams.memberName = '';
      this.queryParams.mobile = '';
      this.queryParams.balance = '';
      this.queryParams.memberCardNumber = '';
      this.searchParams.phone = '';
    },
    // 支付完成 更新页面
    updateQuery() {
      this.queryParams = {};
      this.tableParams.pageNum = 1;
      this.filterProd.name = '';
      this.tableData = [];
      this.searchParams.memberCardNumber = '';
      this.$refs.memberInput.positionCate('');
      this.getRentalList();
    },
    // 清空列表
    clearList() {
      this.tableData.forEach(item => {
        const info = this.prodList.find(v => item.type === 0 && v.id === item.id);
        if (info) {
          info.goodsSku[0].stock = info.total;
        }
      });
      this.tableData = [];
    },
    /** 删除按钮操作 */
    handleDelete(row, index) {
      this.$modal.confirm(`是否确认删除:【${row.name}】吗？`).then(() => {
        this.tableData.splice(index, 1);
        if (row.type === 0) {
          let y = this.prodList.findIndex(v => v.id === row.id);
          let prod = this.prodList[y];
          prod.goodsSku[0].stock = prod.total;
        }
        this.$modal.msgSuccess("删除成功");
      }).catch(e => {
        console.error(e);
      });
    },
    // 订单金额(原价 * 数量)
    getOrderAmount() {
      let sum = this.tableData.reduce((cur, next) => {
        return cur + next.goodsSku[0].price * next.quantity;
      }, 0);
      return sum.toFixed();
    },
    //   去结算
    changeCash() {
      if (this.tableData.length <= 0) {
        this.$modal.msgWarning('请选择需要购买的物品');
        return;
      }
      let detailList = [];
      this.tableData.forEach(item => {
        const obj = {
          productId: item.goodsSku[0].id,
          type: item.type,
          price: (item.myPrice * 100).toFixed(),
          quantity: item.quantity,
          amount: (item.myPrice * 100 * item.quantity).toFixed()
        };
        detailList.push(obj);
      });
      const params = {
        orderAmount: this.getOrderAmount(),
        actualAmount: (this.countDeposit * 100).toFixed(),
        detailList: detailList,
        notes: this.queryParams.remark
      };
      if (this.queryParams.memberId) {
        params.memberId = this.queryParams.memberId;
      }
      params.balance = this.queryParams.balance ? this.queryParams.balance : 0;
      params.memberName = this.queryParams.memberName;
      params.mobile = this.queryParams.mobile;
      this.$refs['cashRegister'].open(params);
    },
    async diaSuccess({
      val,
      data
    }) {
      if (val == 1) {
        const settingsJson = localStorage.getItem(PRINTER_SETTINGS);
        const printSettingList = JSON.parse(settingsJson);
        const cashierSettings = printSettingList.find(v => v.printType == "收银小票");
        // 打印收银小票
        if (cashierSettings && cashierSettings.ifOpen == "1") {
          const configRes2 = await getTemplateConfig(cashierSettings.printTemplate);
          for (let i = 0; i < parseInt(cashierSettings.printNum); i++) {
            await this.$refs["ticketCashierPrintTemplate"].print(data.mxsyxp, cashierSettings, configRes2.data);
          }
        }
      } else {
        this.updateQuery();
      }
    },
    // 计数器改变
    changeNum(val, row) {
      let y = this.prodList.findIndex(v => v.id === row.id);
      let prod = {};
      if (y > -1) {
        prod = this.prodList[y];
      }
      if (val === 'minus') {
        if (row.quantity <= 1) return;
        row.quantity--;
        if (row.type === 0 && y > -1) {
          prod.goodsSku[0].stock += 1;
        }
      } else {
        if (row.type === 0 && row.quantity >= row.total) return;
        row.quantity++;
        if (row.type === 0 && y > -1) {
          prod.goodsSku[0].stock -= 1;
        }
      }
    },
    //   计步器触发时事件
    changeQuantity(val, row) {
      let y = this.prodList.findIndex(v => v.id === row.id);
      let prod = {};
      if (y > -1) {
        prod = this.prodList[y];
      }
      if (this.isPositiveInteger(val)) {
        if (row.type === 0 && val > row.total) {
          row.quantity = row.total;
        }
        if (val <= 1 && val !== '') {
          row.quantity = 1;
        }
        if (row.type === 0 && y > -1) {
          prod.goodsSku[0].stock = prod.total - row.quantity;
        }
      } else {
        if (row.quantity !== '') {
          row.quantity = 1;
        }
        if (row.type === 0 && y > -1) {
          prod.goodsSku[0].stock = prod.total - row.quantity;
        }
      }
    },
    addProd({
      row,
      index
    }) {
      if (row.type === 0) {
        //普通商品
        let y = this.tableData.findIndex(v => v.id === row.id);
        if (y > -1) {
          if (this.stock(index)) {
            let item = this.tableData[y];
            item.quantity++;
          }
          return;
        } else {
          if (row.goodsSku[0].stock === 0) {
            this.$modal.msgWarning('库存不足');
            return;
          }
          this.tableData.push({
            ...row
          });
          this.stock(index);
        }
      } else {
        //服务商品 没有库存 且独自成一条
        this.tableData.push({
          ...row
        });
      }
    },
    stock(index) {
      let prod = this.prodList[index];
      if (prod.goodsSku[0].stock > 0) {
        prod.goodsSku[0].stock--;
      } else {
        this.$modal.msgWarning('库存不足');
        return false;
      }
      return true;
    },
    isPositiveInteger(str) {
      return /^[1-9]\d*$/.test(str);
    },
    /** 查询商品列表 */
    getRentalList(val = {}) {
      this.loading = true;
      this.tableParams.name = val.name;
      this.tableParams.categoryId = val.categoryId;
      this.tableParams.type = this.activeType;
      this.tableParams.name = this.filterProd.name;
      this.tableParams.no = this.filterProd.name;
      getProdPage(this.tableParams).then(res => {
        this.prodList = res.rows.map(item => {
          return {
            ...item,
            total: item.goodsSku[0].stock
          };
        });
        if (this.handleQueryType === 'search') {
          if (this.filterProd.name && this.prodList.length > 0) {
            this.$refs.memberInput.positionCate(this.prodList[0].categoryId);
          } else {
            this.$refs.memberInput.positionCate('');
          }
        }
        this.updateStock();
        this.total = res.total;
        this.handleQueryType = '';
      }).finally(() => {
        this.loading = false;
      });
    },
    // 更新库存
    updateStock() {
      this.tableData.forEach(item => {
        const info = this.prodList.find(v => item.type === 0 && v.id === item.id);
        if (info) {
          info.goodsSku[0].stock = info.total - item.quantity;
        }
      });
    }
  }
};