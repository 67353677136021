/**
 * 获取打印机名称列表
 * @returns {string[]}
 */
export function getPrinterNameList() {
  const list = [];
  const count = LODOP.GET_PRINTER_COUNT(); //获取打印机个数
  for (let i = 0; i < count; i++) {
    //根据设备序号获取设备名
    const printerName = LODOP.GET_PRINTER_NAME(i);
    list.push(printerName);
  }

  return list;
}

/**
 * 下载C-Lodop
 */
export function downloadCLodop() {
  window.open("/CLodop_Setup_for_Win32NT.exe");
}
