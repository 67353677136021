/* unplugin-vue-components disabled */import __unplugin_components_8 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_7 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_6 from 'element-ui/lib/option';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/option.css';
import __unplugin_components_5 from 'element-ui/lib/select';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/select.css';
import __unplugin_components_4 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_3 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_2 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_1 from 'element-ui/lib/form';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form.css';
import __unplugin_components_0 from 'D:/Users/Administrator/Desktop/yunpu/ZhiLuo.Ticketing.Vue/src/components/AppContentLayout/index.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,[_c('div',{staticClass:"flex justify-center app-box"},[_c(__unplugin_components_1,{ref:"form",staticClass:"mt-8",staticStyle:{"width":"400px"},attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"8em"}},[_c(__unplugin_components_2,{attrs:{"label":"模板名称","prop":"templateName"}},[_c(__unplugin_components_3,{attrs:{"placeholder":"请输入","maxlength":10},model:{value:(_vm.form.templateName),callback:function ($$v) {_vm.$set(_vm.form, "templateName", $$v)},expression:"form.templateName"}})],1),_c(__unplugin_components_4,{attrs:{"label":"票样模板类型","prop":"tempType"}},[_c(__unplugin_components_5,{staticClass:"w-full",attrs:{"placeholder":"请选择","disabled":""},model:{value:(_vm.form.tempType),callback:function ($$v) {_vm.$set(_vm.form, "tempType", $$v)},expression:"form.tempType"}},_vm._l((_vm.printTypeList),function(item,index){return _c(__unplugin_components_6,{key:index,attrs:{"label":item.value,"value":item.text}})}),1)],1),_c('div',{staticClass:"flex justify-center"},[_c(__unplugin_components_7,{on:{"click":_vm.goBack}},[_vm._v("返回")]),_c(__unplugin_components_8,{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }