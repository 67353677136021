//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from "@/store";
import { VueCropper } from "vue-cropper";
import { uploadFile } from "@/api/common.js";
import { uploadAvatar } from "@/api/system/user";
import { debounce } from "@/utils";
export default {
  components: {
    VueCropper
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 是否显示cropper
      visible: false,
      // 弹出层标题
      title: "修改头像",
      options: {
        img: store.getters.avatar,
        //裁剪图片的地址
        autoCrop: true,
        // 是否默认生成截图框
        autoCropWidth: 200,
        // 默认生成截图框宽度
        autoCropHeight: 200,
        // 默认生成截图框高度
        fixedBox: true,
        // 固定截图框大小 不允许改变
        outputType: "png" // 默认生成截图为PNG格式
      },
      previews: {},
      resizeHandler: null
    };
  },
  methods: {
    // 编辑头像
    editCropper() {
      this.open = true;
    },
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true;
      if (!this.resizeHandler) {
        this.resizeHandler = debounce(() => {
          this.refresh();
        }, 100);
      }
      window.addEventListener("resize", this.resizeHandler);
    },
    // 刷新组件
    refresh() {
      this.$refs.cropper.refresh();
    },
    // 覆盖默认的上传行为
    requestUpload() {},
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$modal.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.img = reader.result;
        };
      }
    },
    // 上传图片
    uploadImg() {
      this.$refs.cropper.getCropBlob(async data => {
        const formData = new FormData();
        formData.append("ImageFile", data);
        const response = await uploadFile(formData);
        this.open = false;
        console.log(response);
        this.options.img = response.data.url;

        // 上传头像后调用修改头像接口
        await uploadAvatar({
          avatar: response.data.url
        });
        store.commit("SET_AVATAR", response.data.url);
        this.$modal.msgSuccess("修改成功");
        this.visible = false;
        this.$emit("success");
      });
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    // 关闭窗口
    closeDialog() {
      this.visible = false;
      window.removeEventListener("resize", this.resizeHandler);
    }
  }
};