import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listPrincipal, getPrincipal, delPrincipal, addPrincipal, updatePrincipal } from "@/api/ticket/principal";
import { allList } from "@/api/ticket/team";
export default {
  name: "Principal",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 团队联系人表格数据
      principalList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      teamId: "",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        teamPrincipalName: null,
        teamPrincipalNum: null,
        state: null,
        teamId: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      teamList: []
    };
  },
  created() {
    this.queryParams.teamId = this.$route.params && this.$route.params.teamId;
    this.teamId = this.queryParams.teamId;
    console.log(this.teamId);
    //const teamGroupId = this.$route.params && this.$route.params.teamGroupId
    //this.getUsableList(teamGroupId);
    this.getList();
  },
  methods: {
    /** 查询团队联系人列表 */
    getList() {
      this.loading = true;
      listPrincipal(this.queryParams).then(response => {
        this.principalList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        teamPrincipalName: null,
        teamPrincipalNum: null,
        state: 0,
        teamId: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.form.teamId = this.teamId;
      this.title = "添加团队联系人";
    },
    //查询可用团队分组
    getUsableList(teamGroupId) {
      this.query = {
        state: 0,
        teamGroupId: teamGroupId
      };
      allList(this.query).then(response => {
        this.teamList = response.data;
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getPrincipal(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改团队联系人";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updatePrincipal(this.form).then(() => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addPrincipal(this.form).then(() => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除团队联系人编号为"' + ids + '"的数据项？').then(function () {
        return delPrincipal(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download("system/principal/export", {
        ...this.queryParams
      }, `principal_${new Date().getTime()}.xlsx`);
    }
  }
};