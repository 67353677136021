/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"template",staticClass:"print-box",class:{
    'print-box--hidden': _vm.hidden,
  }},[_c('div',{staticClass:"print-box__inner"},[_c('div',{staticClass:"print-box__title"},[_vm._v(_vm._s(_vm.data.scenicName))]),(_vm.config.ifTicketType == 1)?_c('div',{staticClass:"print-box__item print print-box__item--center"},[_vm._v(" 门票"+_vm._s(_vm.operationType)+"小票 ")]):_vm._e(),_c('div',{staticClass:"print-box__divider"}),(_vm.config.ifOrderNo == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("订单号：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.orderNo))])]):_vm._e(),(_vm.config.ifSaleWindow == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("售票窗口：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.pointName)+_vm._s(_vm.data.windowsName)+" ")])]):_vm._e(),(_vm.config.ifSaleTime == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("操作时间：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.updateTime))])]):_vm._e(),(_vm.config.ifOperator == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("操作员：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.createBy))])]):_vm._e(),(
        _vm.config.ifOrderNo == 1 ||
        _vm.config.ifSaleWindow ||
        _vm.config.ifSaleTime ||
        _vm.config.ifOperator
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ticketNo == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("票号：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.ticketNo))])]):_vm._e(),(_vm.config.ifTicketName == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("票类名称：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.ticketName))])]):_vm._e(),(_vm.config.ifSTime == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("入场时间：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.sTime))])]):_vm._e(),(_vm.config.ifETime == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("结束时间：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.eTime))])]):_vm._e(),(
        _vm.config.ticketNo == 1 ||
        _vm.config.ifTicketName == 1 ||
        _vm.config.ifSTime == 1 ||
        _vm.config.ifETime == 1
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifActualAmount == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v(_vm._s(_vm.operationType)+"金额：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.money))])]):_vm._e(),(_vm.config.ifSettlementMethod == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("结算方式：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.payName))])]):_vm._e(),(_vm.config.ifActualAmount == 1 || _vm.config.ifSettlementMethod == 1)?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifEndNote1 == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" "+_vm._s(_vm.config.endNote1)+" ")]):_vm._e(),(_vm.config.ifEndNote2 == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" "+_vm._s(_vm.config.endNote2)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }