//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import { dateRangePickerShortcuts } from "@/utils/el-utils";
import { getVerificationRecords, revokeVerificationRecord } from "@/api/ticket/ota";
function createDateRange() {
  return [dayjs().startOf("d").format("YYYY-MM-DD"), dayjs().endOf("d").format("YYYY-MM-DD")];
}

// 取票记录
export default {
  name: "CheckTicketRecord",
  components: {},
  data() {
    return {
      verificationRecords: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        thirdTicketNo: null,
        otaType: 1
      },
      loading: false,
      total: 0,
      dateRange: createDateRange(),
      pickerOptions: {
        shortcuts: dateRangePickerShortcuts
      }
    };
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    getList() {
      this.loading = true;
      getVerificationRecords(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
        this.verificationRecords = response.rows;
        this.total = response.total;
      }).finally(() => {
        this.loading = false;
      });
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.dateRange = createDateRange();
      this.handleQuery();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 撤销核销记录
    async revocation(item) {
      await this.$confirm("确定撤销核销记录？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      });
      await revokeVerificationRecord(item);
      this.$message({
        type: "success",
        message: "撤销核销记录成功!"
      });
      this.getList();
    },
    // 导出
    handleExport() {
      this.download("/ticket/verificationRecords/export", this.addDateRange(this.queryParams, this.dateRange), `verificationRecords_${new Date().getTime()}.xlsx`);
    }
  }
};