import request from "@/utils/request";

// 查询商品列表
export function getProdPage(query) {
  return request({
    url: "/goods/spu/list",
    method: "get",
    params: query,
  });
}
// 新增商品
export function addProd(data) {
  return request({
    url: "/goods/spu",
    method: "post",
    data: data,
  });
}
// 删除商品
export function delProd(id) {
  return request({
    url: "/goods/spu/" + id,
    method: "delete",
  });
}
// 修改商品
export function updateProd(data) {
  return request({
    url: "/goods/spu",
    method: "put",
    data: data,
  });
}

// 编号生成
export function generateNo(query) {
  return request({
    url: "/goods/spu/generateNo",
    method: "get",
    params: query,
  });
}
// 查询所有单位
export function getAllUnit(query) {
  return request({
    url: "/goods/unit/list",
    method: "get",
    params: query,
  });
}
// 查询类别分页
export function getUnitPage(query) {
  return request({
    url: "/goods/unit/list",
    method: "get",
    params: query,
  });
}
// 新增类别
export function addUnit(query) {
  return request({
    url: "/goods/unit",
    method: "post",
    data: query,
  });
}
// 修改类别
export function updateUnit(query) {
  return request({
    url: "/goods/unit",
    method: "put",
    data: query,
  });
}
// 删除类别
export function delUnit(id) {
  return request({
    url: "/goods/unit/"+ id,
    method: "DELETE",
  });
}

// 商品收款
export function cashierOrder(query) {
  return request({
    url: "/goods/cashier/orders",
    method: "post",
    data: query,
  });
}
// 商品收款订单状态轮询
export function checkOrder(query) {
  return request({
    url: "/goods/cashier/orders/check",
    method: "get",
    params: query,
  });
}
// 精准查询会员
export function accurateQueryMember(query) {
  return request({
    url: "/ticket/member/accurateQuery",
    method: "get",
    params: query,
  });
}

