//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: '',
  components: {},
  props: {
    tips: {
      type: String,
      default: ''
    }
  },
  computed: {},
  watch: {},
  data() {
    return {};
  },
  methods: {
    pass(num) {
      this.$emit('e-key', num);
    },
    passQuick(num) {
      this.$emit('e-quick-key', num);
    },
    passClean() {
      this.$emit('e-clean-key');
    },
    passEsc() {
      this.$emit('e-esc-key');
    },
    passConfirm() {
      this.$emit('e-confirm-key');
    },
    handleKeyDown(e) {
      if (e.key === 'Escape') {
        this.passEsc();
      } else if (e.key === 'Enter') {
        this.passConfirm();
      }
    }
  },
  created() {},
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
};