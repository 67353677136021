import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getUser } from "@/utils/auth";
export default {
  components: {},
  props: {},
  data() {
    return {
      activeMenu: '商品收款',
      cashierRouterList: [{
        name: '商品收款',
        path: '/index',
        icon: 'icon-shishoujine1'
      }, {
        name: '收款订单',
        path: '/order',
        icon: 'icon-xiaoshou'
      }],
      userObj: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 退出
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({
            name: "Login"
          });
        });
      });
    }
  },
  created() {
    this.userObj = JSON.parse(getUser());
  },
  mounted() {},
  destroyed() {}
};