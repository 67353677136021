import request from "@/utils/request";

// 查询团队列表
export function listTeam(query) {
  return request({
    url: "/ticket/team/list",
    method: "get",
    params: query,
  });
}

// 查询团队详细
export function getTeam(id) {
  return request({
    url: "/ticket/team/" + id,
    method: "get",
  });
}

// 新增团队
export function addTeam(data) {
  return request({
    url: "/ticket/team",
    method: "post",
    data: data,
  });
}

// 修改团队
export function updateTeam(data) {
  return request({
    url: "/ticket/team",
    method: "put",
    data: data,
  });
}

// 删除团队
export function delTeam(id) {
  return request({
    url: "/ticket/team/" + id,
    method: "delete",
  });
}
// 新增团队
export function allList(data) {
  return request({
    url: "/ticket/team/allList",
    method: "post",
    data: data,
  });
}

// 查询协议团队
export function stateList(data) {
  return request({
    url: "/ticket/team/stateList",
    method: "post",
    data: data,
  });
}
