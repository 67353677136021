//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";
import "bootstrap";
// import { elementTypeProvider } from "./custom-element-type-provider.js";
// import { printTemplate } from "./custom-print-template.js";
import { printData } from "./print-data.js";
import "@claviska/jquery-minicolors";
import { hiprint } from "@/libs/hiprint/hiprint.bundle.js";
import "@/libs/hiprint/plugins/jquery.hiwprint.js";
import { getTemplateConfig, updateTemplateConfig } from "@/api/ticket/template";
const commonTools = [{
  tid: "customModule.text",
  class: "glyphicon glyphicon-text-width",
  text: "文本"
}, {
  tid: "customModule.image",
  class: "glyphicon glyphicon-picture",
  text: "图片"
}, {
  tid: "customModule.longText",
  class: "glyphicon glyphicon-subscript",
  text: "长文"
}, {
  tid: "customModule.tableCustom",
  class: "glyphicon glyphicon-th",
  text: "表格"
}
// {
//   tid: "customModule.html",
//   class: "glyphicon glyphicon-header",
//   text: "html",
// },
];
const auxiliaryTools = [{
  tid: "customModule.hline",
  class: "glyphicon glyphicon-resize-horizontal",
  text: "横线"
}, {
  tid: "customModule.vline",
  class: "glyphicon glyphicon-resize-vertical",
  text: "竖线"
}, {
  tid: "customModule.rect",
  class: "glyphicon glyphicon-unchecked",
  text: "矩形"
}, {
  tid: "customModule.oval",
  class: "glyphicon glyphicon-record",
  text: "椭圆"
}];
const fieldTools = [
// {
//   tid: "customModule.name",
//   text: "姓名",
// },
{
  tid: "customModule.scenicName",
  text: "景区名称"
}, {
  tid: "customModule.ticketNoQrCode",
  text: "门票码"
}, {
  tid: "customModule.ticketNo",
  text: "票号"
}, {
  tid: "customModule.orderNo",
  text: "订单号"
}, {
  tid: "customModule.ticketName",
  text: "门票名称"
}, {
  tid: "customModule.personNum",
  text: "人数"
}, {
  tid: "customModule.ticketDiscountPrice",
  text: "单价"
}, {
  tid: "customModule.subtotalAmount",
  text: "金额"
}, {
  tid: "customModule.activationEntDate",
  text: "有效期"
}, {
  tid: "customModule.createTime",
  text: "售票时间"
}, {
  tid: "customModule.ticketOfficeName",
  text: "售票点"
}, {
  tid: "customModule.ticketWindowName",
  text: "售票窗口"
}, {
  tid: "customModule.createBy",
  text: "售票员"
}];

/**
 * 普通门票设计模板
 */
export default {
  name: "GeneralTicketTemplateDesign",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      commonTools,
      auxiliaryTools,
      fieldTools,
      hiprintTemplate: null,
      customWidth: "",
      customHeight: "",
      textareaHtml: "",
      textareaJson: "",
      prevViewHtml: "",
      form: {
        id: null,
        customTemplate: null
      }
    };
  },
  async mounted() {
    await this.initTemplateConfig();
    this.initHiprint();
    setTimeout(() => {
      // this.initTemplateConfig();
    }, 1000);
  },
  methods: {
    initHiprint() {
      console.log(hiprint);

      //初始化打印插件
      // hiprint.init({
      //   host: "http://localhost:17522",
      //   token: "123456",
      //   providers: [new elementTypeProvider()],
      // });

      //设置左侧拖拽事件
      // hiprint.PrintElementTypeManager.build("#customTools", "customModule");
      hiprint.PrintElementTypeManager.buildByHtml($("#defaultTools .ep-draggable-item"));
      hiprint.PrintElementTypeManager.buildByHtml($("#customTools .ep-draggable-item"));

      // 赋值打印模板
      let template = null;
      if (this.form.customTemplate) {
        const customTemplate = JSON.parse(this.form.customTemplate);
        template = customTemplate;
        // this.$refs["generalTicketPrintTemplate"].updatePrintTemplate({
        //   template: customTemplate,
        // });
      }
      this.$refs["generalTicketPrintTemplate"].init({
        template,
        settingContainer: "#PrintElementOptionSetting"
      });

      // this.hiprintTemplate = new hiprint.PrintTemplate({
      //   template,
      //   settingContainer: "#PrintElementOptionSetting",
      //   // paginationContainer: "#hiprint-printPagination",
      // });
      // console.log(this.hiprintTemplate);

      //打印设计
      // this.hiprintTemplate.design("#hiprint-printTemplate", {
      //   axisEnabled: true,
      // });

      // const panel = this.hiprintTemplate.addPrintPanel({}, true);
      // this.hiprintTemplate.printPaginationCreator.buildPagination();
    },
    getHtmlToTextarea() {
      this.textareaHtml = this.$refs["generalTicketPrintTemplate"].getHtml(printData)[0].outerHTML;
    },
    getJsonToTextarea() {
      this.textareaJson = JSON.stringify(this.$refs["generalTicketPrintTemplate"].getJson());
    },
    dialogPrint() {
      this.$refs["generalTicketPrintTemplate"].printByHtml(this.$refs["prevView"]);
    },
    print() {
      this.$refs["generalTicketPrintTemplate"].print(printData);
    },
    print2() {
      this.$refs["generalTicketPrintTemplate"].print2(printData, {
        // printer: "Gprinter GP-1124T",
        title: "hiprint测试打印"
      });
    },
    preview() {
      const prevView = this.$refs["generalTicketPrintTemplate"].getHtml(printData)[0];
      this.$refs["prevView"].appendChild(prevView);
      $("#myModal").modal("show");
    },
    //调整纸张
    setPaper(paperTypeOrWidth, height) {
      this.$refs["generalTicketPrintTemplate"].setPaper(paperTypeOrWidth, height);
    },
    //旋转
    rotatePaper() {
      this.$refs["generalTicketPrintTemplate"].rotatePaper();
    },
    // 清空
    clearTemplate() {
      this.$refs["generalTicketPrintTemplate"].clear();
    },
    goBack() {
      this.$tab.closePage();
    },
    async initTemplateConfig() {
      const res = await getTemplateConfig(this.id);
      Object.assign(this.form, res.data);
    },
    async save() {
      const jsonTid = this.$refs["generalTicketPrintTemplate"].getJsonTid();
      this.form.customTemplate = JSON.stringify(jsonTid);
      const res = await updateTemplateConfig(this.form);
      if (res.code == 200) {
        this.$modal.msgSuccess(res.msg);
        this.$tab.closePage();
      }
    }
  }
};