import axios from "axios";
import Message from "element-ui/lib/message.js";

const request = axios.create({
  // 超时
  timeout: 30 * 1000,
});

// request拦截器
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    const { errno, errmsg } = res;

    if (errno == 200) {
      return res;
    } else {
      Message({ message: errmsg, type: "error" });
      throw new Error(errmsg);
      // return Promise.reject(new Error(errmsg));
    }
  },
  (error) => {
    console.error(error);
    Message({ message: error.message, type: "error" });
    return Promise.reject(error);
  },
);

// 上传文件
export async function uploadFile(data) {
  const res = await request({
    url: process.env.VUE_APP_UPLOAD_URL + "/hardware/uploadFile",
    method: "post",
    data: data,
  });

  return {
    ...res,
    code: res.errmsg,
    msg: res.errmsg ? res.errmsg : "上传成功",
    data: {
      originalFileName: res.originalFileName,
      url: res.url,
    },
  };
}

// 上传人脸
export async function uploadFileFace(data) {
  const res = await request({
    url: process.env.VUE_APP_UPLOAD_URL + "/hardware/uploadFileFace",
    method: "post",
    data: data,
  });

  return res;
}
