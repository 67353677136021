export const printData = {
  name: "黄磊",
  scenicName: "示例景区",
  ticketNo: "F987654322",
  orderNo: "123456789",
  ticketName: "示例门票",
  personNum: 2,
  ticketDiscountPrice: 100,
  subtotalAmount: 200,
  activationEntDate: "2020-01-01 00:00:00",
  createBy: "售票员A",
  ticketOfficeName: "售票点A",
  ticketWindowName: "窗口A",
};
