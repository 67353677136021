/**
 * 支付接口
 */
import request from "@/utils/request";

// 查询支付/结算方式列表
export function listPayment(query) {
  return request({
    url: "/ticket/payment/list",
    method: "get",
    params: query,
  });
}

// 查询支付方式详细
export function getPayment(id) {
  return request({
    url: "/ticket/payment/" + id,
    method: "get",
  });
}

// 新增支付方式
export function addPayment(data) {
  return request({
    url: "/ticket/payment",
    method: "post",
    data: data,
  });
}

// 修改支付方式
export function updatePayment(data) {
  return request({
    url: "/ticket/payment",
    method: "put",
    data: data,
  });
}

// 删除支付方式
export function delPayment(id) {
  return request({
    url: "/ticket/payment/" + id,
    method: "delete",
  });
}

// 查询支付方式列表
export function usableList(data) {
  return request({
    url: "/ticket/payment/usableList",
    method: "post",
    data: data,
  });
}

/**
 * 查询会员支付状态
 */
export function getPayState(data) {
  return request({
    url: "/ticket/member/memberBizPayStatue/" + data,
    method: "get",
  });
}

/**
 * 查询售票支付状态
 */
export function getSalePayState(orderNo) {
  return request({
    url: "/office/saleTicket/checkPayStatue/" + orderNo,
    method: "get",
  });
}

/**
 * 查询退款补缴支付状态
 */
export function getRefundPayState(orderNo) {
  return request({
    url: "/office/saleTicket/ticketNoPayStatue/" + orderNo,
    method: "get",
  });
}
