//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

function createForm() {
  return {
    code: ""
  };
}
export default {
  name: "ScanCodeDialog",
  data() {
    return {
      visible: false,
      form: createForm(),
      rules: {
        code: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    handleClosed() {
      this.form = createForm();
      this.$refs["form"].clearValidate();
    },
    cancel() {
      this.$emit("cancel");
      this.close();
    },
    close() {
      this.$emit("close");
      this.visible = false;
    },
    open() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["scannedPayCode"].focus();
      });
    },
    async confirm() {
      console.log("confirm");
      const res = await this.$refs["form"].validate();
      console.log(res);
      this.$emit("success", this.form.code);
      this.$emit("confirm", this.form.code);
      this.close();
    }
  }
};