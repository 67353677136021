import request from "@/utils/request";

// 入库单列表
export function getPurchaseInPage(query) {
  return request({
    url: "/goods/purchase/in/list",
    method: "get",
    params: query,
  });
}

// 撤单
export function purchaseInReturn(query) {
  return request({
    url: "/goods/purchase/in/returns",
    method: "PUT",
    data: query,
  });
}

// 出库单列表
export function getPurchaseOutPage(query) {
  return request({
    url: "/goods/purchase/out/list",
    method: "get",
    params: query,
  });
}

// 入库统计
export function getPurchaseInItemsPage(query) {
  return request({
    url: "/goods/purchase/in/items/list",
    method: "get",
    params: query,
  });
}

// 创建入库单
export function createPurchaseIn(query) {
  return request({
    url: "/goods/purchase/in",
    method: "post",
    data: query,
  });
}
// 获取用户列表
export function getUserList(query) {
  return request({
    url: "/system/user/list",
    method: "get",
    params: query,
  });
}

// 出库统计
export function getPurchaseOutItemsPage(query) {
  return request({
    url: "/goods/purchase/out/items/list",
    method: "get",
    params: query,
  });
}
