import { hiprint } from "@/libs/hiprint/hiprint.bundle.js";

export function elementTypeProvider() {
  function addElementTypes(context) {
    context.addPrintElementTypes("customModule", [
      new hiprint.PrintElementTypeGroup("常规", [
        { tid: "customModule.text", text: "文本", data: "", type: "text" },
        {
          tid: "customModule.image",
          text: "图片",
          data: "/img/hi.png",
          type: "image",
        },
        {
          tid: "customModule.longText",
          text: "长文",
          data: "",
          type: "longText",
        },
        {
          tid: "customModule.table",
          field: "table",
          text: "表格",
          type: "table",
          groupFields: ["name"],
          groupFooterFormatter: function (group, option) {
            return "这里自定义统计脚信息";
          },
          columns: [
            [
              {
                title: "行号",
                fixed: true,
                rowspan: 2,
                field: "id",
                width: 70,
              },
              { title: "人员信息", colspan: 2 },
              { title: "销售统计", colspan: 2 },
            ],
            [
              { title: "姓名", align: "left", field: "name", width: 100 },
              { title: "性别", field: "gender", width: 100 },
              { title: "销售数量", field: "count", width: 100 },
              { title: "销售金额", field: "amount", width: 100 },
            ],
          ],
        },
        {
          tid: "customModule.tableCustom",
          title: "表格",
          type: "tableCustom",
        },
        // {
        //   tid: "customModule.html",
        //   title: "html",
        //   formatter: function (data, options) {
        //     return $(
        //       '<div style="height:50pt;width:50pt;background:red;border-radius: 50%;"></div>',
        //     );
        //   },
        //   type: "html",
        // },
        {
          tid: "customModule.customText",
          text: "自定义文本",
          customText: "自定义文本",
          custom: true,
          type: "text",
        },
      ]),
      new hiprint.PrintElementTypeGroup("辅助", [
        {
          tid: "customModule.hline",
          text: "横线",
          type: "hline",
        },
        {
          tid: "customModule.vline",
          text: "竖线",
          type: "vline",
        },
        {
          tid: "customModule.rect",
          text: "矩形",
          type: "rect",
        },
        {
          tid: "customModule.oval",
          text: "椭圆",
          type: "oval",
        },
      ]),
      new hiprint.PrintElementTypeGroup("字段", [
        {
          tid: "customModule.name",
          text: "姓名",
          custom: true,
          title: "姓名title",
          field: "name",
          data: "张三data1",
          type: "text",
          name: "name",
          options: {
            // title: "姓名option",
            name: "name",
            data: "张三data2",
            // field: "name",
            // testData: "张三testData",
          },
        },
        {
          tid: "customModule.scenicName",
          type: "text",
          name: "scenicName",
          field: "scenicName",
          text: "景区名称",
          title: "景区名称",
          options: {
            testData: "景区名称",
            hideTitle: true,
            fontSize: 18,
            fontWeight: "bold",
            height: 21,
            lineHeight: 21,
            textAlign: "center",
          },
        },
        {
          tid: "customModule.ticketNoQrCode",
          type: "text",
          name: "ticketNo",
          field: "ticketNo",
          text: "门票码",
          title: "门票码",
          options: {
            testData: "F123456789",
            textType: "qrcode",
            width: 100,
            height: 100,
          },
        },
        {
          tid: "customModule.ticketNo",
          type: "text",
          name: "ticketNo",
          field: "ticketNo",
          text: "票号",
          title: "票号",
          options: {
            testData: "F123456789",
          },
        },
        {
          tid: "customModule.orderNo",
          type: "text",
          name: "orderNo",
          field: "orderNo",
          text: "订单号",
          title: "订单号",
          options: {
            testData: "123456789",
          },
        },
        {
          tid: "customModule.ticketName",
          type: "text",
          name: "ticketName",
          field: "ticketName",
          text: "门票名称",
          title: "门票名称",
          options: {
            testData: "普通门票A",
          },
        },
        {
          tid: "customModule.personNum",
          type: "text",
          name: "personNum",
          field: "personNum",
          text: "人数",
          title: "人数",
          options: {
            testData: "0",
          },
        },
        {
          tid: "customModule.ticketDiscountPrice",
          type: "text",
          name: "ticketDiscountPrice",
          field: "ticketDiscountPrice",
          text: "单价",
          title: "单价",
          options: {
            testData: "0",
          },
        },
        {
          tid: "customModule.subtotalAmount",
          type: "text",
          name: "subtotalAmount",
          field: "subtotalAmount",
          text: "金额",
          title: "金额",
          options: {
            testData: "0",
          },
        },
        {
          tid: "customModule.activationEntDate",
          type: "text",
          name: "activationEntDate",
          field: "activationEntDate",
          text: "有效期",
          title: "有效期",
          options: {
            width: 140,
            testData: "2020-01-01 00:00:00",
          },
        },
        {
          tid: "customModule.createTime",
          type: "text",
          name: "createTime",
          field: "createTime",
          text: "售票时间",
          title: "售票时间",
          options: {
            width: 140,
            testData: "2020-01-01 00:00:00",
          },
        },
        {
          tid: "customModule.ticketOfficeName",
          type: "text",
          name: "ticketOfficeName",
          field: "ticketOfficeName",
          text: "售票点",
          title: "售票点",
          options: {
            testData: "售票点A",
          },
        },
        {
          tid: "customModule.ticketWindowName",
          type: "text",
          name: "ticketWindowName",
          field: "ticketWindowName",
          text: "售票窗口",
          title: "售票窗口",
          options: {
            testData: "窗口A",
          },
        },
        {
          tid: "customModule.createBy",
          type: "text",
          name: "createBy",
          field: "createBy",
          text: "售票员",
          title: "售票员",
          options: {
            testData: "售票员A",
          },
        },
      ]),
    ]);
  }

  return {
    addElementTypes,
  };
}
