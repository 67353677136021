/**
 * 检票时间方案明细/检票时间规则接口
 */
import request from "@/utils/request";

// 查询检票时间明细/规则列表
export function listTimes(query) {
  return request({
    url: "/ticket/times/list",
    method: "get",
    params: query,
  });
}

// 查询售票时间设置详细
export function getTimes(id) {
  return request({
    url: "/ticket/times/" + id,
    method: "get",
  });
}

// 新增售票时间设置
export function addTimes(data) {
  return request({
    url: "/ticket/times",
    method: "post",
    data: data,
  });
}

// 修改售票时间设置
export function updateTimes(data) {
  return request({
    url: "/ticket/times",
    method: "put",
    data: data,
  });
}

// 删除售票时间设置
export function delTimes(id) {
  return request({
    url: "/ticket/times/" + id,
    method: "delete",
  });
}

// 查询售票时间可用列表
export function timesUsableList() {
  return request({
    url: "/ticket/times/usableList",
    method: "get",
  });
}
