import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import { isRelogin } from "@/utils/request";
import config from "@/config";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/register", "/401", "/404", "/redirect", "/test"];

router.beforeEach(async (to, from, next) => {

  NProgress.start();
  if (getToken()) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true;
        try {
          // 判断当前用户是否已拉取完user_info信息
          await store.dispatch("GetInfo");

          if(to.name === 'CashierIndex' && store.getters.permissions.indexOf("cashier:login") === -1 && store.getters.permissions.indexOf("*:*:*") === -1){
            Message.error('该账号暂无收银台登录权限');
            await store.dispatch("LogOut");
            next({ path: "/" });
            return;
        }
          isRelogin.show = false;
          const accessRoutes = await store.dispatch("GenerateRoutes");
          // 根据roles权限生成可访问的路由表
          accessRoutes.forEach((v) => {
            router.addRoute(v); // 动态添加可访问路由表
          });
          next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        } catch (err) {
          console.error(err);
          Message.error(err);
          await store.dispatch("LogOut");
          next({ path: "/" });
        }
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({
        name: "Login",
        query: {
          redirect: to.fullPath,
          platform: config.platform,
        },
      });
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
