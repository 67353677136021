import "@/common/composition-api.js";
import Vue from "vue";
import "@/config/management";
import "normalize.css/normalize.css"; // a modern alternative to CSS resets
// import Element from "element-ui";
import "./assets/styles/element-variables.scss";
// import TDesign from 'tdesign-vue';
import "tdesign-vue/es/style/index.css";
import "./assets/styles/tailwind.css";
import '@/assets/iconfont/iconfont.css'
import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import "@/assets/styles/printer.css"; // 打印样式
import App from "./App";
import store from "./store";
import router from "./router";
import ElementUI from "@/plugins/element-ui";
import Visibility from "@/plugins/visibility";
import directive from "./directive"; // directive
import plugins from "./plugins"; // plugins
import { download } from "@/utils/request";
import "./assets/icons"; // icon
import "./permission"; // permission control
import {filters} from '@/common/filters'
import { parseTime, resetForm, addDateRange, handleTree } from "@/utils/ruoyi";
// 头部标签组件
import VueMeta from "vue-meta";
import "@/common/dayjs";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 全局方法挂载
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.download = download;
Vue.prototype.handleTree = handleTree;
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
// 全局组件挂载
Vue.use(ElementUI);
Vue.use(VueMeta);
Vue.use(directive);
Vue.use(Visibility);
Vue.use(plugins);
import table from '@/components/table/index.vue'
// 全局组件挂载
Vue.component('v-table', table)
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

// Vue.use(Element, {
//   size: Cookies.get("size") || "medium", // set element-ui default size
// });

Vue.config.productionTip = false;

console.log("main");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
