import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateAssociation } from "@/api/ticket/ota";
import { getAllTicketList } from "@/api/ticket/ticketing";
import { listPayment } from "@/api/ticket/payment";
function createForm() {
  return {
    ticketId: null,
    paymentMethodId: null
  };
}
export default {
  name: "AssociateTicketDialog",
  data() {
    return {
      visible: false,
      form: createForm(),
      rules: {
        onlineTicketName: [{
          required: true,
          message: "线上票名称不能为空",
          trigger: "blur"
        }],
        onlineTicketSettlementPrice: [{
          required: true,
          message: "线上票结算价不能为空",
          trigger: "blur"
        }],
        ticketId: [{
          required: true,
          message: "线下票类型不能为空",
          trigger: "blur"
        }],
        paymentMethodId: [{
          required: true,
          message: "支付方式不能为空",
          trigger: "blur"
        }]
      },
      ticketList: [],
      payTypeList: [],
      scenicId: null
    };
  },
  methods: {
    async initTicketList() {
      // FEAT v1.7 仅查出本景区、业务类型是散客、团队的门票
      const res = await getAllTicketList({
        scenicId: this.scenicId
      });
      this.ticketList = res.data.filter(v => {
        return v.operationType == "0" || v.operationType == "1";
      });
    },
    async initPayTypeList() {
      const res = await listPayment();
      this.payTypeList = res.rows.filter(v => v.payType != "1" && v.payType != "2" && v.payType != "3");
    },
    handleClosed() {
      this.form = createForm();
      this.$refs["form"].clearValidate();
    },
    cancel() {
      this.close();
    },
    close() {
      this.visible = false;
    },
    open(data, scenicId) {
      this.form = data;
      this.scenicId = scenicId;
      this.initTicketList();
      this.initPayTypeList();
      this.visible = true;
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const ticket = this.ticketList.find(v => v.id == this.form.ticketId);
          const payType = this.payTypeList.find(v => v.id == this.form.paymentMethodId);
          updateAssociation({
            ...this.form,
            ticketName: ticket.ticketName,
            paymentMethod: payType.paymentName
          }).then(res => {
            this.$modal.msgSuccess(res.msg);
            this.close();
            this.$emit("success");
          });
        }
      });
    }
  }
};