import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DictTag",
  filters: {
    handleArray(array) {
      if (array.length === 0) return "";
      return array.reduce((pre, cur) => {
        return pre + " " + cur;
      });
    }
  },
  props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: [Number, String, Array],
      default: null
    },
    // 当未找到匹配的数据时，显示value
    showValue: {
      type: Boolean,
      default: true
    },
    labelKey: {
      type: String,
      default: "label"
    }
  },
  data() {
    return {
      unmatchArray: [] // 记录未匹配的项
    };
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== "undefined") {
        return Array.isArray(this.value) ? this.value : [String(this.value)];
      } else {
        return [];
      }
    },
    unmatch() {
      if (this.value !== null && typeof this.value !== "undefined") {
        // 传入值为非数组
        if (!Array.isArray(this.value)) {
          return !this.options.some(v => v.value == this.value);
        }
        // 传入值为Array
        return true;
      }
      // 没有value不显示
      return false;
    }
  },
  watch: {
    value() {
      this.calcUnmatchArray();
    },
    options() {
      this.calcUnmatchArray();
    }
  },
  methods: {
    calcUnmatchArray() {
      this.unmatchArray = [];
      if (this.value !== null && typeof this.value !== "undefined") {
        // 传入值为非数组
        if (!Array.isArray(this.value)) {
          if (this.options.some(v => v.value == this.value)) return false;
          this.unmatchArray.push(this.value);
          return true;
        }
        // 传入值为Array
        this.value.forEach(item => {
          if (!this.options.some(v => v.value == item)) this.unmatchArray.push(item);
        });
        return true;
      }
      // 没有value不显示
      return false;
    }
  }
};