/* unplugin-vue-components disabled */import __unplugin_components_9 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_8 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_7 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_6 from 'element-ui/lib/radio';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/radio.css';
import __unplugin_components_5 from 'element-ui/lib/radio';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/radio.css';
import __unplugin_components_4 from 'element-ui/lib/radio-group';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/radio-group.css';
import __unplugin_components_3 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_2 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_1 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_0 from 'element-ui/lib/form';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form.css';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{ref:"form",attrs:{"model":_vm.user,"rules":_vm.rules,"label-width":"80px"}},[_c(__unplugin_components_1,{attrs:{"label":"用户姓名","prop":"userName"}},[_c(__unplugin_components_2,{attrs:{"maxlength":"30"},model:{value:(_vm.user.userName),callback:function ($$v) {_vm.$set(_vm.user, "userName", $$v)},expression:"user.userName"}})],1),_c(__unplugin_components_3,{attrs:{"label":"性别"}},[_c(__unplugin_components_4,{model:{value:(_vm.user.sex),callback:function ($$v) {_vm.$set(_vm.user, "sex", $$v)},expression:"user.sex"}},[_c(__unplugin_components_5,{attrs:{"label":"0"}},[_vm._v(" 男 ")]),_c(__unplugin_components_6,{attrs:{"label":"1"}},[_vm._v(" 女 ")])],1)],1),_c(__unplugin_components_7,[_c(__unplugin_components_8,{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")]),_c(__unplugin_components_9,{attrs:{"type":"danger","size":"mini"},on:{"click":_vm.close}},[_vm._v(" 关闭 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }