//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";
export default {
  name: "DragDiv",
  components: {
    draggable
  },
  props: {
    imgs: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currHoverRow: true,
      hoverIndex: -1
    };
  },
  methods: {
    /**拖拽开始 */
    onStart() {},
    /**拖拽结束 */

    onEnd(e) {
      const newIndex = e.newIndex;
      const oldIndex = e.oldIndex;
      const imgArr = this.imgs.split(",");
      const temp = imgArr[newIndex];
      imgArr[newIndex] = imgArr[oldIndex];
      imgArr[oldIndex] = temp;
      this.$emit("update:imgs", imgArr.join(","));
    },
    setCover(index) {
      this.onEnd({
        newIndex: 0,
        oldIndex: index
      });
    },
    removeImage(index) {
      const imgArr = this.imgs.split(",");
      imgArr.splice(index, 1);
      this.$emit("update:imgs", imgArr.join(","));
    }
  }
};