/**
 * 票号规则接口
 */
import request from "@/utils/request";

// 查询票号规则列表
export function listRule(query) {
  return request({
    url: "/ticket/rule/list",
    method: "get",
    params: query,
  });
}

// 查询票号规则详细
export function getRule(id) {
  return request({
    url: "/ticket/rule/" + id,
    method: "get",
  });
}

// 新增票号规则
export function addRule(data) {
  return request({
    url: "/ticket/rule",
    method: "post",
    data: data,
  });
}

// 修改票号规则
export function updateRule(data) {
  return request({
    url: "/ticket/rule",
    method: "put",
    data: data,
  });
}

// 删除票号规则
export function delRule(id) {
  return request({
    url: "/ticket/rule/" + id,
    method: "delete",
  });
}
// 查询票号可用列表
export function ruleUsableList() {
  return request({
    url: "/ticket/rule/usableList",
    method: "get",
  });
}
