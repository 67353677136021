import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPrinterNameList, downloadCLodop } from "@/utils/lodop-helper";
import { downloadHiprintTool } from "@/utils/hiprint-helper";
import { getPrintSettingList, updatePrintSettingList, getTemplateList } from "@/api/ticket/template";
import { PRINTER_SETTINGS } from "@/constant/localStorage";
const printWidthList = [{
  text: "58mm",
  value: "48mm"
}, {
  text: "80mm",
  value: "72mm"
}];
const printNumList = [{
  text: "1",
  value: "1"
}, {
  text: "2",
  value: "2"
}, {
  text: "3",
  value: "3"
}, {
  text: "4",
  value: "4"
}, {
  text: "5",
  value: "5"
}, {
  text: "6",
  value: "6"
}, {
  text: "7",
  value: "7"
}, {
  text: "8",
  value: "8"
}, {
  text: "9",
  value: "9"
}];
function createForm() {
  return {
    ifOpen: "2",
    printer: null,
    printWidth: null,
    printTemplate: null,
    printNum: null
  };
}
export default {
  data() {
    return {
      printWidthList,
      printNumList,
      form: createForm(),
      rules: {
        printer: [{
          required: true,
          message: "请选择打印机",
          trigger: "change"
        }],
        printWidth: [{
          required: true,
          message: "请选择打印宽度",
          trigger: "change"
        }],
        printTemplate: [{
          required: true,
          message: "请选择打印模板",
          trigger: "change"
        }],
        printNum: [{
          required: true,
          message: "请选择打印份数",
          trigger: "change"
        }]
      },
      printerNameList: [],
      ticketingPrintSettingList: [],
      templateList: []
    };
  },
  computed: {
    generalTemplateList() {
      return this.templateList.filter(v => v.tempType == "普通门票");
    },
    ticketTemplateList() {
      return this.templateList.filter(v => v.tempType == "门票小票");
    },
    cashierTemplateList() {
      return this.templateList.filter(v => v.tempType == "收银小票");
    },
    memberTemplateList() {
      return this.templateList.filter(v => v.tempType == "会员小票");
    },
    checkTemplateList() {
      return this.templateList.filter(v => v.tempType == "检票小票");
    },
    generalPrintForm() {
      const settings = this.ticketingPrintSettingList.find(v => v.printType == "普通门票");
      if (settings) {
        return settings;
      } else {
        return createForm();
      }
    },
    ticketPrintForm() {
      const settings = this.ticketingPrintSettingList.find(v => v.printType == "门票小票");
      if (settings) {
        return settings;
      } else {
        return createForm();
      }
    },
    cashierPrintForm() {
      const settings = this.ticketingPrintSettingList.find(v => v.printType == "收银小票");
      if (settings) {
        return settings;
      } else {
        return createForm();
      }
    },
    memberPrintForm() {
      const settings = this.ticketingPrintSettingList.find(v => v.printType == "会员小票");
      if (settings) {
        return settings;
      } else {
        return createForm();
      }
    },
    checkPrintForm() {
      const settings = this.ticketingPrintSettingList.find(v => v.printType == "检票小票");
      if (settings) {
        return settings;
      } else {
        return createForm();
      }
    }
  },
  created() {
    this.initPrintSettingList();
    this.initPrinterNameList();
    this.initTemplateList();
  },
  methods: {
    async initPrintSettingList() {
      const settingsJson = localStorage.getItem(PRINTER_SETTINGS);
      this.ticketingPrintSettingList = JSON.parse(settingsJson);
    },
    async initTemplateList() {
      const response = await getTemplateList();
      this.templateList = response.rows;
    },
    async save() {
      if (this.generalPrintForm.ifOpen == "1") {
        await this.$refs["generalPrintForm"].validate();
      }
      console.log(this.ticketPrintForm.ifOpen);
      if (this.ticketPrintForm.ifOpen == "1") {
        await this.$refs["ticketPrintForm"].validate();
      }
      if (this.cashierPrintForm.ifOpen == "1") {
        await this.$refs["cashierPrintForm"].validate();
      }
      if (this.memberPrintForm.ifOpen == "1") {
        await this.$refs["memberPrintForm"].validate();
      }
      if (this.checkPrintForm.ifOpen == "1") {
        await this.$refs["checkPrintForm"].validate();
      }
      localStorage.setItem(PRINTER_SETTINGS, JSON.stringify(this.ticketingPrintSettingList));
      this.$modal.msgSuccess("保存成功");
      this.$tab.closePage();
    },
    cancel() {
      this.$tab.closePage();
    },
    initPrinterNameList() {
      this.printerNameList = getPrinterNameList();
    },
    downloadHiprintTool() {
      downloadHiprintTool();
    },
    downloadCLodopTool() {
      downloadCLodop();
    }
  }
};