/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./prodInfo.vue?vue&type=template&id=e8f8ffc8&scoped=true"
import script from "./prodInfo.vue?vue&type=script&lang=js"
export * from "./prodInfo.vue?vue&type=script&lang=js"
import style0 from "./prodInfo.vue?vue&type=style&index=0&id=e8f8ffc8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f8ffc8",
  null
  
)

export default component.exports