//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateUserProfile } from "@/api/system/user";
export default {
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      // 表单校验
      rules: {
        userName: [{
          required: true,
          message: "用户姓名不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateUserProfile(this.user).then(() => {
            this.$modal.msgSuccess("修改成功");
          });
        }
      });
    },
    close() {
      this.$tab.closePage();
    }
  }
};