import { groupBy } from "lodash-es";
import dayjs from "dayjs";

/**
 * 按分组生成表格span
 * @param {Array} list
 * @param {Array} keys
 * @param {Object} options
 * @param {string} options.key
 * @param {Function} options.groupHandler
 */
export function genGroupRowspan(list, keys = [], options = {}) {
  options.key = options.key ?? "rowspan";
  const l = list.map((v) => ({
    ...v,
    [options.key]: [],
  }));

  const l2 = sortRowspanMain(l, keys, { groupHandler: options.groupHandler });
  genRowspanMain(l2, keys, options.key, 0);
  return l2;
}

/**
 *
 * @param {Array} list
 * @param {Array} keys
 * @param {Object} options
 * @param {Function} options.groupHandler
 */
function sortRowspanMain(list, keys, options = {}) {
  if (keys.length === 0) {
    return list;
  }

  const ll = [];

  Object.values(groupBy(list, keys[0])).map((v) => {
    const l = sortRowspanMain(v, keys.slice(1, keys.length), options);
    if (typeof options.groupHandler === "function") {
      options.groupHandler(keys[0], l);
    }
    return ll.push(...l);
  });

  return ll;
}

/**
 *
 * @param {Array} list
 * @param {Array} keys
 * @param {string} key
 */
function genRowspanMain(list, keys, key) {
  if (keys.length === 0) {
    return list;
  }

  Object.values(groupBy(list, keys[0])).forEach((v) => {
    v.forEach((item, i) => {
      item[key].push(i === 0 ? v.length : 0);
    });
    genRowspanMain(v, keys.slice(1, keys.length), key);
  });
}

/**
 * 日期范围选择器快捷选择配置
 */
export const dateRangePickerShortcuts = [
  {
    text: "最近一周",
    onClick(picker) {
      const start = dayjs().subtract(1, "w").startOf("d").toDate();
      const end = dayjs().endOf("d").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一个月",
    onClick(picker) {
      const start = dayjs().subtract(1, "M").startOf("d").toDate();
      const end = dayjs().endOf("d").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一年",
    onClick(picker) {
      const start = dayjs().subtract(1, "y").startOf("d").toDate();
      const end = dayjs().endOf("d").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "今日",
    onClick(picker) {
      const start = dayjs().startOf("d").toDate();
      const end = dayjs().endOf("d").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "本周",
    onClick(picker) {
      const start = dayjs().startOf("w").toDate();
      const end = dayjs().endOf("w").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "本月",
    onClick(picker) {
      const start = dayjs().startOf("M").toDate();
      const end = dayjs().endOf("M").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "今年",
    onClick(picker) {
      const start = dayjs().startOf("y").toDate();
      const end = dayjs().endOf("y").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "上周",
    onClick(picker) {
      const start = dayjs().subtract(1, "w").startOf("w").toDate();
      const end = dayjs().subtract(1, "w").endOf("w").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "上月",
    onClick(picker) {
      const start = dayjs().subtract(1, "M").startOf("M").toDate();
      const end = dayjs().subtract(1, "M").endOf("M").toDate();
      picker.$emit("pick", [start, end]);
    },
  },
];
