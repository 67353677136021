/**
 * 景区业务接口
 */
import request from "@/utils/request";

// 查询景区业务类型列表
export function listOperation(query) {
  return request({
    url: "/ticket/operation/list",
    method: "get",
    params: query,
  });
}

// 查询景区业务类型详细
export function getOperation(id) {
  return request({
    url: "/ticket/operation/" + id,
    method: "get",
  });
}

// 新增景区业务类型
export function addOperation(data) {
  return request({
    url: "/ticket/operation",
    method: "post",
    data: data,
  });
}

// 修改景区业务类型
export function updateOperation(data) {
  return request({
    url: "/ticket/operation",
    method: "put",
    data: data,
  });
}

// 删除景区业务类型
export function delOperation(id) {
  return request({
    url: "/ticket/operation/" + id,
    method: "delete",
  });
}

// 查询景区业务可用列表
export function operationUsableList() {
  return request({
    url: "/ticket/operation/usableList",
    method: "get",
  });
}
