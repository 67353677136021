import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { getDataStatistics, getSalesEcharts, getNotice } from "@/api/ticket/home";
import { mapGetters } from "vuex";
import animejs from "animejs";
import dayjs from "dayjs";
import { moneyFormatter } from "@/utils/index";

/**
 * 管理端首页
 */
export default {
  name: "IndexManagement",
  data() {
    return {
      salesDateRange: "近7天",
      dataStatistics: {
        todaySalesPersonTimes: 0,
        todayAddMember: 0,
        yesAddEnteringNum: 0,
        yesDaySalesPersonTimes: 0,
        todayRefundPersonTime: 0,
        todayTicketRefundAmount: 0,
        yesAddMember: 0,
        yesAddMemberAmount: 0,
        yesTicketRefundAmount: 0,
        todayAddEnteringNum: 0,
        yesDayTicketSalesAmount: 0,
        todayAddMemberAmount: 0,
        todayTicketSalesAmount: 0,
        yesRefundPersonTime: 0,
        updateTime: ""
      },
      refreshDataAnime: null,
      countTicketAmount: 0,
      countTicketNum: 0,
      noticeList: []
    };
  },
  computed: {
    ...mapGetters(["shopId"])
  },
  mounted() {
    this.initDataStatistics();
    this.initSalesEcharts();
    this.getNotice();
    this.refreshDataAnime = animejs({
      targets: this.$refs["refreshDataEl"],
      rotate: "1turn",
      duration: 1000,
      easing: "linear",
      autoplay: false
    });
  },
  methods: {
    moneyFormatter,
    goNotice(item) {
      window.open(`https://www.yuque.com/zhanghehe-q7xmh/gy3w0y/${item.slug}`);
    },
    goMoreNotice() {
      window.open("https://www.yuque.com/zhanghehe-q7xmh/gy3w0y");
    },
    goSalesParticular() {
      this.$router.push({
        name: "SalesParticular"
      });
    },
    refreshData(e) {
      console.log(e);
      this.refreshDataAnime.play();
      this.initDataStatistics();
    },
    async getNotice() {
      const res = await getNotice();
      this.noticeList = res.data.map(v => {
        return {
          ...v,
          date: dayjs(v.created_at).format("YYYY-MM-DD")
        };
      });
    },
    async initSalesEcharts() {
      let num = 7;
      switch (this.salesDateRange) {
        case "近7天":
          num = 7;
          break;
        case "近30天":
          num = 30;
          break;
        case "近90天":
          num = 90;
          break;
        default:
          break;
      }
      const res = await getSalesEcharts(num);
      // Object.assign(this.dataStatistics, res.data);
      const data = res.data;
      this.countTicketAmount = data.accumulatedSales.countTicketAmount;
      this.countTicketNum = data.accumulatedSales.countTicketNum;
      const dateList = data.salesList.map(v => v.date);
      const saleData = data.salesList.map(v => v.num);
      const refundData = data.salesRefundList.map(v => v.num);
      this.initTicketSalesChart(dateList, saleData, refundData);
    },
    async initDataStatistics() {
      const res = await getDataStatistics(this.shopId);
      Object.assign(this.dataStatistics, res.data);
    },
    salesDateRangeChange(e) {
      console.log(e);
      this.initSalesEcharts();
    },
    initTicketSalesChart(dateList, saleData, refundData) {
      console.log("initTicketSalesChart");
      const ticketSalesChart = document.getElementById("ticketSalesChart");
      const chart = echarts.init(ticketSalesChart);
      const option = {
        title: {
          text: "门票销售"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          data: ["售票数量", "退票数量"],
          selectorLabel: {
            show: true
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          boundaryGap: false,
          data: dateList
        }],
        yAxis: [{
          type: "value"
        }],
        series: [{
          name: "售票数量",
          type: "line",
          smooth: true,
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: saleData
        }, {
          name: "退票数量",
          type: "line",
          smooth: true,
          stack: "Total",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: refundData
        }]
      };
      chart.setOption(option);
    }
  }
};