//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import printerStyle from "!raw-loader!@/assets/styles/printer.css";
import { mapState } from "vuex";
function createSettings() {
  return {
    ifOpen: "2",
    printer: null,
    printWidth: null,
    printTemplate: null,
    printNum: null
  };
}
function createConfig() {
  return {
    ifScenic: 0,
    ifTicketType: 0,
    ifOrderNo: 0,
    ifSaleWindow: 0,
    ifSaleTime: 0,
    ifOperator: 0,
    ifNum: 0,
    ifUnitPrice: 0,
    ifTicketName: 0,
    ifAmountReceivable: 0,
    ifActualAmount: 0,
    ifSettlementMethod: 0,
    ifChange: 0,
    ifEndNote1: 0,
    ifEndNote2: 0,
    endNote1: "",
    endNote2: "",
    printer: ""
  };
}
function createData() {
  return {
    scenicName: "",
    orderNo: "",
    ticketOfficeName: "",
    ticketWindowName: "",
    createTime: "",
    createBy: "",
    ticketingOrderInfoList: [],
    orderAmount: "",
    actualPayment: "",
    payWay: "",
    orderChangeMoney: ""
  };
}

/**
 * 门票收银打印模板
 */
export default {
  name: "TicketCashierPrintTemplate",
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: createData(),
      config: createConfig(),
      settings: createSettings()
    };
  },
  computed: {
    ...mapState("office", ["office"])
  },
  methods: {
    setConfig(config) {
      this.config = createConfig();
      Object.assign(this.config, config);
    },
    setSettings(settings) {
      this.settings = createSettings();
      Object.assign(this.settings, settings);
    },
    setData(data) {
      this.data = createData();
      Object.assign(this.data, data);
    },
    async print(data, settings, config) {
      if (data) {
        this.setData(data);
      }
      if (settings) {
        this.setSettings(settings);
      }
      if (config) {
        this.setConfig(config);
      }
      const printer = this.settings.printer;
      // if (!printer) {
      //   this.$modal.msgError("未设置打印机");
      //   return;
      // }

      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          // 打印机名称
          const style = `<style>${printerStyle}</style>`;
          const strHTML = this.$refs["template"].innerHTML;
          const LODOP = getLodop();
          LODOP.PRINT_INIT();
          LODOP.SET_PRINTER_INDEXA(printer);
          LODOP.SET_PRINT_PAGESIZE(3, this.config.width, 10, "CreateCustomPage");
          LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", style + strHTML);
          LODOP.SET_LICENSES("深圳市智络科技有限公司", "BBFF47D5AB0D522C0007D05CDE387E65", "", "");
          LODOP.On_Return = (taskID, value) => {
            console.log(taskID, value);
            this.$emit("success");
            resolve();
          };
          // LODOP.PREVIEW();
          LODOP.PRINT();
          // LODOP.PRINT_DESIGN();
        });
      });
    }
  }
};