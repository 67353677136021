import Cookies from "js-cookie";

const TokenKey = "Admin-Token-Ticket";
const UserKey = "Admin-User-Ticket";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

// 存储用户信息
export function getUser() {
  return Cookies.get(UserKey);
}
export function setUser(data) {
  return Cookies.set(UserKey,data);
}
export function removeUser() {
  return Cookies.remove(UserKey);
}

