//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import printerStyle from "!raw-loader!@/assets/styles/printer.css";
import { mapState } from "vuex";
function createConfig() {
  return {
    ifScenic: 0,
    ifTicketType: 0,
    ifOrderNo: 0,
    ifSaleWindow: 0,
    ifSaleTime: 0,
    ifOperator: 0,
    ifMemberTicket: 0,
    ifMemberCard: 0,
    ifMemberName: 0,
    ifAmountReceivable: 0,
    ifActualAmount: 0,
    ifSettlementMethod: 0,
    ifChange: 0,
    ifRechargeAmount: 0,
    ifRechargeTimes: 0,
    ifGiftAmount: 0,
    ifGiveNumber: 0,
    ifOldValidity: 0,
    ifNewValidity: 0,
    ifNewPrintCardNum: 0,
    ifSTime: 0,
    ifETime: 0,
    ifEndNote1: 0,
    ifEndNote2: 0,
    endNote1: "",
    endNote2: ""
  };
}
function createData() {
  return {
    scenicName: "",
    // 1 退款 2 补缴
    paymentType: 1,
    transactionOrderNo: "",
    updateTime: "",
    createBy: "",
    ticketName: "",
    money: "0",
    settlementMethod: "",
    ticketPoint: "",
    windows: "",
    ticketNo: "",
    orderNo: "",
    sTime: "",
    eTime: "",
    payName: "",
    windowsName: "",
    pointName: ""
  };
}

/**
 * 门票退款补缴打印模板
 */
export default {
  name: "RefundPayBackPrintTemplate",
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: createData(),
      config: createConfig()
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    operationType() {
      return this.data.paymentType == 1 ? "退款" : "补缴";
    }
  },
  methods: {
    setConfig(config) {
      Object.assign(this.config, createConfig());
      Object.assign(this.config, config);
    },
    setData(data) {
      Object.assign(this.data, createData());
      Object.assign(this.data, data);
    },
    print(data, config) {
      if (data) {
        this.setData(data);
      }
      if (config) {
        this.setConfig(config);
      }
      if (!this.config.printer) {
        this.$modal.msgError("未设置打印机");
      }
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const style = `<style>${printerStyle}</style>`;
          const strHTML = this.$refs["template"].innerHTML;
          const LODOP = getLodop();
          LODOP.PRINT_INIT();
          LODOP.SET_PRINTER_INDEXA(this.config.printer);
          LODOP.SET_PRINT_PAGESIZE(3, this.config.width, 10, "CreateCustomPage");
          LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", style + strHTML);
          LODOP.SET_LICENSES("深圳市智络科技有限公司", "BBFF47D5AB0D522C0007D05CDE387E65", "", "");
          LODOP.On_Return = (taskID, value) => {
            console.log(taskID, value);
            this.$emit("success");
            resolve();
          };
          // LODOP.PREVIEW();
          LODOP.PRINT();
          // LODOP.PRINT_DESIGN();
        });
      });
    }
  }
};