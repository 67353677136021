/**
 * 游客类型接口
 */
import request from "@/utils/request";

// 查询游客类型列表
export function listType(query) {
  return request({
    url: "/ticket/type/list",
    method: "get",
    params: query,
  });
}

// 查询游客类型详细
export function getType(id) {
  return request({
    url: "/ticket/type/" + id,
    method: "get",
  });
}

// 新增游客类型
export function addType(data) {
  return request({
    url: "/ticket/type",
    method: "post",
    data: data,
  });
}

// 修改游客类型
export function updateType(data) {
  return request({
    url: "/ticket/type",
    method: "put",
    data: data,
  });
}

// 删除游客类型
export function delType(id) {
  return request({
    url: "/ticket/type/" + id,
    method: "delete",
  });
}

// 查询游客类型可用列表
export function typeUsableList() {
  return request({
    url: "/ticket/type/usableList",
    method: "get",
  });
}
