/**
 * 门票接口
 */
import request from "@/utils/request";

// 查询门票列表
export function listTicketing(query) {
  return request({
    url: "/ticket/ticketing/list",
    method: "get",
    params: query,
  });
}

// 查询门票列表
export function getAllTicketList(query) {
  return request({
    url: "/ticket/ticketing/selectOption",
    method: "get",
    params: query,
  });
}

// 查询门票详细
export function getTicketing(id) {
  return request({
    url: "/ticket/ticketing/" + id,
    method: "get",
  });
}

// 新增门票
export function addTicketing(data) {
  return request({
    url: "/ticket/ticketing",
    method: "post",
    data: data,
  });
}

// 修改门票
export function updateTicketing(data) {
  return request({
    url: "/ticket/ticketing",
    method: "put",
    data: data,
  });
}

// 删除门票
export function delTicketing(id) {
  return request({
    url: "/ticket/ticketing/" + id,
    method: "delete",
  });
}

// 查询会员类型门票列表
export function getMemberTicketList(query) {
  return request({
    url: "/ticket/ticketing/list",
    method: "get",
    params: { ...query, operationType: "2" },
  });
}

// 获取检票记录
export function getCheckRecordList(query) {
  return request({
    url: "/ticket/inspectionRecord/list",
    method: "get",
    params: query,
  });
}

/**
 * 退款/补缴
 * @param {string} ticketNo
 * @returns
 */
export function payOrRefund(data) {
  return request({
    url: `/office/saleTicket/payOrRefund`,
    method: "post",
    data,
  });
}
