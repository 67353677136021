/* unplugin-vue-components disabled */import __unplugin_components_9 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_8 from 'element-ui/lib/button';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/button.css';
import __unplugin_components_7 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_6 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_5 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_4 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_3 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_2 from 'element-ui/lib/input';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/input.css';
import __unplugin_components_1 from 'element-ui/lib/form-item';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form-item.css';
import __unplugin_components_0 from 'element-ui/lib/form';import 'element-ui/lib/theme-chalk/base.css';import 'element-ui/lib/theme-chalk/form.css';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(__unplugin_components_0,{ref:"form",attrs:{"model":_vm.user,"rules":_vm.rules,"label-width":"80px"}},[_c(__unplugin_components_1,{attrs:{"label":"旧密码","prop":"oldPassword"}},[_c(__unplugin_components_2,{attrs:{"placeholder":"请输入旧密码","type":"password","show-password":""},model:{value:(_vm.user.oldPassword),callback:function ($$v) {_vm.$set(_vm.user, "oldPassword", $$v)},expression:"user.oldPassword"}})],1),_c(__unplugin_components_3,{attrs:{"label":"新密码","prop":"newPassword"}},[_c(__unplugin_components_4,{attrs:{"placeholder":"请输入新密码","type":"password","show-password":""},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})],1),_c(__unplugin_components_5,{attrs:{"label":"确认密码","prop":"confirmPassword"}},[_c(__unplugin_components_6,{attrs:{"placeholder":"请确认新密码","type":"password","show-password":""},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})],1),_c(__unplugin_components_7,[_c(__unplugin_components_8,{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")]),_c(__unplugin_components_9,{attrs:{"type":"danger","size":"mini"},on:{"click":_vm.close}},[_vm._v(" 关闭 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }