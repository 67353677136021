//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MemberInformation from "./components/MemberInformation.vue";
import { getMember, memberOrderList } from "@/api/ticket/member";
import { getGroup } from "@/api/ticket/group";
import { dateRangePickerShortcuts } from "@/utils/el-utils";
import defaultImg from "@/assets/images/profile.png";
import { consumptionOperationTypeList } from "@/common/form";
import dayjs from "dayjs";
import { listUser } from "@/api/system/user";
import { addDateRange } from "@/utils/ruoyi";
export default {
  components: {
    MemberInformation
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      consumptionOperationTypeList,
      defaultImg,
      activeNameOne: "会员信息",
      activeNameTwo: "卡片信息",
      activeNameThree: "操作记录",
      //   操作记录查询参数
      queryParams: {
        //操作类型
        documentType: null,
        //操作人
        salesId: null,
        pageSize: 10,
        pageNum: 1,
        dateRange: null
      },
      total: 0,
      //会员信息数据
      memberInfo: {
        avatar: null,
        memberName: "",
        phone: "",
        memberNum: "",
        sex: "",
        memberAge: "",
        birthday: "",
        certificateType: "",
        certificateNumber: "",
        memberGroupId: "",
        memberGroupName: "",
        memberCardNumber: "",
        ticketName: "",
        printedCardNumber: "",
        numberOfTimes: "",
        remainingSum: "0",
        giveMoney: "0",
        card_source: "",
        startTime: "",
        endTime: "",
        logOff: "",
        remark: "",
        totalMoney: "0"
      },
      //卡片信息label
      memberHead: [{
        name: "会员姓名",
        prop: "memberName"
      }, {
        name: "会员手机",
        prop: "phone"
      },
      // { name: "会员编号", prop: "memberNum" },
      {
        name: "会员性别",
        prop: "sex"
      }, {
        name: "会员年龄",
        prop: "memberAge"
      }, {
        name: "会员生日",
        prop: "birthday"
      }, {
        name: "证件类型",
        prop: "certificateType"
      }, {
        name: "证件号码",
        prop: "certificateNumber"
      }, {
        name: "会员分组",
        prop: "memberGroupName"
      }
      // { name: "会员住址", prop: "name" },
      ],
      headListTwo: [{
        name: "会员卡号",
        prop: "memberCardNumber"
      }, {
        name: "会员票类",
        prop: "ticketName"
      }, {
        name: "印刷卡号",
        prop: "printedCardNumber"
      }, {
        name: "可用次数",
        prop: "numberOfTimes"
      }, {
        name: "会员余额",
        prop: "totalMoney"
      }, {
        name: "会员本金",
        prop: "remainingSum"
      }, {
        name: "赠送余额",
        prop: "giveMoney"
      }, {
        name: "开卡渠道",
        prop: "card_source"
      }, {
        name: "开卡时间",
        prop: "createTime"
      }, {
        name: "截止有效期",
        prop: "endTime"
      }, {
        name: "已注销",
        prop: "logOff"
      },
      // { name: "推荐人", prop: "name" },
      {
        name: "备注",
        prop: "remark"
      }],
      pickerOptions: {
        shortcuts: dateRangePickerShortcuts
      },
      // 操作人
      operatorList: [],
      // 操作记录
      tableData: [],
      activeHead: 0
    };
  },
  computed: {
    head() {
      return this.memberInfo.avatar ?? defaultImg;
    }
  },
  async created() {
    this.initDateRange();
    await this.initMember();
    this.getMemberOrderList();
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const res = await listUser();
      this.operatorList = res.rows;
    },
    //获取会员分组信息
    getMemberGroupList() {
      getGroup(this.memberInfo.memberGroupId).then(res => {
        if (res.code == 200) {
          this.memberInfo.memberGroupName = res.data.memberGroupName;
        }
      });
    },
    //会员和子会员点击事件
    handleClickOne(tab, event) {},
    //操作记录和人脸同步点击事件
    handleClickThree(tab, event) {},
    //获取当前日期 [开始时间，结束时间]
    initDateRange() {
      const startDate = dayjs().startOf("d").format("YYYY-MM-DD");
      const endDate = dayjs().endOf("d").format("YYYY-MM-DD");
      this.queryParams.dateRange = [startDate, endDate];
    },
    initMember() {
      return getMember(this.id).then(res => {
        Object.assign(this.memberInfo, res.data);
        this.memberInfo.totalMoney = this.memberInfo.remainingSum + this.memberInfo.giveMoney;

        //回显会员分组
        this.getMemberGroupList();
        //判断会员卡是否注销
        if (this.memberInfo.statue == -1) {
          this.memberInfo.logOff = "是";
        } else {
          this.memberInfo.logOff = "否";
        }
        //给会员年龄赋值
        if (this.memberInfo.birthday != null) {
          this.memberInfo.memberAge = dayjs(new Date()).diff(dayjs(this.memberInfo.birthday), "year");
        }
      });
    },
    //获取会员操作记录
    getMemberOrderList() {
      memberOrderList(addDateRange({
        ...this.queryParams,
        memberCardNumber: this.memberInfo.memberCardNumber
      }, this.queryParams.dateRange)).then(res => {
        //消费点和消费项目暂无
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 搜索操作记录
    handleQuery() {
      this.getMemberOrderList();
    }
  }
};