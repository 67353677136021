import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AssociateTicketDialog from "../components/AssociateTicketDialog.vue";
import { getAssociationList, getBindShopList, disassociate } from "@/api/ticket/ota";

// 票类关联设置
export default {
  name: "TicketAssociationSettings",
  components: {
    AssociateTicketDialog
  },
  data() {
    return {
      associationList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ticketingTimeName: null
      },
      openShopUuid: null,
      loading: false,
      total: 0,
      otaShopList: []
    };
  },
  async mounted() {
    await this.initBindShopList();
    this.getList();
  },
  methods: {
    async initBindShopList() {
      const res = await getBindShopList({
        otaType: 1
      });
      this.otaShopList = res.data.filter(v => v.scenicId);
      if (this.otaShopList.length > 0) {
        this.openShopUuid = this.otaShopList[0].openShopUuid;
      }
    },
    getList() {
      this.loading = true;
      getAssociationList({
        ...this.queryParams,
        openShopUuid: this.openShopUuid
      }).then(response => {
        this.associationList = response.rows;
        this.total = response.total;
      }).finally(() => {
        this.loading = false;
      });
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    meituanShopChange(e) {
      console.log(e);
      // 店铺切换，刷新列表
      this.getList();
    },
    // 关联
    associate(item) {
      const otaShop = this.otaShopList.find(v => v.openShopUuid == this.openShopUuid);
      this.$refs["associateTicketDialog"].open(item, otaShop.scenicId);
    },
    // 解除关联
    disassociate(item) {
      this.$confirm("确定解除关联？", "解除关联", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await disassociate(item.id);
        this.$modal.msgSuccess(res.msg);
        this.getList();
      });
    }
  }
};