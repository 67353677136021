
export const filters = {
  // 金额过滤器
  formatMoney: value => {
    if(value === undefined || value === null){
      return '0.00';
    }
    // 将分转化为元并保留2位小数
    value = (value / 100).toFixed(2);
    return value;
  },

  // 商品类型
  prodType: value => {
    if(value === 0 || value === 'ordinary'){
      return '普通';
    }
    if(value === 1 || value === 'service'){
      return '服务';
    }
  },

}

