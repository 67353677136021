/**
 * 景区接口
 */
import request from "@/utils/request";

// 查询景区列表
export function listScenic(query) {
  return request({
    url: "/ticket/scenic/list",
    method: "get",
    params: query,
  });
}

// 查询景区详细
export function getScenic(id) {
  return request({
    url: "/ticket/scenic/" + id,
    method: "get",
  });
}

// 新增景区
export function addScenic(data) {
  return request({
    url: "/ticket/scenic",
    method: "post",
    data: data,
  });
}

// 修改景区
export function updateScenic(data) {
  return request({
    url: "/ticket/scenic",
    method: "put",
    data: data,
  });
}

// 删除景区
export function delScenic(id) {
  return request({
    url: "/ticket/scenic/" + id,
    method: "delete",
  });
}

// 查询景区可用列表
export function scenicUsableList() {
  return request({
    url: "/ticket/scenic/usableList",
    method: "get",
  });
}
