import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCahierDetail, returnCashier } from "@/api/product/order";
import { payStatus } from "@/utils/dictionary";
export default {
  components: {},
  props: {
    currentRowId: {
      type: String,
      default: ''
    },
    operaType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {
        notes: '',
        refundAmount: '',
        refundMethod: ''
      },
      rules: {
        refundMethod: [{
          required: true,
          message: '请选择退款方式',
          trigger: 'change'
        }]
      },
      detailInfo: {},
      payStatusList: payStatus(),
      table1Height: 0,
      multipleSelection: [],
      loading: true,
      submitLoading: false
    };
  },
  computed: {},
  watch: {
    multipleSelection: {
      deep: true,
      handler(val) {
        if (val && val.length > 0) {
          let sum = val.reduce((cur, next) => {
            return cur + next.myReturnQuantity * next.price;
          }, 0);
          this.ruleForm.refundAmount = (sum / 100).toFixed(2);
        }
      }
    }
  },
  methods: {
    // 获取订单详情
    getDetailInfo() {
      this.loading = true;
      getCahierDetail(this.currentRowId).then(res => {
        if (res.code === 200) {
          this.detailInfo = res.data;
          if (this.operaType === 'return') {
            //默认退货数量为 总数量-已退数量
            this.detailInfo.detailList = res.data.detailList.map(item => {
              return {
                ...item,
                myReturnQuantity: item.quantity - item.returnsQuantity
              };
            });
          }
          this.$nextTick(() => {
            this.table1Height = this.$refs.tableArea1.offsetHeight - 64;
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 计数器加减
    changeNum(val, row) {
      if (val === 'minus') {
        if (row.myReturnQuantity <= 0) return;
        row.myReturnQuantity--;
      } else {
        if (row.quantity - row.returnsQuantity === row.myReturnQuantity) return;
        row.myReturnQuantity++;
      }
    },
    changeQuantity(val, row) {
      //总数量-已退数量 = 还可以退的数量
      const returningNum = row.quantity - row.returnsQuantity;
      if (this.isPositiveInteger(val)) {
        if (val > returningNum) {
          row.myReturnQuantity = returningNum;
        }
      } else {
        row.myReturnQuantity = '';
      }
    },
    isPositiveInteger(str) {
      return /^[1-9]\d*$/.test(str);
    },
    handlerSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlerSelectionChangeAll(val) {
      this.multipleSelection = val;
    },
    //退货数量为0不能勾选
    isRowSelectable(row) {
      if (row.myReturnQuantity == 0) {
        return false;
      } else {
        return true;
      }
    },
    // 退款
    submit() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择需要退款的商品');
        return;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          let detailList = this.multipleSelection.map(item => {
            return {
              productId: item.productId,
              type: item.type,
              returnsQuantity: item.myReturnQuantity
            };
          });
          const obj = {
            orderNo: this.detailInfo.no,
            refundAmount: (this.ruleForm.refundAmount * 100).toFixed(),
            refundMethod: this.ruleForm.refundMethod,
            detailList: detailList,
            notes: this.ruleForm.notes
          };
          returnCashier(obj).then(res => {
            if (res.code === 200) {
              this.$message.success('退货成功');
              this.toBack();
            }
          }).finally(() => {
            this.submitLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    toBack() {
      this.$emit('e-back');
    }
  },
  created() {
    this.getDetailInfo();
  },
  mounted() {},
  destroyed() {}
};