//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listMember } from "@/api/ticket/member";
import { moneyFormatter } from "@/utils";
export default {
  data() {
    return {
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      tableColumns: {
        rowClick: row => this.rowClick(row),
        columns: [{
          param: 'memberCardNumber',
          label: '会员卡号',
          tooltip: true
        }, {
          param: 'memberName',
          label: '会员姓名'
        }, {
          param: 'phone',
          label: '手机号码'
        }, {
          param: 'remainingSum',
          label: '余额',
          subdivision: row => {
            return moneyFormatter(row.remainingSum + row.giveMoney);
          }
        }]
      }
    };
  },
  methods: {
    open() {
      this.getList();
      this.visible = true;
    },
    rowClick(row) {
      this.visible = false;
      this.$emit('e-row', row);
    },
    handleQuery() {
      this.form.pageNum = 1;
      this.getList();
    },
    /** 查询会员列表 */
    async getList() {
      this.loading = true;
      try {
        const response = await listMember({
          ...this.form
        });
        this.tableData = response.rows;
        this.total = response.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};