//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPrinterNameList, downloadCLodop } from "@/utils/lodop-helper";
import { getTemplateConfig, updateTemplateConfig, addTemplateConfig } from "@/api/ticket/template";
import { printTypeList } from "@/common/form";
export default {
  name: "AddPrintTemplate",
  props: {
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "add"
    }
  },
  data() {
    return {
      printTypeList,
      form: {
        templateName: "",
        tempType: "",
        printer: "",
        width: ""
      },
      rules: {
        templateName: [{
          required: true,
          message: "请输入模板名称",
          trigger: "blur"
        }],
        tempType: [{
          required: true,
          message: "请选择票样模板类型",
          trigger: "blur"
        }],
        printer: [{
          required: true,
          message: "请选择打印机",
          trigger: "blur"
        }],
        width: [{
          required: true,
          message: "请输入打印宽度",
          trigger: "blur"
        }]
      },
      printerNameList: []
    };
  },
  mounted() {
    if (this.type == "add") {
      this.form.tempType = "普通门票";
    }
    this.initPrinterNameList();
    this.initPrintConfig();
  },
  methods: {
    async save() {
      try {
        await this.$refs["form"].validate();
      } catch (e) {
        return;
      }
      if (this.type == "edit") {
        const res = await updateTemplateConfig(this.form);
        if (res.code == 200) {
          this.$modal.msgSuccess(res.msg);
          // this.$cache.local.set(PRINTER_NAME, this.form.printer);
          this.$tab.closePage();
        }
      } else {
        const res = await addTemplateConfig(this.form);
        if (res.code == 200) {
          this.$modal.msgSuccess(res.msg);
          // this.$cache.local.set(PRINTER_NAME, this.form.printer);
          this.$tab.closePage();
        }
      }
    },
    async initPrintConfig() {
      const res = await getTemplateConfig(this.id);
      Object.assign(this.form, res.data);
    },
    downloadTool() {
      downloadCLodop();
    },
    goBack() {
      this.$tab.closePage();
    },
    initPrinterNameList() {
      this.printerNameList = getPrinterNameList();
    }
  }
};