import IndexOfficeLayout from "@/layout/indexOffice";

export const officeRoutes = [
  {
    path: "",
    component: IndexOfficeLayout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: "IndexOffice" */ "@/views/IndexOffice"),
        name: "Index",
        meta: { title: "首页", icon: "icon-shouye", affix: true },
      },
    ],
  },
  {
    path: "/ticket-office/member",
    component: IndexOfficeLayout,
    hidden: true,
    children: [
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "MemberDetails" */ "@/views/common/MemberDetails/index"
          ),
        name: "MemberDetails",
        props: true,
        meta: { title: "会员详情", activeMenu: "/member" },
      },
      // {
      //   path: "/add/:id",
      //   component: () => import("@/views/ticketing/operation/addTicket"),
      //   name: "EditTicket",
      //   props: true,
      //   meta: { title: "新增会员", activeMenu: "/ticket/operation" },
      // },
    ],
  },
  {
    path: "/printSettings",
    component: IndexOfficeLayout,
    children: [
      {
        path: "printSettings",
        name: "printSettings",
        component: () =>
          import(
            /* webpackChunkName: "PrintSettings" */ "@/views/common/PrintSettings"
          ),
        meta: { title: "打印设置" },
      },
    ],
    hidden: true,
    },
    {
        path: "/billingLeaseQ/orderRecordDetails",
        component: IndexOfficeLayout,
        hidden: true,
        children: [
          {
            path: ":item",
            component: () =>
              import(
                 "@/views/common/orderRecordDetails/index.vue"
              ),
            name: "orderRecordDetails",
            props: true,
            meta: { title: "租赁订单详情", activeMenu: "/billingLeaseQ/orderRecord" },
          }
        ],
      }
];
