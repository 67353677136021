/**
 * 门票是否支持修改过期时间
 */
export const modificationTimeList = [
  { text: "否", value: 0 },
  { text: "是", value: 1 },
];

/**
 * 门票是否开启获取身份证照片
 */
export const idCardPhotoList = [
  { text: "否", value: "0" },
  { text: "是", value: "1" },
];

/**
 * 门票是否开禁用
 */
export const ticketDisableList = [
  { text: "否", value: "0" },
  { text: "是", value: "1" },
];

/**
 * 门票是否支持修改价格
 */
export const modifyThePriceList = [
  { text: "否", value: "0" },
  { text: "是", value: "1" },
];

/**
 * 子订单来源 source
 */
export const subOrderSourceList = [
  { text: "窗口", value: "窗口" },
  { text: "公众号", value: "公众号" },
  { text: "手持机", value: "手持机" },
];

/**
 * 子订单销售渠道 salesChannels
 */
export const salesChannelsList = [
  { text: "站内", value: "站内" },
  { text: "美团点评", value: "美团点评" },
  { text: "抖音", value: "抖音" },
];

/**
 * 检票使用类型
 * useType
 * 1 售票 2 验票进 3 验票出 4 退票
 */
export const useTypeList = [
  { text: "售票", value: 1 },
  { text: "验票进", value: 2 },
  { text: "验票出", value: 3 },
  { text: "退票", value: 4 },
];

export function useTypeValueToText(value) {
  return valueToText(useTypeList, value);
}

/**
 * 检票使用类型（检票记录中使用）
 * useType
 */
export const useTypeListForCheck = [
  { text: "验票进", value: 2 },
  { text: "验票出", value: 3 },
];

/**
 * 订单类型
 * orderType
 * 1 售票 2 补录 3退票 4补缴
 */
export const orderTypeList = [
  { text: "售票", value: 1 },
  { text: "补录", value: 2 },
  { text: "退票", value: 3 },
  { text: "补缴", value: 4 },
];

export function orderTypeValueToText(value) {
  return valueToText(orderTypeList, value, value);
}

/**
 * 时间分组 dateType
 * 1 年 2 月 3 日
 */
export const dateTypeList = [
  { text: "年", value: 1 },
  { text: "月", value: 2 },
  { text: "日", value: 3 },
];

export function dateTypeValueToText(value) {
  return valueToText(dateTypeList, value);
}

/**
 * 售票窗口类型 ticketWindowType
 */
export const ticketWindowTypeList = [
  {
    value: "0",
    text: "人工",
  },
  {
    value: "1",
    text: "自助",
  },
  {
    value: "2",
    text: "手持机",
  },
];

/**
 * 检票设备类型 ticketCheckingEquipmentType
 */
export const counterDeviceTypeList = [
  {
    value: "0",
    text: "闸机",
  },
  {
    value: "1",
    text: "手持机",
  },
  {
    value: "2",
    text: "其他",
  },
];

/**
 * 检票设备类型（游乐场） ticketCheckingEquipmentType
 */
export const amusementCounterDeviceTypeList = [
  {
    value: "0",
    text: "闸机",
  },
];

/**
 * 门票种类 ticketType
 * @deprecated
 */
export const ticketTypeList = [
  {
    value: "0",
    text: "二维码门票",
  },
  {
    value: "1",
    text: "感应卡",
  },
  {
    value: "2",
    text: "无介质",
  },
];

/**
 * 出票方式 issueWarrantType
 */
export const issueWarrantTypeList = [
  {
    value: "0",
    text: "仅限一票一人",
  },
  {
    value: "1",
    text: "仅限一票多人",
  },
  {
    value: "2",
    text: "无限制",
  },
];

/**
 * 激活日期类型 activationTicketDate
 */
export const activationPeriodTypeList = [
  {
    value: "0",
    text: "自然日",
  },
  {
    value: "1",
    text: "自然月",
  },
  {
    value: "2",
    text: "自然年",
  },
  {
    value: "3",
    text: "月",
  },
  {
    value: "4",
    text: "年",
  },
  {
    value: "7",
    text: "固定激活期",
  },
];

export function activationPeriodTypeToText(value) {
  return valueToText(activationPeriodTypeList, value);
}

/**
 * 有效期类型 periodOfValidityType
 */
export const validityPeriodTypeList = [
  {
    value: "0",
    text: "自然日",
  },
  {
    value: "1",
    text: "自然月",
  },
  {
    value: "2",
    text: "自然年",
  },
  {
    value: "3",
    text: "月",
  },
  {
    value: "4",
    text: "年",
  },
  {
    value: "5",
    text: "小时",
  },
  {
    value: "6",
    text: "分钟",
  },
  {
    value: "7",
    text: "固定有效期",
  },
];

export function validityPeriodTypeToText(value) {
  return valueToText(validityPeriodTypeList, value);
}

/**
 * 激活方式 activationTicketType
 */
export const activationTicketTypeList = [
  {
    value: "0",
    text: "售票激活",
  },
  {
    value: "1",
    text: "首次验票激活",
  },
];

/**
 * 一般是否禁用
 */
export const commonDisableList = [
  {
    value: "0",
    text: "否",
  },
  {
    value: "1",
    text: "是",
  },
];

/**
 * 一般是否启用
 */
export const enableList = [
  {
    value: "0",
    text: "启用",
  },
  {
    value: "1",
    text: "禁用",
  },
];

/**
 * 飘过期可退 ifTicketRefund
 * 0不可退  1可退
 */
export const ifTicketRefundList = [
  {
    value: 1,
    text: "是",
  },
  {
    value: 0,
    text: "否",
  },
];

/**
 * 消费记录操作类型 documentType
 */
export const consumptionOperationTypeList = [
  {
    value: "开卡",
    text: "开卡",
  },
  {
    value: "充值",
    text: "充值",
  },
  {
    value: "消费",
    text: "消费",
  },
  {
    value: "验票",
    text: "验票",
  },
  {
    value: "购票",
    text: "购票",
  },
  {
    value: "计时收费",
    text: "计时收费",
  },
  {
    value: "退款",
    text: "退款",
  },
  {
    value: "补卡",
    text: "补卡",
  },
  {
    value: "退卡",
    text: "退卡",
  },
  {
    value: "续卡",
    text: "续卡",
  },
];

/**
 * 订单来源
 */
export const orderSourceList = [
  {
    value: "窗口",
    text: "窗口",
  },
  {
    value: "公众号",
    text: "公众号",
  },
];

/**
 * 交易类型 1流入 2流出
 */
export const tradeTypeList = [
  {
    value: "1",
    text: "流入",
  },
  {
    value: "2",
    text: "流出",
  },
];

export function tradeTypeValueToText(value) {
  return valueToText(tradeTypeList, value);
}

/**
 * 会员订单来源
 */
export const memberOrderSourceList = orderSourceList;

/**
 * 会员状态 statue
 */
export const memberStatusList = [
  { text: "销卡", value: -1 },
  { text: "已过期", value: 0 },
  { text: "正常", value: 1 },
];

export function memberStatusValueToText(value) {
  return valueToText(memberStatusList, value);
}

/**
 * 支付类型
 * @deprecated
 */
export const payTypeList = [
  { text: "现金", value: "现金" },
  { text: "记账", value: "记账" },
  { text: "扫码", value: "扫码" },
];

/**
 * 订单状态
 * orderState
 * 1 售票 2 补录 3退票
 */
export const orderStateList = [
  { text: "支付中", value: 0 },
  { text: "已付", value: 1 },
  { text: "退款中", value: 2 },
  { text: "已退", value: 3 },
];

export function orderStateValueToText(value) {
  return valueToText(orderStateList, value);
}

/**
 * 门票业务类型 operationType
 */
export const ticketBusinessTypeList = [
  {
    value: "0",
    text: "散客",
  },
  // {
  //   value: "1",
  //   text: "团队",
  // },
  {
    value: "2",
    text: "会员",
  },
  // {
  //   value: "3",
  //   text: "员工",
  // },
];

/**
 * 退票类型
 * refundTicketType
 */
export const refundTicketTypeList = [
  { text: "不允许退票", value: "0" },
  { text: "可退|用过不可退", value: "1" },
  { text: "可退|退余额", value: "2" },
  { text: "可退|退全额", value: "3" },
  { text: "可退|扣除比例", value: "4" },
  { text: "可退|固定金额", value: "5" },
];

export function refundTicketTypeValueToText(value) {
  return valueToText(refundTicketTypeList, value);
}

function createMonthDayList() {
  const list = [];
  for (let i = 1; i <= 31; i++) {
    list.push({
      text: String(i).padStart(2, "0"),
      value: String(i),
    });
  }
  return list;
}

export const monthDayList = createMonthDayList();

export function monthDayValueToText(value) {
  return valueToText(monthDayList, value);
}

export const weekDayList = [
  {
    text: "星期一",
    value: "2",
  },
  {
    text: "星期二",
    value: "3",
  },
  {
    text: "星期三",
    value: "4",
  },
  {
    text: "星期四",
    value: "5",
  },
  {
    text: "星期五",
    value: "6",
  },
  {
    text: "星期六",
    value: "7",
  },
  {
    text: "星期日",
    value: "1",
  },
];

export function weekDayValueToText(value) {
  return valueToText(weekDayList, value);
}

/**
 * 检票记录-扣费类型 deductionRule
 */
export const deductionRuleList = [
  {
    text: "固定金额",
    value: "0",
  },
  {
    text: "计时收费",
    value: "1",
  },
];

/**
 * 打印类型 tempType
 */
export const printTypeList = [
  {
    text: "门票小票",
    value: "门票小票",
  },
  {
    text: "收银小票",
    value: "收银小票",
  },
  {
    text: "会员小票",
    value: "会员小票",
  },
  {
    text: "检票小票",
    value: "检票小票",
  },
  {
    text: "普通门票",
    value: "普通门票",
  },
];

/**
 * 短信类型 smsType
 */
export const smsTypeList = [
  {
    text: "验证码",
    value: "1",
  },
  {
    text: "通知",
    value: "2",
  },
  {
    text: "推广",
    value: "3",
  },
];

/**
 * 使用频率单位 usageFrequency
 */
export const useUnitList = [
  {
    text: "天",
    value: "天",
  },
  {
    text: "月",
    value: "月",
  },
  {
    text: "年",
    value: "年",
  },
];

export function smsTypeValueToText(value) {
  return valueToText(smsTypeList, value);
}

/**
 * 短信发送状态 smsState
 */
export const smsStateList = [
  {
    text: "发送失败",
    value: 0,
  },
  {
    text: "发送成功",
    value: 1,
  },
];

export function smsStateValueToText(value) {
  return valueToText(smsStateList, value);
}

/**
 * 销售历史的支付状态
 * 支付状态  1待支付  2已支付 4部分退款 5全部退款 6已取消
 */
export const payStatusList = [
  { text: "待支付", value: 1 },
  { text: "已支付", value: 2 },
  { text: "退款中", value: 3 },
  { text: "部分退款", value: 4 },
  { text: "全部退款", value: 5 },
  { text: "已取消", value: 6 },
];

export function payStatusValueToText(value) {
  console.log(value);
  return valueToText(payStatusList, value);
}

/**
 * 将list的value转成text
 * @param {Array} list
 * @param {*} value
 * @param {*} defaultValue
 * @returns
 */
export function valueToText(list, value, defaultValue = "") {
  const item = list.find((v) => v.value === value);
  return item?.text ?? defaultValue;
}
