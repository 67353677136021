/**
 * 计费租赁接口
 */
import request from "@/utils/request";

// 租赁商品列表接口
export function rentalList(query) {
    return request({
      url: "/goods/rental/list",
      method: "get",
      params: query,
    });
}
  //租赁商品新增
export function rentalAdd(data) {
    return request({
      url: "/goods/rental/add",
      method: "post",
      data: data,
    });
}
  
  //修改租赁商品
  export function rentalEdit(data) {
    return request({
      url: "/goods/rental/edit",
      method: "put",
      data: data,
    });
}
// 删除租赁商品
export function delRental(id) {
    return request({
      url: "/goods/rental/" + id,
      method: "delete",
    });
}
  
  //租赁订单新增
  export function rentalOrderAdd(data) {
    return request({
      url: "/goods/rentalOrder/add",
      method: "post",
      data: data,
    });
}

// 获取租赁订单扫码支付结果
export function getPayStatus(id) {
    return request({
      url: `/goods/rentalOrder/getPayStatus/${id}`,
      method: "get"
    });
}


//租赁订单列表查询
export function rentalOrderList(query) {
    return request({
      url: "/goods/rentalOrder/list",
      method: "get",
      params: query,
    });
}

  //租赁订单商品归还
  export function returnGoods(data) {
    return request({
      url: "/goods/rentalOrder/returnGoods",
      method: "post",
      data: data,
    });
}

  //租赁订单结算
  export function settlement(data) {
    return request({
      url: "/goods/rentalOrder/settlement",
      method: "post",
      data: data,
    });
}


  //租赁结算支付
  export function settlementPay(data) {
    return request({
      url: "/goods/rentalOrder/settlementPay",
      method: "post",
      data: data,
    });
}


// 查询结算补差支付状态
export function getCheckoutStatus(id) {
    return request({
      url: `/goods/rentalOrder/getCheckoutStatus/${id}`,
      method: "get"
    });
}

// 根据id查询租赁商品
export function getRental(id) {
    return request({
      url: `/goods/rental/${id}`,
      method: "get"
    });
}

 //强制归还
 export function compulsoryReturn(data) {
    return request({
      url: "/goods/rentalOrder/compulsoryReturn",
      method: "post",
      data: data,
    });
}

// 获取租赁订单结算详情信息
export function getCheckoutInfo(id) {
    return request({
      url: `/goods/rentalOrder/getCheckoutInfo/${id}`,
      method: "get"
    });
}