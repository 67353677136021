//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTemplateConfig, updateTemplateConfig } from "@/api/ticket/template";
function createData() {
  return {
    scenicName: "示例景区",
    documentType: "开卡",
    transactionOrderNo: "123456789",
    createTime: "2023-01-01",
    createBy: "操作员A",
    memberName: "会员A",
    memberCardNumber: "1234567890",
    ticketName: "示例门票",
    rechargeBalance: 0,
    giveMoney: 0,
    rechargeNum: 0,
    giveRechargeNum: 0,
    cashierAmount: "0",
    settlementMethod: "扫码",
    ticketPoint: "售票点A",
    windows: "售票窗口A",
    oldEndTimes: "2023-02-01",
    newEndTimes: "2023-03-01",
    printCardNumber: "654321"
  };
}

/**
 * 会员打印模板设计
 */
export default {
  name: "MemberTemplateDesign",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        printer: "",
        ifScenic: 0,
        ifTicketCode: 0,
        ticketNo: 0,
        ifTicketName: 0,
        ifPersonNum: 0,
        ifUnitPrice: 0,
        ifAmount: 0,
        ifValidity: 0,
        ifSeller: 0,
        ifTicketWindow: 0,
        ifPrintTime: 0,
        ifEndNote1: 0,
        ifEndNote2: 0,
        endNote1: "",
        endNote2: "",
        ifMemberName: 0,
        ifMemberCard: 0,
        ifMemberTicket: 0,
        ifTicketType: 0,
        ifOrderNo: 0,
        ifSaleWindow: 0,
        ifSaleTime: 0,
        ifOperator: 0,
        ifNum: 0,
        ifAmountReceivable: 0,
        ifActualAmount: 0,
        ifSettlementMethod: 0,
        ifChange: 0,
        ifRechargeAmount: 0,
        ifRechargeTimes: 0,
        ifGiftAmount: 0,
        ifGiveNumber: 0,
        ifOldValidity: 0,
        ifNewValidity: 0,
        ifNewPrintCardNum: 0
      },
      documentType: "开卡"
    };
  },
  watch: {
    form: {
      handler(value) {
        console.log(value);
        this.$refs["ticketPrintTemplate"].setConfig(value);
      },
      deep: true
    }
  },
  created() {
    this.initPrintConfig();
  },
  mounted() {
    const data = createData();
    data.documentType = this.documentType;
    this.$refs["ticketPrintTemplate"].setData(data);
  },
  methods: {
    documentTypeChange(e) {
      console.log(e);
      const data = createData();
      data.documentType = e;
      this.$refs["ticketPrintTemplate"].setData(data);
    },
    async initPrintConfig() {
      const res = await getTemplateConfig(this.id);
      Object.assign(this.form, res.data);
    },
    goBack() {
      this.$tab.closePage();
    },
    print() {
      this.$refs["ticketPrintTemplate"].print();
    },
    async save() {
      const res = await updateTemplateConfig(this.form);
      if (res.code == 200) {
        this.$modal.msgSuccess(res.msg);
        this.$tab.closePage();
      }
    }
  }
};