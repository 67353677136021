/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"template",staticClass:"print-box",class:{
    'print-box--hidden': _vm.hidden,
  }},[_c('div',{staticClass:"print-box__inner"},[(_vm.config.ifScenic == 1)?_c('div',{staticClass:"print-box__title"},[_vm._v(" "+_vm._s(_vm.data.scenicName)+" ")]):_vm._e(),(_vm.config.ifTicketCode == 1)?_c('div',{staticClass:"print-box__qr-code"},[_c('img',{staticClass:"print-box__qr-code-img",attrs:{"src":_vm.ticketCodeImg,"alt":"qrcode"}})]):_vm._e(),(_vm.config.ticketNo == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("票号：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.ticketNo))])]):_vm._e(),(_vm.config.ifTicketName == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("票名：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.ticketName))])]):_vm._e(),(_vm.config.ifPersonNum == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("人次：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.personNum)+" ")])]):_vm._e(),(_vm.config.ifUnitPrice == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("单价：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.ticketDiscountPrice))])]):_vm._e(),(_vm.config.ifAmount == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("金额：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(" "+_vm._s(_vm.data.subtotalAmount)+" ")])]):_vm._e(),(_vm.config.ifValidity == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("有效期截止：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.currentValidity[1]))])]):_vm._e(),(
        _vm.config.ifTicketCode == 1 ||
        _vm.config.ifTicketName == 1 ||
        _vm.config.ticketNo == 1 ||
        _vm.config.ifPersonNum == 1 ||
        _vm.config.ifUnitPrice == 1 ||
        _vm.config.ifAmount == 1 ||
        _vm.config.ifValidity == 1
      )?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifSeller == 1 || _vm.config.ifTicketWindow == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" 售票信息 ")]):_vm._e(),(_vm.config.ifSeller == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_c('div',{staticClass:"print-box__label"},[_vm._v("售票员：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.data.createBy))])]):_vm._e(),(_vm.config.ifTicketWindow == 1)?_c('div',{staticClass:"print-box__item print-box__item--center"},[_vm._v(" "+_vm._s(_vm.data.ticketOfficeName)+"-"+_vm._s(_vm.data.ticketWindowName)+" ")]):_vm._e(),(_vm.config.ifSeller == 1 || _vm.config.ifTicketWindow == 1)?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifPrintTime == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("打印时间：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.printTime))])]):_vm._e(),(_vm.config.ifPrintTime == 1)?_c('div',{staticClass:"print-box__divider"}):_vm._e(),(_vm.config.ifEndNote1 == 1)?_c('div',{staticClass:"print-box__item"},[_c('div',{staticClass:"print-box__label"},[_vm._v("尾注：")]),_c('div',{staticClass:"print-box__value"},[_vm._v(_vm._s(_vm.config.endNote1))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }