import JSEncrypt from "jsencrypt/bin/jsencrypt.min";

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
  "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\n" +
  "nzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ==";

const privateKey =
  "MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAqhHyZfSsYourNxaY\n" +
  "7Nt+PrgrxkiA50efORdI5U5lsW79MmFnusUA355oaSXcLhu5xxB38SMSyP2KvuKN\n" +
  "PuH3owIDAQABAkAfoiLyL+Z4lf4Myxk6xUDgLaWGximj20CUf+5BKKnlrK+Ed8gA\n" +
  "kM0HqoTt2UZwA5E2MzS4EI2gjfQhz5X28uqxAiEA3wNFxfrCZlSZHb0gn2zDpWow\n" +
  "cSxQAgiCstxGUoOqlW8CIQDDOerGKH5OmCJ4Z21v+F25WaHYPxCFMvwxpcw99Ecv\n" +
  "DQIgIdhDTIqD2jfYjPTY8Jj3EDGPbH2HHuffvflECt3Ek60CIQCFRlCkHpi7hthh\n" +
  "YhovyloRYsM+IS9h/0BzlEAuO0ktMQIgSPT3aFAgJYwKpqRYKlLDVcflZFCKY7u3\n" +
  "UP8iWi1Qw0Y=";

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.decrypt(txt); // 对数据进行解密
}

/** 将本地对象转为base64 */
export function toBase(str) {
    // 将对象转换为JSON字符串
    const jsonString = JSON.stringify(str);
    // 使用encodeURIComponent将JSON字符串转换为UTF-8的百分比编码形式
    const encodedString = encodeURIComponent(jsonString);
    // 使用btoa将百分比编码形式的字符串转换为Base64
    const base64String = btoa(encodedString);
    return base64String;
  }

/** 将base64转成本地对象 */
export function changeBase(base64String) {
    // 使用atob将Base64字符串解码为百分比编码形式的字符串
    const encodedString = atob(base64String);
    // 使用decodeURIComponent将百分比编码形式的字符串转换回JSON字符串
    const jsonString = decodeURIComponent(encodedString);
    // 将JSON字符串解析回对象
    const obj = JSON.parse(jsonString);
  
    return obj;
  }