import {
  activationPeriodTypeToText,
  monthDayValueToText,
  weekDayValueToText,
  validityPeriodTypeToText,
} from "./form";
import dayjs from "dayjs";
import stringRandom from "string-random";
import { operationUsableList } from "@/api/ticket/operation";
import { wait } from "@/utils/common";
import {
  getPayState,
  getSalePayState,
  listPayment,
} from "@/api/ticket/payment";

/**
 * 获取 激活期/有效期 的时间范围
 * @param num 数量
 * @param unit 单位/类型
 * @return {[Date, Date]} 开始时间、结束时间的数组
 */
export function getAddTime(num, unit) {
  const startDay = dayjs();
  const startTime = startDay.toDate();
  let endTime = startTime;
  switch (unit) {
    case "自然日":
      endTime = startDay
        .add(num - 1, "d")
        .endOf("d")
        .toDate();
      break;
    case "自然月":
      endTime = startDay
        .add(num - 1, "M")
        .endOf("M")
        .toDate();
      break;
    case "自然年":
      endTime = startDay
        .add(num - 1, "y")
        .endOf("y")
        .toDate();
      break;
    case "月":
      endTime = startDay.add(num, "M").toDate();
      break;
    case "年":
      endTime = startDay.add(num, "y").toDate();
      break;
    case "小时":
      endTime = startDay.add(num, "h").toDate();
      break;
    case "分钟":
      endTime = startDay.add(num, "m").toDate();
      break;
    default:
      throw new Error(`unit类型错误，unit: ${unit}`);
  }

  return [startTime, endTime];
}

/**
 * 将激活时间设置转为文字
 * @param ticketInfo
 * @returns {string}
 */
export function getActivationTime(ticketInfo) {
  let text = "";
  if (ticketInfo.activationTicketType == "0") {
    // 售票激活
    // const startTime = dayjs().startOf("d").format("YYYY-MM-DD HH:mm:ss");
    // const endTime = dayjs().endOf("d").format("YYYY-MM-DD HH:mm:ss");
    // text = `${startTime}-${endTime}`;

    text = dayjs().format("YYYY-MM-DD");
  } else if (ticketInfo.activationTicketType == "1") {
    // 首次验票激活
    if (ticketInfo.activationTicketDate == "7") {
      // 固定日期
      text = `${ticketInfo.activationStartTime} ~ ${ticketInfo.activationEndTime}`;
    } else {
      const activationPeriodTypeText = activationPeriodTypeToText(
        ticketInfo.activationTicketDate,
      );
      const [startDate, endDate] = getAddTime(
        ticketInfo.activationTicketEndDate ?? 0,
        activationPeriodTypeText,
      );

      const startD = dayjs(startDate).format("YYYY-MM-DD");
      const endD = dayjs(endDate).format("YYYY-MM-DD");
      text = `${startD} ~ ${endD}`;
    }
  }

  return text;
}

/**
 * 将激活时间设置转为日期范围
 * @param ticketInfo
 * @returns {[Date, Date]}
 */
export function getActivationTimeRange(ticketInfo) {
  let range = null;
  if (ticketInfo.activationTicketType == "0") {
    // 售票激活
    const startTime = dayjs().startOf("d").toDate();
    const endTime = dayjs().endOf("d").toDate();
    range = [startTime, endTime];
  } else if (ticketInfo.activationTicketType == "1") {
    // 首次验票激活
    if (ticketInfo.activationTicketDate == "7") {
      // 固定日期
      const startTime = dayjs(ticketInfo.activationStartTime, "YYYY-MM-DD")
        .startOf("d")
        .toDate();
      const endTime = dayjs(ticketInfo.activationEndTime, "YYYY-MM-DD")
        .endOf("d")
        .toDate();

      range = [startTime, endTime];
    } else {
      const activationPeriodTypeText = activationPeriodTypeToText(
        ticketInfo.activationTicketDate,
      );
      range = getAddTime(
        ticketInfo.activationTicketEndDate ?? 0,
        activationPeriodTypeText,
      );
    }
  }

  return range;
}

/**
 * 将有效期设置转为日期范围
 * @param ticketInfo
 * @returns {[Date, Date]}
 */
export function getValidityTimeRange(ticketInfo) {
  let range = null;

  if (ticketInfo.periodOfValidityType == "7") {
    // 固定日期
    const startTime = dayjs(ticketInfo.periodOfValidityStartTime, "YYYY-MM-DD")
      .startOf("d")
      .toDate();
    const endTime = dayjs(ticketInfo.periodOfValidityEndTime, "YYYY-MM-DD")
      .endOf("d")
      .toDate();

    range = [startTime, endTime];
  } else {
    const validityPeriodTypeText = validityPeriodTypeToText(
      ticketInfo.periodOfValidityType,
    );
    range = getAddTime(
      ticketInfo.periodOfValidityEndDate ?? 0,
      validityPeriodTypeText,
    );
  }

  return range;
}

/**
 * 获取当前时刻票的有效期
 * 未激活取激活期，已激活取有效期
 * @param {Object} ticket 门票
 * @return {string}
 */
export function getCurrentValidity(ticket) {
  const [s, e] = getCurrentValidityArr(ticket);
  return s && e ? `${s} ~ ${e}` : "";
}

/**
 * 获取当前时刻票的有效期数组
 * 未激活取激活期，已激活取有效期
 * @param {Object} ticket 门票
 * @return {[string, string]}
 */
export function getCurrentValidityArr(ticket) {
  let arr = null;
  if (ticket.ifActivation == "1") {
    arr = [ticket.sTicketValidity, ticket.eTicketValidity];
  } else {
    arr = [ticket.activationStaDate, ticket.activationEntDate];
  }

  return arr;
}

/**
 * 检票时间规则/售票时间规则文字
 * @param {Object} timeRule 时间规则
 * @returns {string}
 */
export function getTicketingTimesText(timeRule) {
  let str = "";
  switch (timeRule.ticketingTimeType) {
    case "0":
      str = timeRule.ticketingTimes;
      break;
    case "1":
      str = timeRule.ticketingTimes
        .split(",")
        .map((v) => monthDayValueToText(v))
        .join(",");
      break;
    case "2":
      str = timeRule.ticketingTimes
        .split(",")
        .map((v) => weekDayValueToText(v))
        .join(",");
      break;
    default:
      break;
  }

  return str;
}

/**
 * 生成会员卡号
 * @returns {string}
 */
export function createMemberCardNo() {
  const str1 = stringRandom(1, "123456789");
  const str2 = stringRandom(9, {
    letters: false,
  });

  return `M${str2}`;
}

/**
 * 生成会员卡号
 * 8位16进制数
 * @returns {string}
 */
export function createMemberCardNo2() {
  const str1 = stringRandom(8, "0123456789ABCDEF");
  return str1;
}

/**
 * 验票时获取输入号码的类型
 * 会员卡号：8位
 * 普通门票：F开头9位数字
 * 美团点评：10位数字
 * 抖音：https开头
 * @param {string} no 票号
 * @returns {"普通门票"|"会员卡号"|"美团点评"|"抖音"} 普通门票、会员卡号、美团点评、
 */
export function getTicketNoType(no) {
  no = String(no);
  if (no.length === 8) {
    return "会员卡号";
  } else if (/^F\d{9}$/.test(no)) {
    return "普通门票";
  } else if (/^\d{10}$/.test(no)) {
    return "美团点评";
  } else if (/^https:\/\/v.douyin.com/i.test(no)) {
    return "抖音";
  } else {
    throw new Error("未知票号类型");
  }
}

/**
 * 查询窗口可用支付方式列表
 */
export async function getWindowPayList(office) {
  const res = await listPayment();
  return res.rows.filter((v) => {
    const methodOfCashierArr = office.methodOfCashier
      ? office.methodOfCashier.split(",")
      : [];
    return methodOfCashierArr.some((v2) => v2 == v.payType);
  });
}

/**
 * 查询景区非会员业务列表
 */
export async function getNotMemOperationList() {
  const res = await operationUsableList();
  return res.data.filter((v) => v.operationType != "2");
}

/**
 * 获取会员扫码支付状态
 * @returns { Promise<boolean> } 是否支付成功
 */
export async function handleScanCodePay({ payCode }) {
  let stateRes = null;
  // 循环获取支付状态，10次，间隔3秒
  for (let i = 0; i < 10; i++) {
    try {
      stateRes = await getPayState(payCode);
    } catch (e) {
      console.error(e);
      return false;
    }

    if (stateRes.code == 200) {
      const data = stateRes.data;
      if (data.code == "0000") {
        const respData = data.respData;

        if (respData.bizCode == "0000") {
          if (respData.tradeCode == "0000") {
            return true;
          } else if (respData.tradeCode == "2002") {
            await wait(3000);
            continue;
          } else {
            throw new Error(data.tradeMsg);
          }
        } else {
          throw new Error(data.bizMsg);
        }
      } else {
        throw new Error(data.msg);
      }
    } else {
      throw new Error(stateRes.msg);
    }
  }

  throw new Error("支付超时");
}

/**
 * 获取门票售卖扫码支付状态
 * @returns { Promise<boolean> } 是否支付成功
 */
export async function handleSaleScanCodePay({ orderNo }) {
  let stateRes = null;
  // 循环获取支付状态，10次，间隔3秒
  for (let i = 0; i < 10; i++) {
    try {
      stateRes = await getSalePayState(orderNo);
    } catch (e) {
      console.error(e);
      return false;
    }

    if (stateRes.code == 200) {
      const data = stateRes.data;
      if (data.code == "0000") {
        const respData = data.respData;

        if (respData.bizCode == "0000") {
          if (respData.tradeCode == "0000") {
            return true;
          } else if (respData.tradeCode == "2002") {
            await wait(3000);
            continue;
          } else {
            throw new Error(data.tradeMsg);
          }
        } else {
          throw new Error(data.bizMsg);
        }
      } else {
        throw new Error(data.msg);
      }
    } else {
      throw new Error(stateRes.msg);
    }
  }

  throw new Error("支付超时");
}

/**
 * 获取门票退款补缴扫码支付状态
 * @returns { Promise<boolean> } 是否支付成功
 */
export async function handleRefundScanCodePay({ orderNo }) {
  let stateRes = null;
  // 循环获取支付状态，10次，间隔3秒
  for (let i = 0; i < 10; i++) {
    try {
      stateRes = await getSalePayState(orderNo);
    } catch (e) {
      console.error(e);
      return false;
    }

    if (stateRes.code == 200 && stateRes.msg == "0000") {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * 打开美团ota授权窗口
 */
export function openMeituanOta() {
  const url = getMeituanOtaUrl();
  return window.open(url, "_blank");
}

/**
 * 获取美团ota授权url
 * @returns {URL}
 */
export function getMeituanOtaUrl() {
  // https://e.dianping.com/dz-open/merchant/auth?app_key=f9e039e3da9ea9ec&redirect_url=https://cn.bing.com&state=teststate&scope=[%22tuangou%22,%22ugc%22]
  const url = new URL("https://e.dianping.com/dz-open/merchant/auth");
  const searchParams = url.searchParams;

  // const redirectUri = `https://ticket.yunvip123.cn/wechart/#/pages/login/index?appId=${appId}`;

  searchParams.append("app_key", "aa4bfa72ac17513a");
  // searchParams.append("redirect_uri", redirectUri);
  searchParams.append("response_type", "code");
  // searchParams.append("scope", "["tuangou","ugc"]");
  searchParams.append("state", "STATE");
  return url;
}
