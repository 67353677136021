//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTemplateConfig, updateTemplateConfig } from "@/api/ticket/template";

/**
 * 门票打印模板设计
 */
export default {
  name: "TemplateDesign",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        printer: "",
        ifScenic: 0,
        ifTicketCode: 0,
        ticketNo: 0,
        ifTicketName: 0,
        ifPersonNum: 0,
        ifUnitPrice: 0,
        ifAmount: 0,
        ifValidity: 0,
        ifSeller: 0,
        ifTicketWindow: 0,
        ifPrintTime: 0,
        ifEndNote1: 0,
        ifEndNote2: 0,
        endNote1: "",
        endNote2: "",
        ifMemberName: 0,
        ifMemberCard: 0,
        ifMemberTicket: 0,
        ifTicketType: 0,
        ifOrderNo: 0,
        ifSaleWindow: 0,
        ifSaleTime: 0,
        ifOperator: 0,
        ifNum: 0,
        ifAmountReceivable: 0,
        ifActualAmount: 0,
        ifSettlementMethod: 0,
        ifChange: 0,
        ifRechargeAmount: 0,
        ifRechargeTimes: 0,
        ifGiftAmount: 0,
        ifGiveNumber: 0
      }
    };
  },
  watch: {
    form: {
      handler(value) {
        console.log(value);
        this.$refs["ticketPrintTemplate"].setConfig(value);
      },
      deep: true
    }
  },
  created() {
    this.initPrintConfig();
  },
  mounted() {
    this.$refs["ticketPrintTemplate"].setData({
      scenicName: "示例景区",
      orderNo: "123465",
      ticketNo: "F123456789",
      ticketName: "示例门票",
      personNum: 2,
      ticketDiscountPrice: 100,
      subtotalAmount: 200,
      activationEntDate: "2020-01-01 00:00:00",
      createBy: "售票员A",
      ticketOfficeName: "售票点A",
      ticketWindowName: "窗口A"
    });
  },
  methods: {
    async initPrintConfig() {
      const res = await getTemplateConfig(this.id);
      Object.assign(this.form, res.data);
    },
    goBack() {
      this.$tab.closePage();
    },
    print() {
      this.$refs["ticketPrintTemplate"].print();
    },
    async save() {
      const res = await updateTemplateConfig(this.form);
      if (res.code == 200) {
        this.$modal.msgSuccess(res.msg);
        this.$tab.closePage();
      }
    }
  }
};