
import Layout from "@/layout/cashierLayOut.vue";
export const cashierRoutes = [
  {
    path: "",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "/index",
        component: () =>
          import("@/views/cashier/prod-menu/index.vue"),
        name: "CashierIndex",
        meta: { title: "商品收款" },
      },
      {
        path: "/order",
        component: () =>
          import("@/views/cashier/order/index.vue"),
        name: "Order",
        meta: { title: "收款订单" },
      },
    ],
  },
];
