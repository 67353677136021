/**
 * 会员分组接口
 */
import request from "@/utils/request";

// 查询会员分组列表
export function listGroup(query) {
  return request({
    url: "/ticket/group/list",
    method: "get",
    params: query,
  });
}

// 查询会员分组详细
export function getGroup(id) {
  return request({
    url: "/ticket/group/" + id,
    method: "get",
  });
}

// 新增会员分组
export function addGroup(data) {
  return request({
    url: "/ticket/group",
    method: "post",
    data: data,
  });
}

// 修改会员分组
export function updateGroup(data) {
  return request({
    url: "/ticket/group",
    method: "put",
    data: data,
  });
}

// 删除会员分组
export function delGroup(id) {
  return request({
    url: "/ticket/group/" + id,
    method: "delete",
  });
}
