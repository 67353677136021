import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import { listPayment } from "@/api/ticket/payment";
import { changeBase } from '@/utils/jsencrypt';
import { getCheckoutInfo } from "@/api/ticket/rental";
export default {
  name: 'rentalOrderDetails',
  data() {
    return {
      tableData: [],
      form: {},
      tabList: [{
        name: '商品信息',
        type: 'goods'
      }],
      tabType: 'goods',
      payList: [],
      returnList: [{
        name: '未归还',
        value: '0'
      }, {
        name: '部分归还',
        value: '1'
      }, {
        name: '全部归还',
        value: '2'
      }],
      tableColumns: {
        columns: [{
          slotName: 'goodsName',
          param: 'goodsName',
          label: '商品名称'
        }, {
          param: 'totalRent',
          label: '商品押金',
          subdivision: row => {
            return this.changeToFixed(row.totalRent);
          }
        }, {
          param: 'totalDeposit',
          label: '商品租金',
          subdivision: row => {
            return this.changeToFixed(row.totalDeposit);
          }
        }, {
          param: 'quantity',
          label: '数量'
        }, {
          param: 'createTime',
          label: '时长(分钟)',
          subdivision: row => {
            return row.chargeType == 1 ? `实际租赁时长:${this.timeUntilNow(row.createTime, row.returnTime)}` : '-';
          },
          minWidth: 100
        }, {
          param: 'totalRent',
          label: '计时费用',
          subdivision: row => {
            return row.chargeType == 1 ? this.changeToFixed(row.totalRent) : '-';
          }
        }, {
          param: 'returnNum',
          label: '已归还数量',
          subdivision: row => {
            if (row.breakedNum <= 0) {
              return row.returnNum;
            } else {
              if (row.returnNum <= 0) {
                return `${row.breakedNum}(报损遗失)`;
              } else {
                return `${row.returnNum},${row.breakedNum}(报损遗失)`;
              }
            }
          }
        },
        //   {
        //     param: 'breakedNum',
        //       label: '报损遗失数量',
        //       subdivision: (row) => {
        //         if (row.breakedNum <= 0) {
        //    return 
        //   } else {
        //     if (row.returnNum <= 0) {
        //       return `${row.breakedNum}(报损遗失)`
        //     } else {
        //       let arr = this.returnList.filter(item => item.value == row.goodsStatus)
        //       return arr.length > 0 ? `${row.returnNum}(${arr[0].name}),${row.breakedNum}(报损遗失)` : ''
        //     }
        //   }
        //       return this.getReturnStatus(row.status)
        //     }
        //   },
        {
          param: 'getReturn',
          label: '物品状态',
          subdivision: row => {
            return this.getReturn(row);
          },
          styles: row => {
            if (row.breakedNum <= 0) {
              if (row.status == 0) {
                return {
                  'color': '#FF7D00'
                };
              }
            } else {
              if (row.returnNum <= 0) {
                return {
                  'color': '#F53F3F'
                };
              }
            }
          }
        }, {
          param: 'returnTime',
          label: '操作时间',
          subdivision: row => {
            return row.returnTime ? dayjs(row.returnTime).format("YYYY-MM-DD HH:mm:ss") : '-';
          }
        }, {
          param: 'remark',
          label: '备注'
        }]
      },
      settlementColumns: {
        columns: [{
          param: 'payAmount',
          label: '退款/补缴金额',
          subdivision: row => {
            return this.changeToFixed(row.payAmount);
          }
        }, {
          param: 'checkoutType',
          label: '结算方式',
          subdivision: row => {
            return row.checkoutType == '0' ? '退款' : '补缴';
          }
        }, {
          param: 'paymentName',
          label: '退款/补缴方式'
        }, {
          param: 'createTime',
          label: '结算时间'
        }, {
          param: 'remark',
          label: '备注'
        }]
      }
    };
  },
  computed: {
    getPay() {
      let arr = this.payList.filter(item => item.payType == this.form.payType);
      return arr.length > 0 ? arr[0].paymentName : '-';
    },
    getReturn() {
      return row => {
        if (row.breakedNum <= 0) {
          let arr = this.returnList.filter(item => item.value == row.status);
          return arr.length > 0 ? arr[0].name : '';
        } else {
          if (row.returnNum <= 0) {
            return `${row.breakedNum}(报损遗失)`;
          } else {
            let arr = this.returnList.filter(item => item.value == row.status);
            return arr.length > 0 ? `${row.returnNum}(${arr[0].name}),${row.breakedNum}(报损遗失)` : '';
          }
        }
      };
    },
    getReturnSettlement() {
      return row => {
        if (row.goodsStatus > 1 && row.settlementStatus > 1) {
          return '已支付';
        } else {
          let arr = this.returnList.filter(item => item.value == row.goodsStatus);
          return arr.length > 0 ? arr[0].name : '';
        }
      };
    },
    compTypeStyle() {
      return item => {
        if (item.chargeType === 0) {
          return 'color00B';
        } else {
          return 'color165';
        }
      };
    },
    chargeTypeName() {
      return row => {
        if (row.chargeType == 1) {
          return row.str;
        } else {
          return row.goodsName;
        }
      };
    }
  },
  created() {
    this.initPaymentList();
    if (this.$route.params && this.$route.params.item) {
      let str = this.$route.params.item;
      this.form = changeBase(str);
      this.tableData = this.form.rentalGoodsOrderItemsList;
      if (this.form.goodsStatus > 1 && this.form.settlementStatus > 1) {
        this.tabList.push({
          name: '结算信息',
          type: 'settlement'
        });
      }
    }
  },
  methods: {
    changeTab(row) {
      this.tabType = row.type;
      if (this.tabType == 'goods') {
        this.tableData = this.form.rentalGoodsOrderItemsList;
      } else {
        this.getCheckoutInfo();
      }
    },
    getCheckoutInfo() {
      getCheckoutInfo(this.form.id).then(res => {
        this.tableData = res.data;
      });
    },
    async initPaymentList() {
      const payTypeList = await listPayment();
      this.payList = payTypeList.rows;
    },
    //金额格式化
    changeToFixed(val) {
      return Number(val).toFixed(2);
    },
    // 计算当前时间距离开始时间
    timeUntilNow(date, newValue) {
      const now = new Date(newValue);
      const input = new Date(date);
      const diff = now - input;

      // 如果输入时间在过去，则返回0
      if (diff < 0) return '0 时 0 分 0 秒';
      let hours = Math.floor(diff / 3600000);
      let minutes = Math.floor(diff % 3600000 / 60000);
      let seconds = Math.floor(diff % 60000 / 1000);
      return `${hours} 时 ${minutes} 分 ${seconds} 秒`;
    },
    back() {
      this.$router.go(-1);
    }
  }
};