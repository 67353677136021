import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDay } from "@/utils/date-utils";
import { dateRangePickerShortcuts } from "@/utils/el-utils";
import { getUserList } from "@/api/product/inventory";
import { getCahierOrder } from "@/api/product/order";
import OrderDetailsOrReturn from '@/components/Product/Order/order-details-or-return.vue';
import { getWindowPayList } from "@/common/logic";
import { mapState } from "vuex";
import { payStatus } from "@/utils/dictionary";
import { listPayment } from "@/api/ticket/payment";
export default {
  components: {
    OrderDetailsOrReturn
  },
  props: {},
  data() {
    return {
      activePage: 1,
      pickerOptions: {
        shortcuts: dateRangePickerShortcuts
      },
      form: {
        payTimes: [new Date(getDay(0) + ' 00:00:00').getTime(), new Date(getDay(0) + ' 23:59:59').getTime()],
        cardCode: '',
        mobile: '',
        no: '',
        status: '',
        paymentPayType: '',
        operatorId: '',
        pageNum: 1,
        pageSize: 10
      },
      opeaterList: [],
      tableData: [],
      total: 0,
      operaType: '',
      currentRowId: '',
      payList: [],
      payStatusList: payStatus(),
      loading: true,
      tableHeight: 0
    };
  },
  computed: {
    ...mapState("office", ["office"]),
    compStatus() {
      return status => {
        const info = this.payStatusList.find(v => v.value === status);
        if (info) {
          return info.label;
        } else {
          return '';
        }
      };
    },
    compType() {
      return status => {
        let type = '';
        switch (status) {
          case 30:
            type = 'info';
            break;
          case 40:
            type = 'success';
            break;
          case 50:
            type = 'warning';
            break;
          case 60:
            type = 'danger';
            break;
        }
        return type;
      };
    }
  },
  watch: {},
  methods: {
    async getPayList() {
      const res = await listPayment();
      if (res.code === 200) {
        this.payList = res.rows;
      }
    },
    toQuery() {
      this.form.pageNum = 1;
      this.form.no = this.form.cardCode;
      this.form.mobile = this.form.cardCode;
      this.getList();
    },
    getList() {
      this.loading = true;
      getCahierOrder(this.form).then(res => {
        if (res.code === 200) {
          this.tableData = res.rows && res.rows.length > 0 ? res.rows : [];
          this.total = res.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    // 获取操作员
    getOperater() {
      getUserList({
        pageNum: 1,
        pageSize: 100
      }).then(res => {
        if (res.code === 200) {
          this.opeaterList = res.rows.length > 0 ? res.rows : [];
        }
      });
    },
    toDetail(id, type) {
      this.currentRowId = id;
      this.operaType = type;
      this.activePage = 2;
    },
    back() {
      this.activePage = 1;
      this.currentRowId = '';
      this.operaType = '';
      this.getList();
    }
  },
  created() {
    this.getOperater();
    this.getList();
    this.getPayList();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableArea.offsetHeight;
    });
  },
  destroyed() {}
};